import React from 'react'
import get from 'lodash/get'
import { compose } from 'redux'
import { connect } from 'react-redux'

import ImportErrorModal from './ImportErrorModal'

class ImportErrorLayout extends React.Component {
  state = {
    isLoading: false,
  }

  reloadPage = async () => {
    window.location.reload()
  }

  render() {
    return (
      <>
        {this.props.children}
        {this.props.isImportError && <ImportErrorModal reloadPage={this.reloadPage} />}
      </>
    )
  }
}

export default compose(
  connect((state) => ({
    isImportError: get(state, 'importError.isImportError'),
  }))
)(ImportErrorLayout)
