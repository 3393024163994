import * as Sentry from '@sentry/react'
import { SubmissionError } from 'redux-form/dist/redux-form'

export const captureException = (e) => {
  Sentry.captureException(e)
}

export const captureFilteredExceptionHandler = (e) => {
  if (e instanceof SubmissionError || e?._error === 'Not found.') {
    return
  }
  captureException(e)
}
