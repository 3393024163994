import React from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { compose } from 'redux'
import { connect } from 'react-redux'
import get from 'lodash/get'
import Cookies from 'js-cookie'

import { getCookie } from '~/lib/utils'
import { getDomain } from '~/common/utils/helpers'

export function RequireAuth({
  token,
  needRedirect,
  redirectTo,
  aclRedirectTo,
  aclNeedRedirect,
  children,
  outlet,
}) {
  const location = useLocation()

  const isNeedRedirect = React.useMemo(() => {
    if (needRedirect !== undefined) return needRedirect
    return !!token
  }, [needRedirect, token])

  React.useEffect(() => {
    if (!isNeedRedirect && redirectTo === '/signin') {
      Cookies.set('gan-redirect', location.pathname, {
        domain: getDomain(null),
      })
    }
  }, [isNeedRedirect, redirectTo, location.pathname])

  if (aclNeedRedirect) {
    return <Navigate to={aclRedirectTo} />
  }
  if (isNeedRedirect) {
    return outlet ? <Outlet /> : children
  }
  return <Navigate to={redirectTo} />
}

export default compose(
  connect((state) => {
    return {
      token: get(state.resource, 'session.data.token') || getCookie('jwt'),
    }
  })
)(RequireAuth)
