import { ERROR, NO_ERROR } from '../actions/importError'

export const defaultState = {
  isImportError: false,
  isModalOpen: false,
}

export function reducer(state = defaultState, { type }) {
  switch (type) {
    case ERROR: {
      return {
        ...state,
        isImportError: true,
      }
    }
    case NO_ERROR: {
      return {
        ...state,
        isImportError: false,
      }
    }
    default: {
      return state
    }
  }
}
