import moment from 'moment-timezone'
import * as dateFns from 'date-fns'

import en from 'date-fns/locale/en-US'
import sv from 'date-fns/locale/sv'

export const getDefaultDate = (date) => {
  return moment(date).format('YYYY-MM-DD')
}

export const getDefaultTypeDateAndTime = (date) => {
  return moment(date).format('YYYY-MM-DD') + ' ' + moment(date).format('HH:mm')
}

export const getTime = (date) => {
  return moment(date).format('HH:mm')
}

export const getMonthDay = (date) => {
  return moment(date).format('MM-DD')
}

export const getMonthNameDayYear = (date, localeString = 'en') => {
  if (!date) return ''
  if (localeString === 'en') {
    return dateFns.format(new Date(date), 'MMMM dd, yyyy', { locale: en })
  } else {
    return dateFns.format(new Date(date), 'dd MMMM, yyyy', { locale: sv })
  }
}

export const getDefault24TypeDateAndTime = (date) => {
  return moment(date).format('YYYY-MM-DD') + ' ' + moment(date).format('HH:mm')
}

export const weekAgo = (date) => {
  return moment(date).add(-7, 'days').format('YYYY-MM-DD')
}

export const monthAgo = (date) => {
  return moment(date).add(-1, 'months').format('YYYY-MM-DD')
}

export const yearAgo = (date) => {
  return moment(date).add(-1, 'years').format('YYYY-MM-DD')
}

export const timeAgo = (targetDate) => {
  const now = new Date()
  const target = new Date(targetDate)
  const difference = now - target // difference in milliseconds

  const seconds = Math.floor(difference / 1000)
  const minutes = Math.floor(seconds / 60)
  const hours = Math.floor(minutes / 60)
  const days = Math.floor(hours / 24)

  if (days > 0) {
    return { amount: days, period: 'day' }
  } else if (hours > 0) {
    return { amount: hours, period: 'hour' }
  } else if (minutes > 0) {
    return { amount: minutes, period: 'minute' }
  } else {
    return { amount: seconds, period: 'second' }
  }
}

export const getTimeDifference = (startDate, endDate, measure = 'millisecond') => {
  if (!startDate || !endDate) return 0

  const diff = endDate - startDate

  switch (measure) {
    case 'millisecond':
      return diff
    case 'second':
      return diff / 1000
    case 'minute':
      return diff / (1000 * 60)
    case 'hour':
      return diff / (1000 * 60 * 60)
    case 'day':
      return diff / (1000 * 60 * 60 * 24)
    default:
      return diff
  }
}

export const dateAndTimeToISO = (date) => {
  return date && date.utc().toISOString()
}
