import React from 'react'
import { ToastContainer } from 'react-toastify'
import { compose } from 'redux'
import get from 'lodash/get'
import { connect } from 'react-redux'
import { withErrorBoundary } from 'react-error-boundary'

import Authenticate from '~/components/Authenticate'
import AppRoutes from './Routes'
import ScrollToTop from '~/components/ScrollToTop'
import { connectResource } from '~/common/utils/resource'
import { IntlProviderWrapper } from '~/providers/IntlContext'
import OfflineLayout from '~/components/Offline/OfflineLayout'
import ImportErrorLayout from '~/components/ImportError/ImportErrorLayout'
import FileManagerLayout from '~/components/FileManager/layout'
import { CloseButton } from '~/components/CloseButton'
import { captureFilteredExceptionHandler } from '~/common/utils/sentry'
import { PARTNER_MATCH_URLS } from '~/common/utils/constants'
import { isRouteMatch } from '~/common/utils/helpers'
import ErrorFallback from '~/common/components/ErrorFallback'

export class App extends React.Component {
  render() {
    const { profile, partnerProfile } = this.props

    const isPartner = !!this.props.partnerSession && !this.props.userSession
    const isPartnerSection = isRouteMatch(window.location.pathname, PARTNER_MATCH_URLS)
    const userLocale = get(profile, 'data.language')
    const partnerLocale = get(partnerProfile, 'data.language')
    const locale = isPartner ? partnerLocale : isPartnerSection ? partnerLocale : userLocale
    return (
      <IntlProviderWrapper locale={locale}>
        <>
          <ScrollToTop>
            <ImportErrorLayout>
              <OfflineLayout>
                <FileManagerLayout>
                  <Authenticate>
                    <AppRoutes />
                  </Authenticate>
                </FileManagerLayout>
              </OfflineLayout>
            </ImportErrorLayout>
          </ScrollToTop>
          <ToastContainer
            position="top-right"
            autoClose={4000}
            hideProgressBar
            closeButton={<CloseButton />}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnVisibilityChange
            draggable
            pauseOnHover
          />
        </>
      </IntlProviderWrapper>
    )
  }
}

const composed = compose(
  connectResource({
    namespace: 'profile',
    endpoint: 'profile',
    async: true,
    prefetch: false,
  }),
  connectResource({
    namespace: 'partnerProfile',
    endpoint: 'partner/profile',
    async: true,
    prefetch: false,
  }),
  connect((state) => ({
    userSession: get(state, 'resource.session.data.token'),
    partnerSession: get(state, 'resource.session.data.partnerToken'),
  }))
)(App)

export default withErrorBoundary(composed, {
  FallbackComponent: ErrorFallback,
  onError: (error, info) => {
    captureFilteredExceptionHandler(error)
  },
})
