import React from 'react'
import { FormattedMessage } from 'react-intl'
import { FiPlusCircle } from 'react-icons/fi'

import { FormAddAttribute } from './ModalAddAttribute'

export class AddAttributeForm extends React.Component {
  state = {
    showForm: false,
  }

  showFormTrigger = () => {
    this.setState((prevState) => ({ showForm: !prevState.showForm }))
  }

  resetForm = () => {
    this.showFormTrigger()
  }

  handleClose = async (isSubmit = false) => {
    if (isSubmit === true) {
      await this.props.attributes.filter()
    }
    this.showFormTrigger()
  }

  render() {
    return (
      <>
        {this.state.showForm && (
          <div className="mb-4 rounded-md bg-gray-100 p-4">
            <FormAddAttribute onClose={this.handleClose} />
          </div>
        )}
        {!this.state.showForm ? (
          <div className="mb-4">
            <button
              type="button"
              data-id="btn-link"
              className="flex items-center space-x-1 leading-none text-blue-600"
              onClick={this.showFormTrigger}
            >
              <FiPlusCircle size={14} strokeWidth={2.5} />{' '}
              <FormattedMessage id="contacts.addAttribute" />
            </button>
          </div>
        ) : null}
      </>
    )
  }
}

export default AddAttributeForm
