import React, { Component } from 'react'
import { reduxForm, getFormValues, SubmissionError } from 'redux-form/dist/redux-form'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { toast } from 'react-toastify'
import get from 'lodash/get'
import { FiX } from 'react-icons/fi'

import { Modal, ModalBody } from '~/components/Modal'
import { connectFormResource } from '~/common/utils/resource'
import { languages } from '~/common/utils/constants'
import { connectResource } from '~/common/utils/resource'
import { CheckboxField, TextField } from '~/common/forms/fields'
import SelectField from '~/common/forms/fields/SelectField'
import { required, validateEmail } from '~/validations'
import ButtonSpinner from '~/components/ButtonSpinner'
import Spinner from '~/components/Spinner'
import AnimatedCheckmark from '~/components/AnimatedCheckmark'
import { captureException } from '~/common/utils/sentry'

export const STATUS_SUCCESS = 'SUCCESS'
export const STATUS_ERROR = 'ERROR'

export class AddUserModal extends Component {
  _isMounted = false

  state = {
    status: null,
  }

  onSubmit = (e) => {
    const { onSubmit } = this.props
    return onSubmit(e)
      .then((res) => {
        if (get(res, 'id')) {
          toast.success(<FormattedMessage id="toasters.user.created" />)
          if (this._isMounted) {
            this.setState({ status: STATUS_SUCCESS })
          }
        } else {
          if (this._isMounted) {
            this.setState({
              status: STATUS_ERROR,
            })
          }
        }
      })
      .catch((e) => {
        let error
        if (e instanceof SubmissionError) {
          error = get(e, 'errors.error')
        } else {
          captureException(e)
        }
        if (this._isMounted) {
          this.setState({
            status: STATUS_ERROR,
            error,
          })
        }
        throw e
      })
  }

  componentDidMount() {
    this._isMounted = true
  }

  componentWillUnmount() {
    this._isMounted = false
    this.props.newUser.setData(null)
  }

  render() {
    const {
      onClose,
      handleSubmit,
      newUser,
      modalHeader = <FormattedMessage id="partner.button.createNewUser" />,
      loginAsUser,
      formData,
    } = this.props
    return (
      <Modal onClose={onClose} size="md">
        <ModalBody>
          <button
            onClick={onClose}
            className="btn-none stripe-modal--close-button pointer text-gray-600"
          >
            <FiX strokeWidth={2.5} />
          </button>
          <div className="p-3">
            {this.state.status === STATUS_SUCCESS && (
              <div className="row mt-1 justify-center text-center">
                <div className="col">
                  <AnimatedCheckmark />
                  <h2 className="mt-4 mb-3">
                    <FormattedMessage id="partner.modal.user.add.title" />
                  </h2>
                  <p className="mb-4">
                    <FormattedMessage
                      id="partner.modal.user.add.description.created"
                      values={{
                        email: get(newUser, 'data.email'),
                      }}
                    />
                    {formData.should_send_welcome_mail && (
                      <>
                        {' '}
                        <FormattedMessage id="partner.modal.user.add.description.emailsent" />
                      </>
                    )}{' '}
                    <FormattedMessage id="partner.modal.user.add.description.login" />
                  </p>
                  <p className="card-text text-muted text-center">
                    <button
                      className="btn btn-block btn-success"
                      onClick={loginAsUser(get(newUser, 'data.id'))}
                    >
                      <FormattedMessage id="partner.modal.user.add.button.title" />
                    </button>
                  </p>
                </div>
              </div>
            )}
            {this.state.status !== STATUS_SUCCESS && (
              <form onSubmit={handleSubmit(this.onSubmit)}>
                <h3 className="mt-1">{modalHeader}</h3>
                <p className="mb-3 text-gray-700">
                  <FormattedMessage id="partner.user.add.form.description" />
                </p>
                <Spinner show={newUser.isLoading} />
                <div className="form-group">
                  <TextField
                    name="email"
                    type="email"
                    id="email"
                    label={<FormattedMessage id="common.inputs.emailAddress" />}
                    validate={[required, validateEmail]}
                  />
                </div>
                <div className="form-group">
                  <SelectField
                    label={<FormattedMessage id="settings.language" />}
                    name="language"
                    options={languages}
                  />
                </div>
                <div className="form-group">
                  <CheckboxField
                    name="should_send_welcome_mail"
                    boxLabel={<FormattedMessage id="partner.button.shouldSendWelcomeMail" />}
                  />
                </div>
                <ButtonSpinner
                  type="submit"
                  className="btn btn-success w-full"
                  spin={newUser.isLoading}
                  disabled={newUser.isLoading}
                >
                  <FormattedMessage id="partner.button.createNewUser" />
                </ButtonSpinner>
              </form>
            )}
          </div>
        </ModalBody>
      </Modal>
    )
  }
}

const getInitialValues = (formData, init) => {
  const email = get(formData, 'email')
  const language = get(formData, 'language')
  const shouldSendWelcomeMail = get(formData, 'should_send_welcome_mail')

  const initialValues = {
    ...init,
  }
  if (email) {
    initialValues.email = email
  }
  if (language) {
    initialValues.language = language
  }
  if (typeof shouldSendWelcomeMail !== 'undefined') {
    initialValues.should_send_welcome_mail = shouldSendWelcomeMail
  }
  return initialValues
}

export default compose(
  connectFormResource({
    namespace: 'newUser',
    endpoint: 'partner/users/:id?',
    list: true,
    idKey: 'id',
    async: true,
  }),
  connectResource({
    namespace: 'partnerProfile',
    endpoint: 'partner/profile',
    async: true,
    prefetch: false,
  }),
  connect((state, props) => {
    const formData = getFormValues('newUser')(state)

    return {
      initialValues: getInitialValues(formData, {
        language: get(state.resource, 'partnerProfile.data.language'),
        should_send_welcome_mail: true,
      }),
      formData,
    }
  }),
  reduxForm({
    form: 'newUser',
    enableReinitialize: true,
  })
)(AddUserModal)
