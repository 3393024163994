import React, { Component } from 'react'
import { compose } from 'redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { FormattedMessage } from 'react-intl'
import get from 'lodash/get'

import { Modal, ModalHeader, ModalBody, ModalFooter } from '~/components/Modal'
import { connectResource } from '~/common/utils/resource'
import { getPaymentUrl } from '~/common/utils/helpers'
import { CurrentCard } from './CurrentCard'
import API from '~/api'
import { captureFilteredExceptionHandler } from '~/common/utils/sentry'
import { addPurchaseEvents } from '~/common/utils/googleTagManager'

export class PaymentModal extends Component {
  _isMounted = false

  state = {
    isLoading: false,
    payWithNewCard: false,
  }

  async componentDidMount() {
    this._isMounted = true
    let paymentwindow

    const PaymentWindow = window.PaymentWindow
    if (!PaymentWindow) {
      return toast.error(<FormattedMessage id="errors.payment.general" />)
    }

    if (this.props.id) {
      try {
        await this.props.invoice.fetch()
      } catch (e) {
        captureFilteredExceptionHandler(e)
        return toast.error(<FormattedMessage id="errors.payment.general" />)
      }
    }

    let resp
    try {
      resp = await this.props.payment.filter({
        ...this.props.payment.filters,
        ...this.props.filters,
      })
    } catch (e) {
      captureFilteredExceptionHandler(e)
      return toast.error(<FormattedMessage id="errors.payment.general" />)
    }

    paymentwindow = new PaymentWindow(resp)

    paymentwindow.eventListener = function (c) {
      if (!c || (c && !c.data) || (c && c.data && !c.data.split)) {
        return
      }
      paymentwindow.onMessage(c, paymentwindow)
    }

    paymentwindow.close()
    paymentwindow.append('payment')

    return paymentwindow.open()
  }

  componentWillUnmount() {
    this._isMounted = false
    this.props.payment.setData(null)
  }

  payWithExistingCard = async () => {
    if (this._isMounted) {
      this.setState({
        isLoading: true,
      })
    }
    const { order_id } = this.props.filters
    let res
    try {
      res = await API('pay_order/existing_card').post({ order_id })
      if (get(res, 'status') === 'FAIL_PERMANENT') {
        return toast.error(<FormattedMessage id="errors.payment" />)
      }

      addPurchaseEvents(order_id)

      await this.props.profile.fetch()
      if (this._isMounted) {
        this.setState({
          isLoading: false,
        })
      }
      this.props.navigate('/upgrade/pay-by-card/accept')
      if (this.props.onClose) {
        this.props.onClose()
      }
    } catch (e) {
      if (this._isMounted) {
        this.setState({
          isLoading: false,
        })
      }
      captureFilteredExceptionHandler(e)
    }
  }

  render() {
    const { onClose, profile, isChangeCard } = this.props
    const { payWithNewCard, isLoading } = this.state
    const isCurrentCard = profile.data.active_card && !payWithNewCard && !isChangeCard
    return (
      <Modal onClose={onClose}>
        <ModalHeader onClose={onClose} name={<FormattedMessage id="invoices.table.payment" />} />
        <ModalBody>
          {isCurrentCard && (
            <CurrentCard
              {...this.props}
              payWithExistingCard={this.payWithExistingCard}
              isLoading={isLoading}
            />
          )}
          <div id="payment" className={`text-center ${isCurrentCard ? 'd-none' : ''}`} />
        </ModalBody>
        <ModalFooter>
          <div className="row">
            <div className="col-2">
              <img
                alt=""
                src="https://d7vuwcueykwhl.cloudfront.net/integration/ewindow/Images/window/epay_logo.png"
              />
            </div>
            <div className="col-12 col-md-8">
              <p className="small m-0">
                <FormattedMessage id="paymentmodal.epay.security.message" />
              </p>
            </div>
            <div className="col-2 text-right">
              <img
                alt=""
                src="https://d7vuwcueykwhl.cloudfront.net/integration/ewindow/Images/window/secure_logo.png"
              />
            </div>
          </div>
        </ModalFooter>
      </Modal>
    )
  }
}

const ComposedContainer = compose(
  connectResource({
    namespace: 'payment',
    endpoint: 'order_window_options',
    prefetch: false,
    list: true,
    filters: {
      accepturl: getPaymentUrl(`upgrade/pay-by-card/accept/`),
      cancelurl: getPaymentUrl(`upgrade/cancel/`),
      cssurl: getPaymentUrl(`styles/epay.css/`),
      iframeheight: '550',
      iframewidth: '730',
    },
  }),
  connectResource({
    namespace: 'profile',
    endpoint: 'profile',
    async: true,
    prefetch: true,
    refresh: true,
  }),
  connectResource({
    namespace: 'invoice',
    endpoint: 'invoices',
    idKey: 'id',
    list: true,
    prefetch: false,
  })
)(PaymentModal)

function ComposedContainerWrapper(props) {
  const navigate = useNavigate()
  return <ComposedContainer {...props} navigate={navigate} />
}

export default ComposedContainerWrapper
