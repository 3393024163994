import React from 'react'
import { Outlet, Route } from 'react-router-dom'

import AsyncComponent from '~/components/AsyncComponent'

const NewDraftComponent = () => import('../NewDraft')
const NewDraft = AsyncComponent({ component: NewDraftComponent })

const SendMailComponent = () => import('../SendMail')
const SendMail = AsyncComponent({ component: SendMailComponent })

const SendTestMailComponent = () => import('../TestMail/TestMailContainer')
const SendTestMail = AsyncComponent({ component: SendTestMailComponent })

const SendMailNowComponent = () => import('../NowMail/NowMailContainer')
const SendMailNow = AsyncComponent({ component: SendMailNowComponent })

const ScheduleMailComponent = () => import('../ScheduleMail/ScheduleMailContainer')
const ScheduleMail = AsyncComponent({ component: ScheduleMailComponent })

export const draftsRoutes = (
  <>
    <Route path="new" element={<Outlet />}>
      <Route exact index element={<NewDraft key="index" />} />
      <Route exact path="own" element={<NewDraft key="own" />} />
      <Route exact path="advanced" element={<NewDraft key="advanced" />} />
    </Route>

    <Route path="send/:id" element={<Outlet />}>
      <Route exact index element={<SendMail />} />
      <Route exact path="test" element={<SendTestMail />} />
      <Route exact path="now" element={<SendMailNow />} />
      <Route exact path="later" element={<ScheduleMail />} />
    </Route>
  </>
)
