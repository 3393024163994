import * as helpers from '~/common/utils/helpers'

export default function fetchRetry(promise, options) {
  function onError(err) {
    const triesLeft = options.tries - 1
    if (!triesLeft) {
      throw err
    }
    const index = options.index || 1
    return helpers.tick(options.delay * index).then(() => {
      return fetchRetry(promise, {
        ...options,
        tries: triesLeft,
        index: index + 1,
      })
    })
  }
  return promise().catch(onError)
}
