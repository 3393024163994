import React, { FC, ReactNode } from 'react'

export const BUTTON_TYPES = ['button', 'reset', 'submit'] as const

interface ButtonSpinnerProps {
  title?: string
  spin?: boolean
  className?: string
  children?: ReactNode | null
  type?: typeof BUTTON_TYPES[number]
  onClick?: () => void
  disabled?: boolean
}

const ButtonSpinner: FC<ButtonSpinnerProps> = ({
  spin = false,
  className = '',
  children = null,
  type = BUTTON_TYPES[0],
  onClick = () => {},
  disabled = false,
  title,
}) => {
  //TODO Fix type prop is not used
  return (
    <button
      title={title}
      type="submit"
      className={`${className} ${spin ? 'animate' : ''} btn-spinner`}
      onClick={onClick}
      disabled={disabled || spin}
    >
      <span>{children}</span>
    </button>
  )
}

export default ButtonSpinner
