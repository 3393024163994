import React from 'react'
import { FormattedMessage } from 'react-intl'

import { signupCodesHash } from '~/common/utils/constants/signupCodes'
import { AUTH_LAYOUT_TYPES } from '~/common/utils/constants'
import Image from '~/components/Image'
import { useIntlContext } from '~/providers/IntlContext'

import skarmavbildImg from '~/img/skarmavbild.png'
import authForgotImg from '~/img/auth-forgot.svg'
import smsPromoEn from '~/img/sms-promo-en.svg'
import smsPromoSv from '~/img/sms-promo-sv.svg'

export default function AuthLayout({ children, type, code }) {
  const { locale } = useIntlContext()
  const promoImg = locale === 'sv' ? smsPromoSv : smsPromoEn
  const signupCode = signupCodesHash[code]
  return (
    <div className="auth-container flex flex-auto items-stretch">
      <div className="auth-container--form flex w-1/2 flex-1 justify-center p-12">{children}</div>
      <div
        className={`type-${type} hidden w-1/2 items-center justify-center bg-gray-100 p-12 md:flex`}
      >
        {type === AUTH_LAYOUT_TYPES.SIGNIN && (
          <div className="flex w-full max-w-md flex-col text-center">
            <div>
              <span className="badge badge-success px-2 py-1">
                <FormattedMessage id="authlayout.login.blockeditorLabel" />
              </span>
            </div>
            <h2 className="mt-2 mb-3">
              <FormattedMessage id="authlayout.login.blockeditorHeading" />
            </h2>
            <FormattedMessage id="common.authlayout.signin.img.alt">
              {(alt) => <Image className="my-4" width="450px" image={promoImg} alt={alt} />}
            </FormattedMessage>
            <p className="lead mt-3 text-gray-800">
              <FormattedMessage id="authlayout.login.blockeditorText" />
            </p>
          </div>
        )}
        {type === AUTH_LAYOUT_TYPES.SIGNUP &&
          (signupCode && signupCode.isCustom ? (
            <div className="mt-3">
              <h2 className="mb-3 text-center">{signupCode.logo.title}</h2>
              <p className="mb-4 text-center">{signupCode.logo.subtitle}</p>
              <div className="max-w-sm text-center">
                <FormattedMessage id="common.authlayout.signup.img.alt">
                  {(alt) => <Image image={signupCode.logo.url} alt={alt} />}
                </FormattedMessage>
              </div>
            </div>
          ) : (
            <div className="max-w-md">
              <div className="rounded-lg bg-white p-12 shadow-lg">
                <div className="text-center">
                  <FormattedMessage id="common.authlayout.signup.img.alt">
                    {(alt) => (
                      <Image
                        image={skarmavbildImg}
                        alt={alt}
                        className="h-28 w-28 rounded-full object-cover"
                      />
                    )}
                  </FormattedMessage>
                </div>
                <p className="lead mt-4 mb-5 text-center">
                  "<FormattedMessage id="authlayout.signup.quote" />"
                </p>
                <div className="flex flex-col items-center">
                  <p className="font-weight-semi-bold mb-0">
                    <FormattedMessage id="authlayout.signup.customer" />
                  </p>
                  <p className="mb-0">
                    <FormattedMessage id="authlayout.signup.quoted" />
                  </p>
                </div>
              </div>
            </div>
          ))}
        {type === AUTH_LAYOUT_TYPES.FORGOT && (
          <FormattedMessage id="common.authlayout.forgot.img.alt">
            {(alt) => <img alt={alt} className="max-w-sm" src={authForgotImg} />}
          </FormattedMessage>
        )}
      </div>
    </div>
  )
}
