import React from 'react'
import get from 'lodash/get'
import { FormattedMessage } from 'react-intl'

import { getDefaultDate } from '~/common/utils/formatDate'
import ButtonSpinner from '~/components/ButtonSpinner'

export function CurrentCard({ profile, invoice, payWithExistingCard, amount, isLoading }) {
  const amountToShow = parseFloat(amount || get(invoice, 'data.total_amount', 0)).toFixed(2)
  return (
    <>
      <div className="row mb-3">
        <div className="col">
          <p className="small mb-1">
            <FormattedMessage id="card.yourCurrentCard" />
          </p>
          <div>
            <pre>{profile.data.active_card}</pre>
          </div>
          <hr />
          <p>
            <FormattedMessage id="card.date" />: {getDefaultDate(get(invoice, 'data.due_date'))}
          </p>
        </div>
        <div className="col">
          <div className="card bg-light mb-3">
            <div className="card-body text-center">
              <p className="small mb-1">
                <FormattedMessage id="invoices.amount" />
              </p>
              <h3 className="currency-amount">
                {profile.data.currency_code} {amountToShow}
              </h3>
            </div>
          </div>
          <ButtonSpinner
            type="button"
            onClick={payWithExistingCard}
            className="btn btn-success btn-block"
            spin={isLoading}
            disabled={isLoading}
          >
            <FormattedMessage id="card.pay" />
          </ButtonSpinner>
        </div>
      </div>
    </>
  )
}
