import { CLEAR, SET_ERROR, SET_VALID } from '../actions/vatId'
import get from 'lodash/get'

export const defaultState = {}

export const defaultFormState = {
  vat_id: null,
  org_number: null,
  isValid: null,
  isWarning: null,
}

export function reducer(state = defaultState, { type, payload = {}, meta = {} }) {
  if (!meta.form) {
    return state
  }
  switch (type) {
    case SET_ERROR: {
      return {
        ...state,
        [meta.form]: {
          ...defaultFormState,
          ...state[meta.form],
          isValid: false,
          isWarning: get(payload, 'isWarning', null),
          vat_id: get(payload, 'vat_id', null),
          org_number: get(payload, 'org_number', null),
        },
      }
    }
    case SET_VALID: {
      return {
        ...state,
        [meta.form]: {
          ...defaultFormState,
          ...state[meta.form],
          isValid: true,
          isWarning: null,
          vat_id: null,
          org_number: null,
        },
      }
    }
    case CLEAR: {
      return {
        ...state,
        [meta.form]: {
          ...defaultFormState,
          ...state[meta.form],
          isValid: null,
          isWarning: null,
          vat_id: null,
          org_number: null,
        },
      }
    }
    default: {
      return state
    }
  }
}
