import React from 'react'
import { FormattedMessage } from 'react-intl'
import get from 'lodash/get'
import { FiTrash2, FiLogIn } from 'react-icons/fi'

import { Table, Column, TooltipContent } from '~/common/widgets'
import ModalConfirmationTrigger from '~/modals/ModalConfirmationTrigger'

export const UsersTable = (props) => {
  return (
    <Table
      list={{ results: get(props, 'users.data', []) || [] }}
      isLoading={get(props, 'users.isLoading') || props.isLoginInAsUser}
      paginationLimit={props.users.filters.paginate_by}
      paginationCount={props.users.count}
      paginationOnChange={(data) => props.users.filter({ page: data.currentIndex })}
      itemsType={<FormattedMessage id="partner.table.items" />}
    >
      <Column
        field="email"
        title={<FormattedMessage id="common.email" />}
        format={(value, item) => (
          <div className="flex">
            <span title={value} className="text-truncate block">
              {value}
            </span>
            {item.is_partner && (
              <>
                <span className="badge badge-pill ml-2 bg-blue-100 text-blue-600">
                  <FormattedMessage id="partner.account" />
                </span>
              </>
            )}
          </div>
        )}
      />
      <Column
        field="name"
        title={
          <span className="text-capitalize">
            <FormattedMessage id="common.name" />
          </span>
        }
        format={(_, item) => (
          <span>
            {!item.first_name && !item.last_name
              ? '-'
              : `${item.first_name} ${item.last_name}`.trim()}
          </span>
        )}
      />
      <Column
        field="name"
        length="120"
        title={<FormattedMessage id="common.actions" />}
        width="100px"
        format={(_, item) => {
          return (
            <div className="flex">
              <TooltipContent
                className="flex items-center"
                tooltipText={<FormattedMessage id="auth.login" />}
                iconType
              >
                <FiLogIn
                  className="text-primary font-size-large pointer"
                  onClick={props.loginAsUser(item.id)}
                  strokeWidth={2.5}
                />
              </TooltipContent>
              {!item.is_partner && (
                <TooltipContent tooltipText={<FormattedMessage id="actions.partner.remove" />}>
                  <ModalConfirmationTrigger
                    size="md"
                    message={
                      <>
                        <h3>
                          <FormattedMessage id="partner.modal.user.remove.description.title" />
                        </h3>
                        <p className="mt-3">
                          <FormattedMessage
                            id="partner.modal.user.remove.description.line1"
                            values={{ email: item.email }}
                          />
                        </p>
                        <p className="mt-1 text-gray-700">
                          <FormattedMessage id="partner.modal.user.remove.description.line2" />
                        </p>
                      </>
                    }
                    title={<FormattedMessage id="partner.modal.user.remove.description.title" />}
                    onConfirm={() => {
                      props.users.remove(item)
                    }}
                  >
                    <FiTrash2 className="text-danger font-size-large pointer" strokeWidth={2.5} />
                  </ModalConfirmationTrigger>
                </TooltipContent>
              )}
            </div>
          )
        }}
      />
    </Table>
  )
}

export default UsersTable
