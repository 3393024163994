import { OFFLINE, ONLINE } from '../actions/offline'

export const defaultState = {
  isOffline: false,
  isModalOpen: false,
}

export function reducer(state = defaultState, { type }) {
  switch (type) {
    case OFFLINE: {
      return {
        ...state,
        isOffline: true,
      }
    }
    case ONLINE: {
      return {
        ...state,
        isOffline: false,
      }
    }
    default: {
      return state
    }
  }
}
