import React, { PureComponent } from 'react'
import Select, { createFilter } from 'react-select'
import CreatableSelect from 'react-select/creatable'
import PropTypes from 'prop-types'
import isEqual from 'lodash/isEqual'
import get from 'lodash/get'
import { FormattedMessage } from 'react-intl'

export const styles = {
  menu: (provided) => ({ ...provided, zIndex: 1000 }),
  control: (provided, state) => {
    const custom = {}
    custom.boxShadow = '0 1px 2px 0 rgba(0, 0, 0, 0.05)'
    if (state.isFocused) {
      custom.borderColor = '#64A1DD'
      custom.outline = 0
      custom.boxShadow = '0 0 0 white, 0 0 0 2px #E9F2FA'
    }
    return {
      ...provided,
      ...custom,
    }
  },
  input: (base) => ({
    ...base,
    'input:focus': {
      boxShadow: 'none',
    },
  }),
}

export const getTheme = (theme) => ({
  ...theme,
  borderRadius: 8,
  spacing: {
    ...theme.spacing,
    controlHeight: 38,
  },
  colors: {
    ...theme.colors,
    primary: '#64A1DD', // $blue-400
    primary75: '#A7C9EC', // $blue-300
    primary50: '#C8DDF3', // $blue-200
    primary25: '#e2e8f0', // $gray-200
    neutral10: '#edf2f7', // $gray-200
    neutral20: '#cbd5e1', // $gray-400
    neutral30: '#a0aec0', // $gray-500
    danger: '#EB5757', // $red-500
    dangerLight: '#FDD2D2', // $red-200
  },
})

const propTypes = {
  inputClassName: PropTypes.string,
  placeholder: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  name: PropTypes.string,
  options: PropTypes.array,
  required: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  isDisabled: PropTypes.bool,
  isClearable: PropTypes.bool,
  isMulti: PropTypes.bool,
  isSearchable: PropTypes.bool,
}
const defaultProps = {
  inputClassName: 'select-custom',
  isClearable: false,
  isMulti: false,
  isSearchable: false,
}

class SelectInput extends PureComponent {
  handleChange = (e, data) => {
    if (data.action === 'remove-value' && this.props.confirmationAction) {
      if (this.props.isMulti) {
        return this.props.confirmationAction(e.map((i) => i.value))
      }
    }
    let value = get(e, 'value')
    if (!this.props.isMulti && (Array.isArray(this.props.value) || this.props.arrayValue)) {
      value = [e.value]
    }
    if (this.props.isMulti) {
      e = e || []
      value = e.map((i) => i.value)
    }

    if (this.props.onFieldChange) {
      this.props.onFieldChange(value)
    }

    return this.props.onChange(value)
  }

  generateValue = (e) => {
    if (!this.props.isMulti && (Array.isArray(e) || this.props.arrayValue)) {
      return this.props.options.find((i) => i.value === e[0])
    }
    if (this.props.isMulti && Array.isArray(e)) {
      return e.map((item) => this.props.options.find((i) => isEqual(i.value, item)))
    }

    return this.props.options.find((i) => i.value === e)
  }

  getNoOptionsMessage = () => <FormattedMessage id="common.select.noOptions" />

  getFormatCreateLabel = (inputValue) => (
    <FormattedMessage id="common.select.create" values={{ inputValue }} />
  )

  render() {
    const {
      name,
      options,
      value,
      isSearchable = true,
      filterConfig,
      onBlur,
      placeholder,
      customMeta,
      ...restProps
    } = this.props

    const CustomSelect = this.props.onCreateOption ? CreatableSelect : Select

    const errorClass =
      ((this.props.meta.touched && this.props.meta.error) || get(customMeta, 'error')) && 'error'

    return (
      <CustomSelect
        {...restProps}
        styles={styles}
        theme={getTheme}
        name={name}
        className={`react-select ${errorClass}`}
        value={this.generateValue(value)}
        onChange={this.handleChange}
        options={options}
        isSearchable={isSearchable}
        filterOption={filterConfig ? createFilter(filterConfig) : undefined}
        placeholder={placeholder || <FormattedMessage id="common.select.dots" />}
        noOptionsMessage={this.getNoOptionsMessage}
        formatCreateLabel={this.getFormatCreateLabel}
      />
    )
  }
}

SelectInput.propTypes = propTypes
SelectInput.defaultProps = defaultProps

export default SelectInput
