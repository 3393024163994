import React from 'react'
import { useIntlContext } from '~/providers/IntlContext'

const languages = [
  {
    code: 'en',
    name: 'En',
  },
  {
    code: 'sv',
    name: 'Sv',
  },
]

export const ChangeLanguage = (props) => {
  const { switchTo, locale } = useIntlContext()
  return (
    <div className="flex gap-3" data-test="change-language">
      {languages.map((item, index) => {
        const className = `text-xs underline-offset-2 ${
          locale === item.code ? 'underline opacity-50' : ''
        } ${props.variant === 'light' ? 'text-white' : 'text-blue-500'}`
        return (
          <a
            data-test={locale === item.code ? 'active' : ''}
            key={index}
            href="/#"
            onClick={async (e) => {
              e.preventDefault()
              await switchTo[item.code]()
            }}
            className={className}
          >
            {item.name}
          </a>
        )
      })}
    </div>
  )
}
