const FortnoxIc = () => {
  return (
    <svg className="w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 168.7 168.7">
      <path
        d="M84.3 36.7h25.1c1.8 0 2.8 1 2.8 2.8v13.4c0 1.8-1 2.8-2.8 2.8H84.3c-5.9 0-9.8 3.9-9.8 9.8v18.3h34.8c1.8 0 2.8 1 2.8 2.8V100c0 1.8-1 2.8-2.8 2.8H74.5v26.1c0 1.8-1 2.8-2.8 2.8H57.8c-1.8 0-2.8-1-2.8-2.8v-63c0-17.4 11.9-29.2 29.3-29.2"
        fill="#003824"
      />
      <path
        d="M84.3 165.2c-15.3 0-30.4-4.4-43.5-12.8-16.2-10.5-28-26.5-33.3-45-5.3-18.3-3.8-37.9 4.1-55.2 1.3-2.8 4.5-4 7.3-2.7 2.8 1.3 4 4.5 2.7 7.3-6.8 14.9-8.1 31.8-3.5 47.6 4.6 16 14.8 29.7 28.7 38.8 16.5 10.7 37 13.8 56 8.5 2.9-.8 6 .9 6.8 3.8.8 2.9-.9 6-3.8 6.8-7.1 2-14.3 2.9-21.5 2.9z"
        fill="#007533"
      />
      <path
        d="M124.4 153.6c-1.8 0-3.6-.9-4.6-2.5-1.6-2.6-.9-6 1.7-7.6 8.4-5.3 15.3-12.3 20.8-20.6C163 91 153.9 48.3 122 27.6c-1-.7-2.2-1.4-3.6-2.2-2.6-1.5-3.6-4.9-2.1-7.5 1.5-2.6 4.9-3.6 7.5-2.1 1.6.9 2.9 1.7 4.1 2.5 37 24 47.5 73.5 23.5 110.5-6.3 9.7-14.4 17.7-24.1 23.9-.9.6-1.9.9-2.9.9z"
        fill="#ffc200"
      />
      <path
        d="M28.6 41.6c-1.3 0-2.6-.5-3.6-1.4-2.3-2-2.5-5.5-.5-7.8C43.7 10.7 73.3 1 101.7 7.4c3 .7 4.8 3.6 4.2 6.6-.7 3-3.6 4.8-6.6 4.2-24.5-5.5-50 2.8-66.6 21.6-1.1 1.2-2.6 1.8-4.1 1.8z"
        fill="#00db33"
      />
    </svg>
  )
}

export default FortnoxIc
