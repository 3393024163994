import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import get from 'lodash/get'
import { FiAlertCircle, FiChevronDown, FiChevronRight, FiX } from 'react-icons/fi'
import NumberFormat from 'react-number-format'

import { noop } from '~/lib/utils'
import ButtonSpinner from '~/components/ButtonSpinner'
import CustomValidationError from '~/components/CustomValidationError'

export const OrderInformation = ({
  article,
  upgradeConditions,
  profile,
  onSubmit,
  priceCalculation,
  withButtons = true,
  isLoading,
  setPromoCode = noop,
}) => {
  const [isPromoDiscountOpen, setIsPromoDiscountOpen] = React.useState(false)
  const [localPromoCode, setLocalPromoCode] = React.useState('')
  const [localPromoCodeError, setLocalPromoCodeError] = React.useState('')
  const [localPromoCodeSuccess, setLocalPromoCodeSuccess] = React.useState(false)

  const handleResetPromoDiscount = React.useCallback(() => {
    setLocalPromoCode('')
    setLocalPromoCodeError('')
    setPromoCode('')
  }, [setLocalPromoCode, setPromoCode])

  const handleLocalPromoCodeChange = React.useCallback(
    (e) => setLocalPromoCode(e?.target?.value || ''),
    [setLocalPromoCode]
  )

  const handleApplyPromoCode = React.useCallback(async () => {
    setLocalPromoCodeError('')
    setPromoCode('')
    try {
      const result = await upgradeConditions.filter({
        promo_code: localPromoCode,
      })
      setLocalPromoCodeSuccess(true)
      setPromoCode(result?.promo_discount?.code || '')
      setIsPromoDiscountOpen(false)
    } catch (e) {
      setLocalPromoCodeError(e?.promo_code)
    }
  }, [setLocalPromoCodeError, setPromoCode, upgradeConditions, localPromoCode])

  if (!priceCalculation) {
    return null
  }

  const {
    isShowAdditionalPostFee,
    currentPostDeliveryPrice,
    planDiscount,
    trialDiscount,
    trialNotUsedDays,
    articleSumWithDiscount,
    vatPercent,
    articleVat,
    invoicePrice,
    isYear,
  } = priceCalculation
  const isContactBased = get(profile, 'data.subscription.is_contact_based')
  const isSubscriptionProlonged =
    get(upgradeConditions, 'data.end_date') > get(profile, 'data.subscription.end_time')
  const isPaused = get(profile, 'data.subscription.is_paused')

  return (
    <div className="card box-shadow mb-4">
      <h3 className="card-header h5">
        <FormattedMessage id="common.yourOrder" />
      </h3>
      <ul className="list-group list-group-flush">
        <li className="list-group-item py-3">
          <div className="row">
            <div className="col-12 col-sm-4">
              <span className="text-muted block">
                <FormattedMessage id="upgrade.to" />
              </span>
              <span className="font-weight-bold block text-blue-600">{article.name || '-'}</span>
            </div>
            <div className="col-12 col-sm-4">
              <span className="text-muted block">
                <FormattedMessage id="common.period" />
              </span>
              <span className="font-weight-bold block text-blue-600">
                {get(upgradeConditions, 'data.start_date')} -{' '}
                {get(upgradeConditions, 'data.end_date')}
              </span>
            </div>
            <div className="col-12 col-sm-4">
              <span className="text-muted block">
                <FormattedMessage id="common.price" />
              </span>
              <span className="font-weight-bold block text-blue-600">
                <NumberFormat
                  thousandSeparator=" "
                  decimalScale={2}
                  value={
                    get(upgradeConditions, 'data.article_monthly_price') *
                    get(upgradeConditions, 'data.months')
                  }
                  suffix={` ${profile.data.currency_code}`}
                  displayType="text"
                />
              </span>
            </div>
          </div>
          {isSubscriptionProlonged && !isPaused && (
            <div className="prolonged-tip small font-italic mt-1 flex items-center text-gray-700">
              <FiAlertCircle strokeWidth={2.5} className="mr-1" />
              <FormattedMessage id="upgrade.next.period.charge" />
            </div>
          )}
        </li>
        {isShowAdditionalPostFee && (
          <li className="list-group-item post-fee py-3">
            <div className="row">
              <div className="col-12 col-sm-4">
                <span className="text-muted block">
                  <FormattedMessage id="common.description" />
                </span>
                <span className="font-weight-bold block">
                  <FormattedMessage id="profile.invoiceByPost" />
                </span>
              </div>
              <div className="col-12 col-sm-4">
                <span className="text-muted block">
                  <FormattedMessage id="common.period" />
                </span>
                <span className="font-weight-bold block">-</span>
              </div>
              <div className="col-12 col-sm-4">
                <span className="text-muted block">
                  <FormattedMessage id="common.price" />
                </span>
                <span className="font-weight-bold price-post-fee block">
                  <NumberFormat
                    thousandSeparator=" "
                    decimalScale={2}
                    value={currentPostDeliveryPrice}
                    suffix={` ${profile.data.currency_code}`}
                    displayType="text"
                  />
                </span>
              </div>
            </div>
          </li>
        )}
        {!!planDiscount && (
          <li className="list-group-item discount bg-gray-100 py-3">
            <div className="row">
              <div className="col-12 col-sm-4">
                <span className="text-muted block">
                  <FormattedMessage id="upgrade.discount.current" />
                </span>
                <span className="font-weight-bold text-muted block">
                  {isContactBased ? (
                    <>
                      {get(profile, 'data.subscription.max_number_of_contacts')} {` `}
                      <FormattedMessage id="common.contacts" />
                    </>
                  ) : (
                    <>
                      {get(profile, 'data.subscription.number_of_emails')} {` `}
                      <FormattedMessage id="common.mails" />
                    </>
                  )}
                </span>
              </div>
              <div className="col-12 col-sm-4">
                <span className="text-muted block">
                  <FormattedMessage id="common.period" />
                </span>
                <span className="font-weight-bold text-muted block">
                  {get(upgradeConditions, 'data.start_date')} -{' '}
                  {get(upgradeConditions, 'data.end_date')}
                </span>
              </div>
              <div className="col-12 col-sm-4">
                <span className="text-muted block">
                  <FormattedMessage id="upgrade.totalDiscount" />
                </span>
                <span className="font-weight-bold text-muted block">
                  -
                  <NumberFormat
                    thousandSeparator=" "
                    decimalScale={2}
                    value={planDiscount}
                    suffix={` ${profile.data.currency_code}`}
                    displayType="text"
                  />
                </span>
              </div>
            </div>
          </li>
        )}
        {!!trialDiscount && (
          <li className="list-group-item discount bg-gray-100 py-3">
            <div className="row">
              <div className="col-12 col-sm-4">
                <span className="text-muted block">
                  <FormattedMessage id="upgrade.discount.current.trial" />
                </span>
              </div>
              <div className="col-12 col-sm-4">
                <span className="text-muted block">
                  <FormattedMessage id="common.notused.days" />
                </span>
                <span className="font-weight-bold text-muted block">
                  <NumberFormat
                    thousandSeparator=" "
                    decimalScale={2}
                    value={trialNotUsedDays}
                    displayType="text"
                  />
                </span>
              </div>
              <div className="col-12 col-sm-4">
                <span className="text-muted block">
                  <FormattedMessage id="upgrade.totalDiscount" />
                </span>
                <span className="font-weight-bold text-muted block">
                  -
                  <NumberFormat
                    thousandSeparator=" "
                    decimalScale={2}
                    value={trialDiscount}
                    suffix={` ${profile.data.currency_code}`}
                    displayType="text"
                  />
                </span>
              </div>
            </div>
          </li>
        )}
        {!!upgradeConditions?.data?.promo_discount?.code && (
          <li className="list-group-item discount bg-gray-100 py-3">
            <div className="row">
              <div className="col-4">
                <span className="text-muted block">
                  <FormattedMessage id="upgrade.discount.code" />
                </span>
                <span className="font-weight-bold block text-green-600">
                  {upgradeConditions?.data?.promo_discount?.code}
                  {` `}
                  <NumberFormat
                    thousandSeparator=" "
                    decimalScale={0}
                    value={upgradeConditions?.data?.promo_discount?.percent}
                    suffix={` %`}
                    displayType="text"
                  />
                </span>
              </div>
              <div className="col-4" />
              <div className="col-4">
                <span className="text-muted block">
                  <FormattedMessage id="upgrade.discount" />
                </span>
                <span className="font-weight-bold block text-green-600">
                  -
                  <NumberFormat
                    thousandSeparator=" "
                    decimalScale={2}
                    value={upgradeConditions?.data?.promo_discount?.value}
                    suffix={` ${profile.data.currency_code}`}
                    displayType="text"
                  />
                </span>
              </div>
            </div>
          </li>
        )}
        <li className="list-group-item sum py-3">
          <div className="row">
            <div className="col-12 col-sm-4">
              <span className="text-muted block">
                <FormattedMessage id="common.sum" />
              </span>
              <span className="font-weight-bold price-base block">
                <NumberFormat
                  thousandSeparator=" "
                  decimalScale={2}
                  value={articleSumWithDiscount}
                  suffix={` ${profile.data.currency_code}`}
                  displayType="text"
                />
              </span>
            </div>
            <div className="col-12 col-sm-4">
              {!!articleVat && (
                <>
                  <span className="text-muted block">
                    <FormattedMessage
                      id="common.vat"
                      values={{
                        percent: (
                          <NumberFormat
                            thousandSeparator=" "
                            decimalScale={0}
                            value={vatPercent}
                            suffix={`%`}
                            displayType="text"
                          />
                        ),
                      }}
                    />
                  </span>
                  <span className="font-weight-bold price-vat block">
                    <NumberFormat
                      thousandSeparator=" "
                      decimalScale={2}
                      value={articleVat}
                      suffix={` ${profile.data.currency_code}`}
                      displayType="text"
                    />
                  </span>
                </>
              )}
            </div>
            <div className="col-12 col-sm-4">
              <span className="text-muted block">
                <FormattedMessage id="common.total" />
              </span>
              <span className="h1 font-weight-bold price-total mb-0 block">
                <NumberFormat
                  thousandSeparator=" "
                  decimalScale={2}
                  value={invoicePrice}
                  suffix={` ${profile.data.currency_code}`}
                  displayType="text"
                />
              </span>
            </div>
          </div>
        </li>
      </ul>
      {withButtons && (
        <div className="card-body">
          <div style={{ marginTop: '-5px' }}>
            <button
              className={`btn btn-none ${isPromoDiscountOpen ? '' : 'a'} mb-1 p-0`}
              onClick={() => setIsPromoDiscountOpen(!isPromoDiscountOpen)}
            >
              <FormattedMessage id="upgrade.promo.add.discount" />
              {isPromoDiscountOpen ? (
                <FiChevronDown className="ml-1" strokeWidth={2.5} />
              ) : (
                <FiChevronRight className="ml-1" strokeWidth={2.5} />
              )}
            </button>

            {isPromoDiscountOpen ? (
              <div className={`input-group ${localPromoCodeError ? 'is-invalid' : ''} mb-2`}>
                <FormattedMessage id="upgrade.promo.add.discount.placeholder">
                  {(placeholder) => (
                    <input
                      type="text"
                      disabled={localPromoCodeSuccess}
                      className={`form-control ${localPromoCodeError ? 'error' : ''}`}
                      placeholder={placeholder}
                      value={localPromoCode}
                      onChange={handleLocalPromoCodeChange}
                    />
                  )}
                </FormattedMessage>
                <div className="input-group-append position-relative">
                  {localPromoCodeError ? (
                    <button
                      className="btn-none pointer cancel-search"
                      type="button"
                      onClick={handleResetPromoDiscount}
                    >
                      <FiX strokeWidth={2.5} />
                    </button>
                  ) : null}
                  <ButtonSpinner
                    className="background-white btn border-gray-300 shadow-sm hover:bg-gray-100"
                    onClick={handleApplyPromoCode}
                    disabled={!localPromoCode || localPromoCodeSuccess}
                    spin={upgradeConditions.isLoading}
                  >
                    <FormattedMessage id="upgrade.promo.add.discount.apply.text" />
                  </ButtonSpinner>
                </div>
                {localPromoCodeError && (
                  <div className="invalid-feedback">
                    <CustomValidationError messages={localPromoCodeError} />
                  </div>
                )}
              </div>
            ) : null}
          </div>
          <p className="text-muted mb-4">
            <FormattedMessage id="common.byPlacing" />{' '}
            <Link to="/legal/terms">
              <FormattedMessage id="common.termsOfUse" />
            </Link>{' '}
            {!isYear && (
              <span>
                <FormattedMessage id="common.myCardWillBeCharged" />
              </span>
            )}
          </p>
          <button className="btn btn-success btn-block" onClick={onSubmit} disabled={isLoading}>
            <FormattedMessage id="common.upgradeNow" />
          </button>
        </div>
      )}
    </div>
  )
}
