import React, { Component, ReactNode } from 'react'
import ReactDOM from 'react-dom'
import { noop } from '~/lib/utils'

import './styles.scss'

interface ModalHeaderProps {
  onClose?: () => void
  name?: ReactNode
  className?: string
}

export const ModalHeader: React.FC<ModalHeaderProps> = ({
  onClose = noop,
  name = '',
  className = '',
}) => {
  return (
    <div className="modal-header">
      <h5 className="modal-title">{name}</h5>
      {onClose !== noop ? ( // do not show close button if onClose handler is not attached
        <button type="button" className="close" onClick={onClose}>
          <span>&times;</span>
        </button>
      ) : null}
    </div>
  )
}

interface ModalBodyProps {
  children?: ReactNode
  className?: string
}

export const ModalBody: React.FC<ModalBodyProps> = ({ children = null, className = '' }) => (
  <div className={`modal-body ${className}`}>{children}</div>
)

interface ModalFooterProps {
  children?: ReactNode
  className?: string
}

export const ModalFooter: React.FC<ModalFooterProps> = ({ children = null, className = '' }) => (
  <div className={`modal-footer ${className}`}>{children}</div>
)

interface ModalProps {
  children?: ReactNode
  onClose?: () => void
  className?: string
  id?: string
  size?: 'md' | 'sm' | 'lg' | 'xl'
  backdrop?: 'static' | boolean
}

interface ModalState {
  show: string
}

export class Modal extends Component<ModalProps, ModalState> {
  static defaultProps = {
    children: null,
    onClose: noop,
    className: '',
    id: '',
  }

  container: HTMLElement | null = document.getElementById('root')
  state = {
    show: '',
  }
  timer: NodeJS.Timeout | null = null

  componentDidMount() {
    document.body.classList.add('modal-open')
    this.timer = setTimeout(() => {
      this.setState({ show: 'show' })
    }, 150)
  }

  onDialogClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()
  }

  componentWillUnmount() {
    document.body.classList.remove('modal-open')
    if (this.timer) {
      clearTimeout(this.timer)
    }
  }

  render() {
    const { size = 'lg', backdrop, children, className, id, onClose } = this.props
    return this.container ? (
      ReactDOM.createPortal(
        <div
          className={`modal fade ${this.state.show} ${className}`}
          tabIndex={-1}
          style={{ display: 'block' }}
          onClick={backdrop === 'static' ? noop : onClose}
          id={id}
        >
          <div
            className={`modal-dialog modal-${size} modal-dialog-centered`}
            onClick={this.onDialogClick}
          >
            <div className="modal-content">{children}</div>
          </div>
        </div>,
        this.container
      )
    ) : (
      <></>
    )
  }
}
