import React from 'react'

import { error } from '~/store/actions/importError'
import { store } from '~/init'

const importHandleError = (loader) => {
  return React.lazy(async () => {
    let component
    try {
      component = await loader()
    } catch (e) {
      component = {
        default: () => null,
      }
      if (store && store.dispatch) {
        store.dispatch(error())
      }
    }
    return component
  })
}

export default importHandleError
