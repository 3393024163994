import React from 'react'

export const TooltipContent = ({
  children,
  className,
  tooltipText,
  iconType,
  disabled,
  ...rest
}) => {
  const [open, setOpen] = React.useState(false)

  const handleEnter = React.useCallback(() => setOpen(true), [setOpen])
  const handleLeave = React.useCallback(() => setOpen(false), [setOpen])

  return (
    <span
      className={`tooltip-content ${iconType ? 'mr-2_5' : ''} ${className}`}
      {...rest}
      onMouseEnter={handleEnter}
      onMouseLeave={handleLeave}
    >
      {children}
      {open && !disabled ? (
        <div className="tooltip bs-tooltip-top" role="tooltip">
          <div className="arrow" />
          <div className="tooltip-inner">{tooltipText}</div>
        </div>
      ) : null}
    </span>
  )
}

export default TooltipContent
