import React from 'react'
import { Route } from 'react-router-dom'

import IndexPage from './IndexPage'

export const partnerRoutes = (
  <>
    <Route exact index element={<IndexPage />} />
  </>
)
