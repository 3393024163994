import posthog from 'posthog-js'

import { PLANS_LEVELS } from '~/common/utils/constants/providers'

export const identify = (user = {}, profile = {}) => {
  posthog?.identify(user?.id, {
    'User ID': user?.id,
    'Account type': profile?.subscription?.is_free === false ? 'Paid' : 'Free',
    'Account level':
      profile?.subscription?.plan_level === PLANS_LEVELS.STANDARD ? 'Standard plan' : 'Start plan',
    'Account Subscription': profile?.subscription?.name,
    Language: profile?.language,
  })
}

export const signup = (user = {}, profile = {}) => {
  identify(user, profile)
  posthog?.capture('user_signup', {
    'User ID': user?.id,
    'Account type': profile?.subscription?.is_free === false ? 'Paid' : 'Free',
    'Account level':
      profile?.subscription?.plan_level === PLANS_LEVELS.STANDARD ? 'Standard plan' : 'Start plan',
    'Account Subscription': profile?.subscription?.name,
    Language: profile?.language,
  })
}

export const upgrade = (event = {}) => {
  const payload = {
    'Account type': event?.is_free === false ? 'Paid' : 'Free',
    'Account level': event?.plan_level === PLANS_LEVELS.STANDARD ? 'Standard plan' : 'Start plan',
    'Account Subscription': event?.name,
  }
  posthog?.capture('$set', { $set: payload })
  posthog?.capture('user_upgrade', payload)
}

export const trialStart = () => {
  const payload = { 'Account level': 'Trial Standard plan' }
  posthog?.capture('$set', { $set: payload })
  posthog?.capture('trial_start', payload)
}

export const reset = () => {
  posthog?.reset()
}
