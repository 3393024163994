import React from 'react'

import DeleteAccountQuiz from './DeleteAccountQuiz'

export default function DeleteAccount(props) {
  return (
    <div>
      <DeleteAccountQuiz {...props} />
    </div>
  )
}
