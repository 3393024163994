export const vis = (() => {
  let eventKey
  let stateKey
  const keys = {
    hidden: 'visibilitychange',
    webkitHidden: 'webkitvisibilitychange',
    mozHidden: 'mozvisibilitychange',
    msHidden: 'msvisibilitychange',
  }
  for (stateKey in keys) {
    if (stateKey in document) {
      eventKey = keys[stateKey]
      break
    }
  }
  return (handler) => {
    if (handler) document.addEventListener(eventKey, handler)
    return !document[stateKey]
  }
})()

export const isTabVisible = () => vis()
