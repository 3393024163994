import debounce from 'lodash/debounce'
import get from 'lodash/get'
import { upgrade } from '~/common/utils/posthog'

import { calculatePriceData } from './helpers'
import { PLANS_LEVELS } from './constants/providers'

const LOCAL_STORAGE_GTM = {
  UPGRADE_EVENT: 'posthog.upgradeEvent',
  PURCHASE_EVENT: 'gtm.purchaseEvent',
  STANDARD_PLAN_PURCHASE_EVENT: 'gtm.standardPlanPurchaseEvent',
  START_PLAN_PURCHASE_EVENT: 'gtm.startPlanPurchaseEvent',
}

export const getDataLayer = () => {
  window.dataLayer = window.dataLayer || []
  return window.dataLayer
}

export const addEvent = (event) => {
  const dataLayer = getDataLayer()
  dataLayer.push(event)
}

export const addPosthogUpgradeEvent = (event) => {
  upgrade()
}

const pageviewTrack = (store) => (location, action) => {
  const state = store.getState()

  const userId = get(state, 'resource.user.data.id')
  const isFree = get(state, 'resource.profile.data.subscription.is_free')
  const emailConfirmationNeeded = get(state, 'resource.profile.data.email_confirmation_needed')

  const event = {
    event: 'pageviewCustomEvent',
    pagePath: `${location.pathname}${location.search}${location.hash}`,
    pageTitle: window.document.title,
    userId: userId ? userId : undefined,
    dimension1: userId ? '1' : undefined,
    dimension2:
      emailConfirmationNeeded === true
        ? true
        : emailConfirmationNeeded === false
        ? false
        : undefined,
    dimension3: isFree === true ? false : isFree === false ? true : undefined,
  }

  addEvent(event)
}

export const prepareTransactionEvent = (response, props) => {
  const { articleData, discount, upgradeConditions, profile, address, billing } = props

  const { currency, articleVat, conversionValue } = calculatePriceData({
    article: get(articleData, 'data'),
    profile,
    address,
    billing,
    discount,
    upgradeConditions,
  })

  const event = {
    event: 'transactionComplete',
    transactionId: response.order_id,
    transactionTotal: conversionValue,
    transactionCurrency: currency,
    transactionTax: articleVat,
    transactionProducts: [
      {
        sku: response.order_id,
        name: get(articleData, 'data.name'),
        price: conversionValue,
        quantity: 1,
      },
    ],
  }
  return event
}

export const prepareSignupEvent = (label) => {
  const event = {
    event: 'customEvent',
    eventCategory: 'accounts',
    eventAction: 'signed up',
    eventLabel: label,
    eventValue: 1,
  }
  return event
}

export const preparePurchaseEvent = (response, props) => {
  const { articleData, discount, upgradeConditions, profile, address, billing } = props

  const { currency, articleVat, conversionValue } = calculatePriceData({
    article: get(articleData, 'data'),
    profile,
    address,
    billing,
    discount,
    upgradeConditions,
  })

  const event = {
    event: 'purchase',
    ecommerce: {
      transaction_id: response.order_id,
      value: conversionValue,
      tax: articleVat,
      currency,
      items: [
        {
          item_id: get(articleData, 'data.id'),
          item_name: get(articleData, 'data.name'),
        },
      ],
    },
  }
  return event
}

export const prepareUpgradeEvent = (response, props) => {
  const articlePlanLevel = get(props, 'articleData.data.plan_level')
  const articleName = get(props, 'articleData.data.name')

  const event = {
    name: articleName,
    is_free: false,
    plan_level: articlePlanLevel,
  }
  return event
}

export const prepareSignupRegistrationEvent = () => {
  return {
    event: 'signup-registration',
  }
}

export const prepareTrialRegistrationEvent = () => {
  return {
    event: 'trial-registration',
  }
}

export const prepareStartPlanPurchaseEvent = () => {
  return {
    event: 'start-plan-purchase',
  }
}

export const prepareStandardPlanPurchaseEvent = () => {
  return {
    event: 'standard-plan-purchase',
  }
}

export const storePurchaseEvents = (response, props) => {
  const isFreeUser = get(props, 'profile.data.subscription.is_free')
  const userPlanLevel = get(props, 'profile.data.subscription.plan_level')
  const articlePlanLevel = get(props, 'articleData.data.plan_level')
  const orderId = get(response, 'order_id')

  const purchaseEvent = preparePurchaseEvent(response, props)
  const upgradeEvent = prepareUpgradeEvent(response, props)
  const standardPlanPurchaseEvent = prepareStandardPlanPurchaseEvent()
  const startPlanPurchaseEvent = prepareStartPlanPurchaseEvent()

  localStorage.setItem(
    `${LOCAL_STORAGE_GTM.PURCHASE_EVENT}.${orderId}`,
    JSON.stringify(purchaseEvent)
  )

  localStorage.setItem(
    `${LOCAL_STORAGE_GTM.UPGRADE_EVENT}.${orderId}`,
    JSON.stringify(upgradeEvent)
  )

  if (articlePlanLevel === PLANS_LEVELS.STANDARD && userPlanLevel < articlePlanLevel) {
    localStorage.setItem(
      `${LOCAL_STORAGE_GTM.STANDARD_PLAN_PURCHASE_EVENT}.${orderId}`,
      JSON.stringify(standardPlanPurchaseEvent)
    )
  }

  if (articlePlanLevel === PLANS_LEVELS.START && isFreeUser) {
    localStorage.setItem(
      `${LOCAL_STORAGE_GTM.START_PLAN_PURCHASE_EVENT}.${orderId}`,
      JSON.stringify(startPlanPurchaseEvent)
    )
  }
}

export const addPurchaseEvents = (orderId) => {
  try {
    const purchaseEvent = JSON.parse(
      localStorage.getItem(`${LOCAL_STORAGE_GTM.PURCHASE_EVENT}.${orderId}`)
    )
    const upgradeEvent = JSON.parse(
      localStorage.getItem(`${LOCAL_STORAGE_GTM.UPGRADE_EVENT}.${orderId}`)
    )
    const standardPlanPurchaseEvent = JSON.parse(
      localStorage.getItem(`${LOCAL_STORAGE_GTM.STANDARD_PLAN_PURCHASE_EVENT}.${orderId}`)
    )
    const startPlanPurchaseEvent = JSON.parse(
      localStorage.getItem(`${LOCAL_STORAGE_GTM.START_PLAN_PURCHASE_EVENT}.${orderId}`)
    )

    if (purchaseEvent) addEvent(purchaseEvent)
    if (upgradeEvent) addPosthogUpgradeEvent(upgradeEvent)
    if (standardPlanPurchaseEvent) addEvent(standardPlanPurchaseEvent)
    if (startPlanPurchaseEvent) addEvent(startPlanPurchaseEvent)
  } finally {
    localStorage.removeItem(`${LOCAL_STORAGE_GTM.PURCHASE_EVENT}.${orderId}`)
    localStorage.removeItem(`${LOCAL_STORAGE_GTM.UPGRADE_EVENT}.${orderId}`)
    localStorage.removeItem(`${LOCAL_STORAGE_GTM.STANDARD_PLAN_PURCHASE_EVENT}.${orderId}`)
    localStorage.removeItem(`${LOCAL_STORAGE_GTM.START_PLAN_PURCHASE_EVENT}.${orderId}`)
  }
}

export const initPageviewTrack = (history, store, delay = 300) => {
  const pageviewTrackWithStore = debounce(pageviewTrack(store), delay)
  setTimeout(() => {
    const dataLayer = getDataLayer()
    if (!dataLayer.find((item) => item.event === 'pageviewCustomEvent')) {
      pageviewTrackWithStore(window.location)
    }
  }, 1000)
  history.listen(({ location, action }) => pageviewTrackWithStore(location, action))
}
