import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import get from 'lodash/get'
import range from 'lodash/range'
import noop from 'lodash/noop'
import isArray from 'lodash/isArray'
import isEqual from 'lodash/isEqual'
import sortBy from 'lodash/sortBy'
import flatten from 'lodash/flatten'
import { FormattedMessage } from 'react-intl'
import { v1 as uuidv1 } from 'uuid'
import { Link } from 'react-router-dom'

import Pagination from '~/components/Pagination'
import EmptyList from '~/components/EmptyList'
import { PLANS_LEVELS } from '~/common/utils/constants/providers'
import { getPlanLevelWithTrial } from '~/common/utils/helpers'

export const TableContext = React.createContext({
  setStorageName: noop,
  toggleColumnList: [],
  updateToggleColumnList: noop,
  shownColumnIdList: [],
  handleToggleColumn: noop,
})

export class TableBase extends Component {
  constructor(props) {
    super(props)
    this.state = {
      columns: TableBase.configure(props.children, props.context),
    }
    this.id = uuidv1()
  }

  static getDerivedStateFromProps(props, state) {
    return {
      columns: TableBase.configure(props.children, props.context),
    }
  }

  static configure = (children, context) => {
    let columns = flatten(isArray(children) ? children : [children])
      .filter((child) => !!child?.type)
      .map((child) => {
        return {
          type: child.type,
          ...child.props,
        }
      })

    context.updateToggleColumnList(columns)
    return columns.filter(
      ({ toggleId }) => !toggleId || context.shownColumnIdList?.includes(toggleId)
    )
  }

  makeTitles = () => {
    let columns = this.state.columns

    return columns.map(({ type: Type, className, titleClassName, ...props }, index) => {
      let classes = `${className || ''} ${titleClassName || ''}`
      classes = classes.trim()
      return (
        <Type key={index} isTitle={true} list={this.props.list} className={classes} {...props} />
      )
    })
  }

  rowClassName = () => {
    return ''
  }

  makeRow = (item, index) => {
    let columns = this.state.columns
    let rowClassName = this.props.rowClassName || this.rowClassName
    const isDisabled = this.props.isDisabled

    return (
      <tr key={index} className={rowClassName(item)}>
        {columns.map(({ type: Type, title, ...props }, i) => (
          <Type
            key={i}
            item={item}
            index={index}
            list={this.props.list}
            isDisabled={isDisabled}
            {...props}
          />
        ))}
      </tr>
    )
  }

  makeLoading = () => {
    let columns = this.state.columns

    return (
      <>
        {range(5).map((item) => (
          <tr key={item}>
            {columns.map(({ type: Type, ...props }, i) => {
              return (
                <td
                  key={i}
                  className={`cell-pending${
                    props.pendingClassName ? ` ${props.pendingClassName}` : ''
                  }`}
                >
                  <span>{props.mockData || 'Pending'}</span>
                </td>
              )
            })}
          </tr>
        ))}
      </>
    )
  }

  getResults = () => {
    const { list = {}, showOnly } = this.props
    if (showOnly && !!get(list, 'results.length')) {
      return list.results.filter((item, index) => index < showOnly)
    }
    return list.results
  }

  render() {
    const {
      context,
      list = {},
      titleClassName,
      rowClassName,
      isLoading,
      isDisabled,
      paginationCount = 100,
      paginationLimit = 100,
      paginationOnChange,
      placeholderComponent,
      itemsType,
      routerPage,
      showOnly,
      showMorePath,
      theadClassName,
      ...restProps
    } = this.props
    const titles = this.makeTitles()
    const listLength = get(list, 'results.length')
    if (!isLoading && !get(list, 'results.length')) {
      return (
        placeholderComponent || (
          <EmptyList placeholderTitle={<FormattedMessage id="tables.isEmpty" />} />
        )
      )
    }
    const results = this.getResults()
    return (
      <div>
        <table
          {...restProps}
          style={{ tableLayout: 'fixed' }}
          className={`${isDisabled ? 'opacity-50' : ''} ${
            restProps?.className
              ? restProps?.className
              : 'table-hover  table-responsive-md table-bordered table'
          }`}
          id={this.id}
        >
          <thead className={typeof theadClassName !== 'undefined' ? theadClassName : 'thead-light'}>
            <tr>{titles}</tr>
          </thead>
          <tbody>
            {!!get(results, 'length') && !isLoading && results.map(this.makeRow)}
            {isLoading && this.makeLoading()}
          </tbody>
        </table>
        {!showOnly && results && !results.length < paginationCount && paginationOnChange && (
          <div className="row">
            <div className="col">
              <Pagination
                allItemsCount={paginationCount}
                countPerPage={paginationLimit}
                onChange={paginationOnChange}
                disabled={isLoading}
                itemsType={itemsType}
                routerPage={routerPage}
                tableId={this.id}
              />
            </div>
          </div>
        )}
        {showOnly && listLength > showOnly && (
          <small className="show-only__container">
            <FormattedMessage id="common.shown" />{' '}
            <span className="show-only__show-count">{showOnly}</span>{' '}
            <FormattedMessage id="common.shownOf" />{' '}
            <span className="show-only__pagination-count">{paginationCount}</span>{' '}
            <span className="show-only__items-type">{itemsType}</span>.{' '}
            {showMorePath && (
              <Link className="show-only__show-more-path link-underline" to={showMorePath}>
                <FormattedMessage id="common.showAll" />
              </Link>
            )}
          </small>
        )}
      </div>
    )
  }
}

export function TableContextProviderBase({ planLevel, children }) {
  const keepRef = React.useRef({ toggleIdList: [], originalStoredShownIdList: null })

  const [localStorageName, setLocalStorageName] = React.useState('')
  const [toggleColumnList, setToggleColumnList] = React.useState([])
  const [shownColumnIdList, setShownColumnIdList] = React.useState([])

  const setStorageName = React.useCallback((name) => {
    if (name) {
      setLocalStorageName(`table.${name}.shownColumns`)
    }
  }, [])

  const updateToggleColumnList = React.useCallback((columnList) => {
    const newToggleColumnList = columnList.filter(({ toggleId }) => toggleId)
    const newToggleIdList = sortBy(newToggleColumnList.map(({ toggleId }) => toggleId))

    if (!isEqual(newToggleIdList, keepRef.current.toggleIdList)) {
      keepRef.current.toggleIdList = newToggleIdList
      setToggleColumnList(newToggleColumnList)
    }
  }, [])

  const handleToggleColumn = React.useCallback(
    (id) => {
      if (planLevel === PLANS_LEVELS.START) return

      setShownColumnIdList((state) => {
        const isExisted = state.find((columnId) => columnId === id)
        const newList = isExisted ? state.filter((columnId) => columnId !== id) : [...state, id]
        if (localStorageName) {
          localStorage.setItem(localStorageName, JSON.stringify(newList))
        }
        return newList
      })
    },
    [planLevel, localStorageName]
  )

  const value = React.useMemo(
    () => ({
      setStorageName,
      toggleColumnList,
      updateToggleColumnList,
      shownColumnIdList,
      handleToggleColumn,
    }),
    [
      toggleColumnList,
      shownColumnIdList,
      handleToggleColumn,
      updateToggleColumnList,
      setStorageName,
    ]
  )

  React.useEffect(() => {
    if (!toggleColumnList.length) return

    const toggleIdList = toggleColumnList.map(({ toggleId }) => toggleId)
    let newShownIdList = toggleColumnList
      .filter(({ toggleId, defaultHidden }) => toggleId && !defaultHidden)
      .map(({ toggleId }) => toggleId)

    if (localStorageName && planLevel !== PLANS_LEVELS.START) {
      try {
        const storedShownIdList = JSON.parse(localStorage.getItem(localStorageName))
        if (isArray(storedShownIdList)) {
          // save [storedShownIdList] only 1 time, because it will be updated shortly after
          if (!keepRef.current.originalStoredShownIdList) {
            keepRef.current.originalStoredShownIdList = storedShownIdList
          }
          // remove stored Id which do not exist in table
          newShownIdList = keepRef.current.originalStoredShownIdList.filter((id) =>
            toggleIdList.includes(id)
          )
          // when a attribute was removed, we will remove it in localStorage also
          localStorage.setItem(localStorageName, JSON.stringify(newShownIdList))
        }
      } catch {
        localStorage.setItem(localStorageName, JSON.stringify([]))
      }
    }

    setShownColumnIdList(newShownIdList)
  }, [planLevel, localStorageName, toggleColumnList])

  return <TableContext.Provider value={value}>{children}</TableContext.Provider>
}

export const TableContextProvider = compose(
  connect(() => (state) => ({
    planLevel: getPlanLevelWithTrial(get(state, 'resource.profile')),
  }))
)(TableContextProviderBase)

function Table(props) {
  const context = React.useContext(TableContext)

  return <TableBase {...props} context={context} />
}

export default Table
