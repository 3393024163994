import React from 'react'
import { IntlProvider } from 'react-intl'
import Cookies from 'js-cookie'

import { getDomain, getUserLanguage } from '~/common/utils/helpers'
import { DEFAULT_LANGUAGE, supportedLanguages } from '~/common/utils/constants'
import IntlPolyfill from '~/components/IntlPolyfill'

import '@formatjs/intl-pluralrules/polyfill'
import '@formatjs/intl-pluralrules/locale-data/en'

import '@formatjs/intl-relativetimeformat/polyfill'
import '@formatjs/intl-relativetimeformat/locale-data/sv'

export const IntlContext = React.createContext()

export class IntlProviderWrapper extends React.Component {
  constructor(props) {
    super(props)

    this.switchToEnglish = async () => {
      const locale = 'en'
      Cookies.set('gan-locale', locale, { domain: getDomain(null) })
      const messages = (await import(`../../i18n/${locale}.js`))?.default
      this.setState({ locale, messages })
    }

    this.switchToSwedish = async () => {
      const locale = 'sv'
      Cookies.set('gan-locale', locale, { domain: getDomain(null) })
      const messages = (await import(`../../i18n/${locale}.js`))?.default
      this.setState({ locale, messages })
    }

    let locale = props.locale || Cookies.get('gan-locale') || getUserLanguage()
    if (supportedLanguages.indexOf(locale) === -1) {
      locale = DEFAULT_LANGUAGE
    }
    if (locale) {
      Cookies.set('gan-locale', locale, { domain: getDomain(null) })
    }
    this.state = {
      locale,
      messages: null,
      switchTo: {
        en: this.switchToEnglish,
        sv: this.switchToSwedish,
      },
    }
  }

  async componentDidMount() {
    const locale = this.state.locale
    const messages = (await import(`../../i18n/${locale}.js`))?.default
    this.setState({ locale, messages })
  }

  async componentDidUpdate(props, state) {
    if (
      this.props.locale &&
      props.locale !== this.props.locale &&
      this.state.locale !== this.props.locale
    ) {
      Cookies.set('gan-locale', this.props.locale, { domain: getDomain(null) })
      const messages = (await import(`../../i18n/${this.props.locale}.js`))?.default
      this.setState({
        locale: this.props.locale,
        messages,
      })
    }
  }

  render() {
    const { children } = this.props
    const { locale, messages } = this.state
    if (!messages) {
      return null
    }
    return (
      <IntlPolyfill locales={supportedLanguages}>
        <IntlContext.Provider value={this.state}>
          <IntlProvider
            key={locale}
            locale={locale}
            messages={messages}
            defaultLocale={DEFAULT_LANGUAGE}
            textComponent="span"
          >
            {children}
          </IntlProvider>
        </IntlContext.Provider>
      </IntlPolyfill>
    )
  }
}

export const useIntlContext = () => React.useContext(IntlContext)

export default IntlContext
