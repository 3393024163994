export const update = (user, isPartner = false) => {
  if (window.Intercom && window.INTERCOME_APP_ID && user) {
    window.intercomSettings = {
      app_id: window.INTERCOME_APP_ID,
      email: user.email,
      user_id: isPartner ? user.user : user.id,
      created_at: isPartner ? user.created : user.signup_time,
    }
    window.Intercom('update')
  }
}

export const reset = () => {
  if (window.Intercom && window.INTERCOME_APP_ID) {
    window.intercomSettings = {
      app_id: window.INTERCOME_APP_ID,
    }
    window.Intercom('shutdown')
    window.Intercom('boot', window.intercomSettings)
  }
}

export const toggleShow = (show) => {
  if (window.Intercom && window.INTERCOME_APP_ID) {
    if (show) {
      window.Intercom('show')
    } else {
      window.Intercom('hide')
    }
  }
}

export const addOnHideHandler = (handler) => {
  if (window.Intercom && window.INTERCOME_APP_ID) {
    window.Intercom('onHide', handler)
  }
}

export const addOnShowHandler = (handler) => {
  if (window.Intercom && window.INTERCOME_APP_ID) {
    window.Intercom('onShow', handler)
  }
}
