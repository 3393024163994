import React, { Component } from 'react'
import get from 'lodash/get'
import { FiChevronUp, FiChevronDown } from 'react-icons/fi'

import { cutString } from '~/common/utils/helpers'

export function Column(props) {
  let {
    isTitle,
    isDisabled,
    title,
    orderable,
    format,
    field,
    orderingField = field,
    item,
    index,
    align = 'left',
    width,
    list,
    className,
    length = 255,
  } = props

  className = `${className || ''} text-${align}`
  let style = { width }

  if (isTitle) {
    let order
    let orderBy
    let direction = 'asc'

    if (orderable) {
      // TODO optimization
      orderBy = list.filters.data.ordering || 'index'
      if (orderBy[0] === '-') {
        direction = 'desc'
        orderBy = orderBy.substr(1)
      }
      if (orderBy === orderingField) {
        order =
          direction === 'asc' ? (
            <FiChevronDown className="ml-2" />
          ) : (
            <FiChevronUp className="ml-2" />
          )
      }

      className += ' pointer'
    }

    function reorder() {
      if (orderable && list.filters.orderBy) {
        let prefix = orderable === 'desc' ? '-' : ''
        if (orderBy === orderingField) {
          prefix = direction === 'asc' ? '-' : ''
        }
        list.filters.orderBy(`${prefix}${orderingField}`)
      }
    }

    return (
      <th style={style} className={className} onClick={reorder}>
        {title}
        {order}
      </th>
    )
  }

  if (!format) {
    format = (value) => value
  }

  let printedValue = get(item, field)

  if (typeof printedValue === 'string') {
    printedValue = cutString(printedValue, length)
  }

  if (format.prototype instanceof Component) {
    const FormatTag = format
    printedValue = <FormatTag>{printedValue}</FormatTag>
  } else {
    printedValue = format(printedValue, item, { index, field, isDisabled })
  }

  return <td className={className}>{printedValue}</td>
}

export default React.memo(Column)
