import React, { forwardRef } from 'react'
import clsx from 'clsx'
import { CircleNotch } from 'phosphor-react'
import { getSize, getVariant } from '~/common/components/Button/utils'
import { sharedInner, sharedOuter } from '~/common/components/Button/constants'

const Button = forwardRef(
  (
    { children, variant, size, className, isLoading = false, disabled = false, ...restProps },
    ref
  ) => {
    return (
      <button
        className={clsx(sharedOuter, getVariant(variant), getSize(size), className, {
          'pointer-events-none': isLoading || disabled,
        })}
        ref={ref}
        disabled={disabled}
        {...restProps}
      >
        <span
          className={clsx(sharedInner, {
            'opacity-0': isLoading,
          })}
        >
          {children}
        </span>
        {isLoading ? <CircleNotch className="absolute animate-spin" /> : null}
      </button>
    )
  }
)

export default Button
