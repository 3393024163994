import React, { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { Link } from 'react-router-dom'
import {
  Bell,
  Gear,
  IdentificationCard,
  PlugsConnected,
  RocketLaunch,
  ShareNetwork,
  SignOut,
  User,
  UserCircleGear,
} from 'phosphor-react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import Cookies from 'js-cookie'

import { classNames } from '~/common/utils/classNames'
import { connectResource } from '~/common/utils/resource'
import { maxNotificationsCountToShow } from '~/common/utils/constants'
import { logout } from '~/common/session'
import { setData } from '~/common/utils/resource'
import { getDomain } from '~/common/utils/helpers'
import { getCookie } from '~/lib/utils'

export const MyAccount = ({ user, profile, logout, setData, partnerSession, userSession }) => {
  const email = user?.data?.email
  const notificationsCount = profile?.data?.unseen_messages

  const isLoggedInAsUser = !!partnerSession && !!userSession && !!user?.data?.email

  const onSwitchAccountClick = React.useCallback(
    (e) => {
      Cookies.remove('jwt', { domain: getDomain().replace('app', '') })
      logout(true)
      setData(
        {
          token: getCookie('jwt'),
          partnerToken: getCookie('partner_jwt'),
        },
        { resource: { namespace: 'session' } }
      )
    },
    [logout, setData]
  )

  return (
    <Menu as="div" className="relative text-left">
      <div>
        <Menu.Button className="flex w-full items-center justify-between gap-2.5 rounded-lg border border-transparent px-1.5 py-1.5 text-left text-white hover:bg-white hover:bg-opacity-10 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-offset-2 focus:ring-offset-blue-900 sm:px-3">
          <div className="hidden items-center gap-2.5 md:flex">
            <div className="font-medium leading-5 text-white">
              <FormattedMessage id="title.myAccount" />
            </div>
          </div>
          <User className="block md:hidden" size={20} />
          <svg
            fill="none"
            height="13"
            stroke="currentColor"
            strokeWidth="1.5"
            viewBox="0 0 16 24"
            className="hidden md:block"
          >
            <path d="M13 8.517L8 3 3 8.517M3 15.48l5 5.517 5-5.517"></path>
          </svg>
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-1 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          unmount={false}
          className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
        >
          <div className="p-2">
            <div className="px-2 py-1">
              <div className="text-xs text-gray-500">
                <FormattedMessage id="header.signedUpAs" />
              </div>
              <div className="truncate text-xs font-medium">{email}</div>
              {isLoggedInAsUser && (
                <Link to="/partner" onClick={onSwitchAccountClick}>
                  <button className="text-xs font-medium text-blue-500 hover:text-blue-700">
                    <FormattedMessage id="partner.switch.account" />
                  </button>
                </Link>
              )}
            </div>
          </div>
          <div className="p-2">
            <Menu.Item>
              {({ active }) => (
                <Link
                  to="/upgrade"
                  className={`${classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'flex items-center gap-2 rounded-md  py-1.5 px-2 hover:bg-gray-100'
                  )}`}
                >
                  <RocketLaunch weight="duotone" size={16} />
                  <FormattedMessage id="appNavBar.profileDropDown.upgrade" />
                </Link>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <Link
                  to="/account/profile"
                  className={`${classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'flex items-center gap-2 rounded-md  py-1.5 px-2 hover:bg-gray-100'
                  )}`}
                >
                  <IdentificationCard weight="duotone" size={16} />
                  <FormattedMessage id="appNavBar.profileDropDown.profile" />
                </Link>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <Link
                  to="/account"
                  className={`${classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'flex items-center gap-2 rounded-md  py-1.5 px-2 hover:bg-gray-100'
                  )}`}
                >
                  <UserCircleGear weight="duotone" size={16} />
                  <FormattedMessage id="appNavBar.profileDropDown.account" />
                </Link>
              )}
            </Menu.Item>
          </div>
          <div className="p-2">
            <Menu.Item>
              {({ active }) => (
                <Link
                  to="/account/messages"
                  className={`${classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'flex items-center justify-between rounded-md py-1.5 px-2 hover:bg-gray-100'
                  )}`}
                >
                  <span className="flex items-center gap-2">
                    <Bell weight="duotone" size={16} />
                    <FormattedMessage id="appNavBar.profileDropDown.notifications" />
                  </span>
                  {notificationsCount && (
                    <span className="flex h-5 w-5 items-center justify-center rounded-full bg-red-600 font-mono text-[10px] font-semibold leading-none text-white">
                      {notificationsCount > maxNotificationsCountToShow
                        ? `${maxNotificationsCountToShow}+`
                        : notificationsCount}
                    </span>
                  )}
                </Link>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <Link
                  to="/account/settings"
                  className={`${classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'flex items-center gap-2 rounded-md py-1.5 px-2 hover:bg-gray-100'
                  )}`}
                >
                  <Gear weight="duotone" size={16} />
                  <FormattedMessage id="appNavBar.profileDropDown.settings" />
                </Link>
              )}
            </Menu.Item>
          </div>
          <div className="p-2">
            <Menu.Item>
              {({ active }) => (
                <Link
                  to="/account/api"
                  className={`${classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'flex items-center gap-2 rounded-md py-1.5 px-2 hover:bg-gray-100'
                  )}`}
                >
                  <PlugsConnected weight="duotone" size={16} />
                  <FormattedMessage id="appNavBar.profileDropDown.api" />
                </Link>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <Link
                  to="/account/affiliate"
                  className={`${classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'flex items-center gap-2 rounded-md py-1.5 px-2 hover:bg-gray-100'
                  )}`}
                >
                  <ShareNetwork weight="duotone" size={16} />
                  <FormattedMessage id="appNavBar.profileDropDown.affiliate" />
                </Link>
              )}
            </Menu.Item>
          </div>
          <div className="p-2">
            <Menu.Item>
              {({ active }) => (
                <Link
                  className={`${classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'flex items-center gap-2 rounded-md py-1.5 px-2 hover:bg-gray-100'
                  )}`}
                  to="/logged_out"
                >
                  <SignOut weight="duotone" size={16} />
                  <FormattedMessage id="appNavBar.profileDropDown.logout" />
                </Link>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

export default compose(
  connect(
    (state) => ({
      userSession: state?.resource?.session?.data?.token,
      partnerSession: state?.resource?.session?.data?.partnerToken,
    }),
    { setData, logout }
  ),
  connectResource({
    namespace: 'user',
    endpoint: 'user',
    async: true,
    prefetch: false,
  }),
  connectResource({
    namespace: 'profile',
    endpoint: 'profile',
    async: true,
    prefetch: false,
  })
)(MyAccount)
