import React from 'react'
import { injectIntl } from 'react-intl'

class PageTitle extends React.Component {
  prepareTitle = () => {
    const titles = this.props.titles || ['title.default']
    const formattedTitles = titles.map((id) => this.props.intl.formatMessage({ id }))

    return `${formattedTitles.join(' - ')} | Get a Newsletter`
  }

  componentDidMount() {
    document.title = this.prepareTitle()
  }

  render() {
    return null
  }
}

export default injectIntl(PageTitle)
