export const ERROR = '@ds-import-error/error'
export const NO_ERROR = '@ds-import-error/no-error'

export function error() {
  return {
    type: ERROR,
  }
}

export function noError() {
  return {
    type: NO_ERROR,
  }
}
