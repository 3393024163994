import { SHOW, HIDE } from '../actions/intercom'

export const defaultState = {
  isOpen: false,
}

export function reducer(state = defaultState, { type }) {
  switch (type) {
    case SHOW: {
      return {
        ...state,
        isOpen: true,
      }
    }
    case HIDE: {
      return {
        ...state,
        isOpen: false,
      }
    }
    default: {
      return state
    }
  }
}
