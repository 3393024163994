export function checkPublicDomain(email) {
  if (!email) {
    return false
  }
  const parts = `${email}`.split('@')
  if (!parts || (parts && parts.length !== 2)) {
    return false
  }
  return publicDomainsList.indexOf(parts[1]) !== -1
}

const publicDomainsList = [
  'comhem.se',
  'bahnhof.se',
  'bredband2.com',
  'telia.se',
  'telia.com',
  'telenor.se',
  'aol.com',
  'alice.it',
  'aliceadsl.fr',
  'aim.com',
  'arcor.de',
  'att.net',
  'bellsouth.net',
  'bigpond.com',
  'bigpond.net.au',
  'bluewin.ch',
  'blueyonder.co.uk',
  'bol.com.br',
  'centurytel.net',
  'chello.nl',
  'charter.net',
  'club-internet.fr',
  'comcast.net',
  'cox.net',
  'earthlink.net',
  'facebook.com',
  'free.fr',
  'freenet.de',
  'frontiernet.net',
  'gmail.com',
  'gmail.se',
  'gmail.no',
  'gmail.dk',
  'gmail.fi',
  'googlemail.com',
  'gmx.de',
  'gmx.net',
  'hetnet.nl',
  'home.nl',
  'hotmail.com',
  'hotmail.se',
  'hotmail.no',
  'hotmail.dk',
  'hotmail.fi',
  'hotmail.co.uk',
  'hotmail.de',
  'hotmail.es',
  'hotmail.fr',
  'hotmail.it',
  'icloud.com',
  'icloud.se',
  'icloud.no',
  'icloud.dk',
  'icloud.fi',
  'ig.com.br',
  'juno.com',
  'laposte.net',
  'libero.it',
  'live.ca',
  'live.com',
  'live.com.au',
  'live.fr',
  'live.it',
  'live.nl',
  'live.co.uk',
  'live.se',
  'live.no',
  'live.dk',
  'live.fi',
  'mac.com',
  'mac.se',
  'mac.no',
  'mac.dk',
  'mac.fi',
  'mail.com',
  'mail.ru',
  'me.com',
  'msn.com',
  'neuf.fr',
  'ntlworld.com',
  'optonline.net',
  'optusnet.com.au',
  'orange.fr',
  'outlook.com',
  'outlook.se',
  'outlook.no',
  'outlook.dk',
  'outlook.fi',
  'online.no',
  'planet.nl',
  'protonmail.com',
  'proton.me',
  'qq.com',
  'rambler.ru',
  'rediffmail.com',
  'rocketmail.com',
  'sbcglobal.net',
  'sfr.fr',
  'shaw.ca',
  'sky.com',
  'skynet.be',
  'sympatico.ca',
  'terra.com.br',
  'telenet.be',
  'tin.it',
  'tiscali.co.uk',
  'tiscali.it',
  't-online.de',
  'uol.com.br',
  'verizon.net',
  'virgilio.it',
  'voila.fr',
  'wanadoo.fr',
  'web.de',
  'windstream.net',
  'yahoo.com',
  'yahoo.se',
  'yahoo.no',
  'yahoo.dk',
  'yahoo.fi',
  'yahoo.co.id',
  'yahoo.co.in',
  'yahoo.co.jp',
  'yahoo.co.uk',
  'yahoo.com.ar',
  'yahoo.com.au',
  'yahoo.com.br',
  'yahoo.com.mx',
  'yahoo.com.sg',
  'yahoo.de',
  'yahoo.es',
  'yahoo.fr',
  'yahoo.in',
  'yahoo.it',
  'yandex.ru',
  'ymail.com',
  'zonnet.nl',
]
