export const OFFLINE = '@ds-offline/offline'
export const ONLINE = '@ds-offline/online'

export function offline() {
  return {
    type: OFFLINE,
  }
}

export function online() {
  return {
    type: ONLINE,
  }
}
