import React from 'react'
import { Route } from 'react-router-dom'

import AsyncComponent from '~/components/AsyncComponent'

const PayByCardAcceptComponent = () => import('./Pays/PayByCardAccept')
const PayByCardAccept = AsyncComponent({ component: PayByCardAcceptComponent })

const PayByCardCancelComponent = () => import('./Pays/PayByCardCancel')
const PayByCardCancel = AsyncComponent({ component: PayByCardCancelComponent })

const PayByInvoiceAcceptComponent = () => import('./Pays/PayByInvoiceAccept')
const PayByInvoiceAccept = AsyncComponent({ component: PayByInvoiceAcceptComponent })

const NewCreditCardComponent = () => import('./Pays/NewCreditCardContainer')
const NewCreditCard = AsyncComponent({ component: NewCreditCardComponent })

import UpgradePage, { upgradePageRoutes } from './UpgradePage'

export const upgradeRoutes = (
  <>
    <Route exact path="new-credit-card" element={<NewCreditCard />} />
    <Route exact path="pay-by-invoices/accept" element={<PayByInvoiceAccept />} />
    <Route exact path="pay-by-card/accept" element={<PayByCardAccept />} />
    <Route exact path="cancel" element={<PayByCardCancel />} />
    <Route element={<UpgradePage />}>{upgradePageRoutes}</Route>
  </>
)
