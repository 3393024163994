import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { HourglassMedium } from 'phosphor-react'
import get from 'lodash/get'
import { differenceInDays, isBefore } from 'date-fns'
import { getPlanLevelWithTrial } from '~/common/utils/helpers'
import { PLANS_LEVELS } from '~/common/utils/constants/providers'

export function TrialAlertCardBase({ nameIntlId, profile }) {
  const intl = useIntl()

  const trial = get(profile, 'data.trial')
  const name = intl.formatMessage({ id: nameIntlId })
  const nameLowercase = name.toLowerCase()

  const dayLeft = React.useMemo(() => {
    const trialEndDate = get(profile, 'data.trial.end_date', '')
    if (!trialEndDate || isBefore(new Date(trialEndDate), new Date())) return 0
    return differenceInDays(new Date(trialEndDate), new Date()) + 1
  }, [profile])

  const planLevel = getPlanLevelWithTrial(profile)

  if (!trial || (trial && trial?.upgrade_date) || planLevel >= PLANS_LEVELS.STANDARD) {
    return null
  }

  return (
    <div
      className={`mb-8 flex items-center gap-3 rounded-lg border px-4 py-3 text-gray-900 ${
        dayLeft ? 'border-yellow-300 bg-yellow-100' : 'border-red-300 bg-red-100'
      }`}
      data-test="trial-alert-card"
    >
      <HourglassMedium className={dayLeft ? 'text-yellow-700' : 'text-red-700'} size={20} />
      <div>
        <span className="mr-1 font-medium">
          {dayLeft ? (
            <FormattedMessage id="alertTrial.dayLeft" values={{ count: dayLeft }} />
          ) : (
            <FormattedMessage id="alertTrial.ended" />
          )}
        </span>
        <span className="mr-1">-</span>
        {!!dayLeft && (
          <span className="mr-1">
            <FormattedMessage
              id="alertTrial.periodExpires"
              values={{ expire: get(profile, 'data.trial.end_date', '') }}
            />
          </span>
        )}
        <a className="gan-text-link mr-1 font-medium capitalize" href="/upgrade?planLevel=20">
          <FormattedMessage id="alertTrial.upgrade" />
        </a>
        <span>
          <FormattedMessage id="alertTrial.toAvoidLosing" values={{ name: nameLowercase }} />
        </span>
      </div>
    </div>
  )
}

export const TrialAlertCard = compose(
  connect(() => (state) => ({
    profile: get(state, 'resource.profile'),
  }))
)(TrialAlertCardBase)

export default TrialAlertCard
