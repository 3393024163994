import React from 'react'
import { injectIntl } from 'react-intl'
import { compose } from 'redux'

import { prepareMessageTranslate } from '~/components/CustomValidationError'

let key = 1
const getKey = () => (key += 1)

export const FormValidationError = (props) => {
  const { validationMessages = null, className, intl } = props
  const prepareMessage = prepareMessageTranslate(intl.formatMessage)
  if (Array.isArray(validationMessages)) {
    return validationMessages.map((msg) => (
      <div className={`invalid-feedback ${className}`} key={getKey()}>
        {prepareMessage(msg).message}
      </div>
    ))
  }

  if (typeof validationMessages === 'string') {
    return (
      <div className={`invalid-feedback ${className}`}>
        {prepareMessage(validationMessages).message}
      </div>
    )
  }

  if (React.isValidElement(validationMessages) && validationMessages !== null) {
    return <div className={`invalid-feedback ${className}`}>{validationMessages}</div>
  }

  if (
    validationMessages &&
    typeof validationMessages === 'object' &&
    Object.keys(validationMessages).length
  ) {
    return Object.keys(validationMessages).map((key) => (
      <FormValidationError
        key={getKey()}
        intl={intl}
        validationMessages={validationMessages[key]}
      />
    ))
  }

  return null
}

export default compose(injectIntl)(FormValidationError)
