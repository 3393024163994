import React from 'react'

import AsyncComponent from '~/components/AsyncComponent'

const StripeWrapperComponent = () => import('./wrapper')

const StripeWrapper = AsyncComponent({
  component: StripeWrapperComponent,
})

export default StripeWrapper
