import React from 'react'
import { FormattedMessage } from 'react-intl'
import get from 'lodash/get'

import { getDefaultTypeDateAndTime, getMonthDay, getTime } from '../formatDate'
import { PLANS_LEVELS } from './providers'

export const currencyOptions = [
  {
    value: 'SEK',
    label: 'SEK',
  },
  {
    value: 'USD',
    label: 'USD',
  },
]

export const PAYMENT_PROVIDER_MANUAL = 1
export const PAYMENT_PROVIDER_PAYSON = 2
export const PAYMENT_PROVIDER_EPAY = 3
export const PAYMENT_PROVIDER_STRIPE = 4
export const PAYMENT_PROVIDER_FORTNOX = 5
export const paymentProvider = [
  {
    value: PAYMENT_PROVIDER_MANUAL, // bankgiro
    text: 'MANUAL',
    label: 'Manual',
  },
  {
    value: 2,
    text: 'PAYSON',
    label: 'Payson',
  },
  {
    value: 3, // default value
    text: 'EPAY',
    label: 'Epay',
  },
  {
    value: 4,
    text: 'STRIPE',
    label: 'Stripe',
  },
  {
    value: 5, // disable
    text: 'FORTNOX',
    label: 'Fortnox',
  },
]

export const periodOptions = [
  {
    value: '1',
    label: <FormattedMessage id="common.monthly" />,
  },
  {
    value: '12',
    label: <FormattedMessage id="common.yearly" />,
  },
]

export const planLevelOptions = [
  {
    value: PLANS_LEVELS.START,
    label: <FormattedMessage id="plan.name.start.long" />,
  },
  {
    value: PLANS_LEVELS.STANDARD,
    label: <FormattedMessage id="plan.name.standard.long" />,
  },
]

export const DEFAULT_SUBSCRIPTION_START = 0

export const defaultPagination = 100

export const defaultSelectDebounceTimeout = 300

export const defaultJsonTemplatesPagination = 4

export const available_languages = [
  { code: 'en', name: 'English' },
  { code: 'sv', name: 'Svenska' },
]

export const supportedLanguages = available_languages.map((item) => item.code)

export const CURRENCIES_SEK = 'SEK'
export const CURRENCIES_USD = 'USD'
export const CURRENCIES = {
  [CURRENCIES_SEK]: CURRENCIES_SEK,
  [CURRENCIES_USD]: CURRENCIES_USD,
}

export const currencies = ['SEK', 'USD']

export const languages = [
  { label: <FormattedMessage id="languages.swedish" />, value: 'sv' },
  { label: <FormattedMessage id="languages.english" />, value: 'en' },
]

export const getOpeningsChartOptions = (dataIndex) => {
  return {
    legend: {
      display: false,
      position: 'top',
      labels: {
        padding: 15,
        usePointStyle: true,
        position: 'top',
        pointStyle: 'circle',
        fontFamily: 'Inter Var',
      },
    },
    responsive: true,
    aspectRatio: 5,
    maintainAspectRatio: true,
    scales: {
      gridLines: [
        {
          color: '#38a169',
        },
      ],
      xAxes: [
        {
          ticks: {
            display: true,
            padding: 10,
            fontFamily: 'Inter Var',
            callback: (value, index, values) => {
              if (value === 0) {
                return 0
              }
              if (dataIndex === 0) {
                return getTime(value)
              }
              return getMonthDay(value)
            },
          },
          gridLines: {
            drawTicks: false,
            drawBorder: false,
            zeroLineWidth: 1,
            zeroLineColor: 'rgba(0, 0, 0, .08)',
            zeroLineBorderDash: [3, 3],
            borderDash: [3, 3],
            color: 'rgba(0, 0, 0, .08)',
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            display: true,
            padding: 10,
            fontFamily: 'Inter Var',
          },
          gridLines: {
            drawTicks: false,
            drawBorder: false,
            zeroLineWidth: 1,
            zeroLineColor: 'rgba(0, 0, 0, .08)',
            zeroLineBorderDash: [3, 3],
            borderDash: [3, 3],
            color: 'rgba(0, 0, 0, .08)',
          },
        },
      ],
    },
    tooltips: {
      callbacks: {
        title: function (tooltipItem, data) {
          const label = get(tooltipItem, '[0].label')
          if (!label || label === '0') {
            return ''
          }
          return getDefaultTypeDateAndTime(tooltipItem[0].label)
        },
      },
    },
  }
}

export const templatesTitle = {
  en: {
    'Header and one column': 'Header and one column',
    'One column': 'One column',
    'Left column': 'Left column',
    'Right column': 'Right column',
    'Header and right column': 'Header and right column',
    'Header and left column': 'Header and left column',
  },
  sv: {
    'Header and one column': 'Sidhuvud och en kolumn',
    'One column': 'En kolumn',
    'Left column': 'Kolumn till vänster',
    'Right column': 'Kolumn till höger',
    'Header and right column': 'Sidhuvud och kolumn till höger',
    'Header and left column': 'Sidhuvud och kolumn till vänster',
  },
}

export const DEFAULT_LANGUAGE = 'en'

export const NUMBER_OF_ITEMS_TO_SHOW_DELETE_APPROVE = 5

export const MAIL_TYPE_ADVANCED = 0
export const MAIL_TYPE_BLOCKEDITOR_OLD = 1
export const MAIL_TYPE_BLOCKEDITOR_NEW = 2

export const SUBJECT_MAX_LENGTH_TO_SHOW = 30
export const BULK_CONTACTS_COPY_IMPORT_CHUNK_MAX_SIZE = 5000

export const postDeliveryPrice = 50

export const REPORT_TYPE_AUTORESPONDER = 1
export const REPORT_TYPE_MAIL = 0

export const defaultFilters = {
  reports: {
    type: 0,
  },
  lists: {
    ordering: 'name',
  },
  sites: {
    ordering: 'url',
  },
  blockTemplates: {
    type: 'personal',
  },
  recipients: {
    ordering: '-opens,-clicks,contact[email]',
  },
  senders: {
    ordering: '-created',
  },
  smsSenders: {
    ordering: '-created',
  },
}

export const minAffiliateComissionPayout = 500

export const PARTNER_MATCH_URLS = ['/partner']

export const SENDER_STATUS_CONFIRMATION_NOT_NEEDED = 0
export const SENDER_STATUS_NOT_CONFIRMED = 1
export const SENDER_STATUS_CONFIRMED = 2

export const SMS_SENDER_STATUS_PENDING = 0
export const SMS_SENDER_STATUS_APPROVED = 1
export const SMS_SENDER_STATUS_DENIED = 2

export const AUTH_LAYOUT_TYPES = {
  SIGNIN: 'signin',
  SIGNUP: 'signup',
  FORGOT: 'forgot',
}

export const copyToClipboardDelay = 3

export const maxNotificationsCountToShow = 9

export const ATTRIBUTE_FILTER_PREFIX = 'a_'
export const ATTRIBUTE_FILTER_SEPARATOR = '__'
export const FIELD_FILTER_DEFAULT_OPTIONS = [
  { code: 'first_name', nameIntlId: 'contactattrs.static.attributes.firstname', type: 'text' },
  { code: 'last_name', nameIntlId: 'contactattrs.static.attributes.lastname', type: 'text' },
  { code: 'sms_number', nameIntlId: 'contactattrs.static.attributes.phoneNumber', type: 'text' },
]

export const CONTACT_IMPORT_MODE = {
  OVERWRITE: 0,
  LEAVE: 1,
  UPDATE: 2,
}

export const CONTACT_IMPORT_FORM_MODE = {
  LEAVE: 'leave',
  UPDATE: 'update',
}

//In hours
export const CONTACT_PENDING_TIME_LIMIT = 24
//10MB
export const CONTACT_IMPORT_FILE_UPLOAD_MAX = 10000000
