import BaseFieldHOC from '../BaseFieldHOC'

import CheckboxInput from '../inputs/CheckboxInput'
import RadioInput from '../inputs/RadioInput'
import TextInput from '../inputs/TextInput'
import TextareaInput from '../inputs/TextareaInput'

const CheckboxField = BaseFieldHOC(CheckboxInput)
const RadioField = BaseFieldHOC(RadioInput)
const TextField = BaseFieldHOC(TextInput)
const TextareaField = BaseFieldHOC(TextareaInput)

export { CheckboxField, TextField, TextareaField, RadioField }
