import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import get from 'lodash/get'
import Cookies from 'js-cookie'
import { useNavigate } from 'react-router-dom'

import { setData } from '~/common/utils/resource'
import { logout } from '~/common/session'
import { PageTitle } from '~/common/widgets'
import ModalTrigger from '~/modals/ModalTrigger'
import UsersTable from './component'
import AddUserModal from '~/components/Pages/Partner/IndexPage/modals/AddUserModal'
import { connectResource } from '~/common/utils/resource'
import { defaultPagination } from '~/common/utils/constants'
import { getCookie } from '~/lib/utils'
import { getCookieDomain } from '~/common/utils/helpers'

export const UsersTableContainer = (props) => {
  const navigate = useNavigate()

  const [isLoginInAsUser, setIsLoginInAsUser] = React.useState(false)

  const loginAsUser = (userId) => async (event) => {
    setIsLoginInAsUser(true)
    props.logout(true)
    await props.impersonate.create({}, { endpoint: `partner/users/${userId}/impersonate` })
    props.setData(
      {
        token: getCookie('jwt'),
        partnerToken: getCookie('partner_jwt'),
      },
      { resource: { namespace: 'session' } }
    )
    if (Cookies.get('jwt')) {
      await props.user.fetch()
      await props.profile.fetch()
      Cookies.set('logged_in', true, { domain: getCookieDomain() })
      navigate('/')
    }
  }

  return (
    <div className="container-fluid my-12">
      <div className="row mb-4">
        <div className="col-12 col-md-9 mb-4">
          <h1>
            <FormattedMessage id="home.welcomePartner" />
          </h1>
          <p className="lead mb-4">
            <FormattedMessage
              id="home.welcome.partner.description"
              values={{
                email: get(props.partnerProfile, 'data.email'),
              }}
            />
          </p>
          <PageTitle titles={['title.partner']} />
        </div>
        <div className="col-12 col-md-3 text-right">
          <ModalTrigger
            component={AddUserModal}
            onHide={props.users.fetch}
            loginAsUser={loginAsUser}
          >
            <button className="btn btn-success ml-auto">
              <FormattedMessage id="partner.button.createNewUser" />
            </button>
          </ModalTrigger>
        </div>
      </div>
      <UsersTable {...props} loginAsUser={loginAsUser} isLoginInAsUser={isLoginInAsUser} />
    </div>
  )
}

export default compose(
  connectResource({
    namespace: 'impersonate',
    endpoint: 'partner/users/:id/impersonate',
    idKey: 'id',
    async: true,
    prefetch: false,
  }),
  connectResource({
    namespace: 'user',
    endpoint: 'user',
    async: true,
    prefetch: false,
  }),
  connectResource({
    namespace: 'partnerProfile',
    endpoint: 'partner/profile',
    async: true,
    prefetch: false,
  }),
  connectResource({
    namespace: 'profile',
    endpoint: 'profile',
    async: true,
    prefetch: false,
  }),

  connectResource({
    namespace: 'session',
    endpoint: 'api-token-auth',
    async: true,
    prefetch: false,
  }),

  connect(null, { setData, logout }),
  connectResource({
    namespace: 'users',
    endpoint: 'partner/users/:id?',
    idKey: 'id',
    list: true,
    useRouter: true,
    filters: {
      paginate_by: defaultPagination,
    },
    async: true,
  })
)(UsersTableContainer)
