import moment from 'moment-timezone'

export const getCurrentTrial = (trial = {}) => {
  const currentTime = moment()
  if (!trial?.start_date || !trial?.end_date || trial?.upgrade_date) {
    return null
  }
  const isBetween = currentTime.isBetween(
    trial.start_date,
    moment(trial.end_date).endOf('day'),
    undefined,
    '[]'
  )
  if (isBetween) {
    return {
      ...trial,
      days_left: Math.abs(moment(trial.end_date).diff(currentTime, 'days')) + 1,
    }
  }
  return null
}
