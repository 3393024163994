import React from 'react'
import { Route, Outlet } from 'react-router-dom'

import { PageTitle } from '~/common/widgets'
import AsyncComponent from '~/components/AsyncComponent'

const UpgradeSubscriptionContainerComponent = () => import('./UpgradeSubscriptionContainer')

const UpgradeSubscriptionContainer = AsyncComponent({
  component: UpgradeSubscriptionContainerComponent,
})

const UpgradeInformationComponent = () => import('./UpgradeInformationContainer')
const UpgradeInformation = AsyncComponent({ component: UpgradeInformationComponent })

const UpgradeSummaryComponent = () => import('./UpgradeSummaryContainer')
const UpgradeSummary = AsyncComponent({ component: UpgradeSummaryComponent })

export default function UpgradePage() {
  return (
    <div className="gan-container my-8 w-full">
      <PageTitle titles={['title.upgradeAccount']} />
      <div>
        <Outlet />
      </div>
    </div>
  )
}

export const upgradePageRoutes = (
  <>
    <Route exact index element={<UpgradeSubscriptionContainer />} />
    <Route exact path="information" element={<UpgradeInformation />} />
    <Route exact path="summary" element={<UpgradeSummary />} />
  </>
)
