import { reducer as offline } from './reducers/offline'
import { reducer as vatId } from './reducers/vatId'
import { reducer as intercom } from './reducers/intercom'
import { reducer as importError } from './reducers/importError'

const reducers = {
  offline,
  vatId,
  intercom,
  importError,
}
const epics = {}

export { reducers, epics }
