import React from 'react'
import { Routes, Route, useLocation, Outlet } from 'react-router-dom'
import { connect } from 'react-redux'
import get from 'lodash/get'
import { compose } from 'redux'

import { getCookie } from '~/lib/utils'
import { RequireAuth } from '~/common/widgets'
import AsyncComponent from '~/components/AsyncComponent'

import { AUTH_LAYOUT_TYPES } from '~/common/utils/constants'
import { getPlanLevelWithTrial, isIE } from '~/common/utils/helpers'
import OutdatedBrowserModal from '~/modals/components/OutdatedBrowserModal'
import AppLayout from './AppLayout'
import AuthLayout from '../Pages/Auth/AuthLayout'
import { mailsRoutes } from '../Pages/Mails'
import { smsRoutes } from '../Pages/SMS'
import {
  attributesRoutes,
  contactsRoutes,
  formsRoutes,
  listsRoutes,
  pagesRoutes,
  subscriptionsRoutes,
  surveysRoutes,
} from '../Pages/Contacts'
import { upgradeRoutes } from '../Pages/Upgrade'
import { partnerRoutes } from '../Pages/Partner'
import Account, { accountRoutes } from '../Pages/Account'

const NotFound = AsyncComponent({
  component: () => import('../Pages/NotFound'),
})

const Home = AsyncComponent({
  component: () => import('../Pages/Home'),
})

const ConnectProviderCallbackContainer = AsyncComponent({
  component: () => import('../Pages/Contacts/ImportContacts/ConnectProvider/callback'),
})

const StartAutoresponder = AsyncComponent({
  component: () => import('../Pages/Mails/AutoresponderMail/AutoresponderMailContainer'),
})

const Reports = AsyncComponent({
  component: () => import('../Pages/Reports'),
})

const DetailedReport = AsyncComponent({
  component: () => import('../Pages/DetailedReport/DetailedReportContainer'),
})

const Contact = AsyncComponent({
  component: () => import('../Pages/Contact'),
})

const Privacy = AsyncComponent({
  component: () => import('../Pages/Privacy'),
})

const Terms = AsyncComponent({
  component: () => import('../Pages/Terms'),
})

const SigninContainer = AsyncComponent({
  component: () => import('../Pages/Auth/SigninContainer'),
})

const SignUp = AsyncComponent({
  component: () => import('../Pages/Auth/SignupContainer'),
})

const Forgot = AsyncComponent({
  component: () => import('../Pages/Auth/ForgotPasswordContainer'),
})

const Reset = AsyncComponent({
  component: () => import('../Pages/Auth/ResetPasswordContainer'),
})

const LoggedOut = AsyncComponent({
  component: () => import('../Pages/LoggedOut/LoggedOutContainer'),
})

const EmailConfirmation = AsyncComponent({
  component: () => import('../Pages/EmailConfirmation'),
})

const SenderConfirmation = AsyncComponent({
  component: () => import('../Pages/SenderConfirmation'),
})

const ChangeEmailConfirmation = AsyncComponent({
  component: () => import('../Pages/ChangeEmailConfirmation'),
})

const StripeAuthSubscription = AsyncComponent({
  component: () => import('../Pages/StripeAuthSubscription'),
})

const AddPopupForm = AsyncComponent({
  component: () => import('../Pages/Forms/AddPopupForm'),
})

const PopupSettings = AsyncComponent({
  component: () => import('../Pages/Forms/PopupSettings'),
})

const PopupTemplate = AsyncComponent({
  component: () => import('../Pages/Forms/PopupTemplate'),
})

const AddRegularForm = AsyncComponent({
  component: () => import('../Pages/Forms/AddRegularForm/AddRegularFormContainer'),
})

const NewForm = AsyncComponent({
  component: () => import('../Pages/Forms/NewForm'),
})

const PopupSnippet = AsyncComponent({
  component: () => import('../Pages/Forms/PopupSnippet'),
})

const AddPage = AsyncComponent({
  component: () => import('../Pages/LandingPages/AddPage'),
})

const PageSettings = AsyncComponent({
  component: () => import('../Pages/LandingPages/PageSettings'),
})

const PagePublish = AsyncComponent({
  component: () => import('../Pages/LandingPages/PagePublish'),
})

const PagePublishSuccess = AsyncComponent({
  component: () => import('../Pages/LandingPages/PagePublishSuccess'),
})

const PageTemplate = AsyncComponent({
  component: () => import('../Pages/LandingPages/PageTemplate'),
})

const AddSurvey = AsyncComponent({
  component: () => import('../Pages/Surveys/AddSurvey'),
})

const SurveyTemplate = AsyncComponent({
  component: () => import('../Pages/Surveys/SurveyTemplate'),
})

const SurveySettings = AsyncComponent({
  component: () => import('../Pages/Surveys/SurveySettings'),
})

const SurveyPublish = AsyncComponent({
  component: () => import('../Pages/Surveys/SurveyPublish'),
})

const SurveyPublishSuccess = AsyncComponent({
  component: () => import('../Pages/Surveys/SurveyPublishSuccess'),
})

const StandardOverviewPage = AsyncComponent({
  component: () => import('../Pages/StandardOverview/StandardOverviewPage'),
})

const SmsPage = AsyncComponent({
  component: () => import('../Pages/SMS'),
})

const matchRoute = (route) => {
  for (let i = 0, l = AUTH_LAYOUT_MATCH_URLS.length; i < l; i += 1) {
    if (route.startsWith(AUTH_LAYOUT_MATCH_URLS[i].routeStart)) {
      for (let j = 0, m = AUTH_LAYOUT_MATCH_URLS_SKIP.length; j < m; j += 1) {
        if (route.startsWith(AUTH_LAYOUT_MATCH_URLS_SKIP[j])) {
          continue
        }
        return AUTH_LAYOUT_MATCH_URLS[i]
      }
    }
  }

  return false
}

// Auth layout should be active on these URL paths
const AUTH_LAYOUT_MATCH_URLS = [
  {
    routeStart: '/signin',
    type: AUTH_LAYOUT_TYPES.SIGNIN,
  },
  {
    routeStart: '/signup',
    type: AUTH_LAYOUT_TYPES.SIGNUP,
  },
  {
    routeStart: '/forgot',
    type: AUTH_LAYOUT_TYPES.FORGOT,
  },
  {
    routeStart: '/reset_password',
    type: AUTH_LAYOUT_TYPES.FORGOT,
  },
]

const AUTH_LAYOUT_MATCH_URLS_SKIP = ['/signup/confirm']

const AppRoutes = ({
  token,
  partnerToken,
  code,
  location,
  hasLandingPagesEditAccess,
  hasSurveysEditAccess,
}) => {
  if (isIE()) {
    return (
      <AppLayout>
        <div
          style={{
            minHeigth: '70vh',
            height: '70vh',
          }}
        >
          <OutdatedBrowserModal />
        </div>
      </AppLayout>
    )
  }

  const matchedAuthRoute = matchRoute(location.pathname)
  if (matchedAuthRoute) {
    return (
      <Routes>
        <Route
          element={
            <AuthLayout code={code} type={matchedAuthRoute.type}>
              <Outlet />
            </AuthLayout>
          }
        >
          <Route
            exact
            path="/signin"
            element={
              <RequireAuth needRedirect={!token} redirectTo={partnerToken ? '/partner' : '/'}>
                <SigninContainer />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/signup"
            element={
              <RequireAuth needRedirect={!token} redirectTo="/">
                <SignUp />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/forgot"
            element={
              <RequireAuth needRedirect={!token} redirectTo="/">
                <Forgot />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/reset_password/:uidb64/:token"
            element={
              <RequireAuth needRedirect={!token} redirectTo="/">
                <Reset />
              </RequireAuth>
            }
          />
        </Route>
      </Routes>
    )
  }
  return (
    <Routes>
      <Route
        element={
          <AppLayout>
            <Outlet />
          </AppLayout>
        }
      >
        <Route exact path="/signup/confirm/:id" element={<EmailConfirmation />} />
        <Route exact path="/contact" element={<Contact />} />
        <Route exact path="/logged_out" element={<LoggedOut />} />
        <Route
          exact
          path="/"
          element={
            <RequireAuth redirectTo="/signin">
              <Home />
            </RequireAuth>
          }
        />
        <Route path="/email-confirmation" element={<RequireAuth redirectTo="/signin" outlet />}>
          <Route exact index element={<EmailConfirmation key="index" />} />
          <Route exact path=":id" element={<EmailConfirmation key="id" />} />
        </Route>
        <Route exact path="/sender-confirmation/:id/:signature" element={<SenderConfirmation />} />
        <Route
          path="/change-email-confirmation"
          element={<RequireAuth redirectTo="/signin" outlet />}
        >
          <Route exact index element={<ChangeEmailConfirmation key="index" />} />
          <Route exact path=":id" element={<ChangeEmailConfirmation key="id" />} />
        </Route>

        <Route path="/contacts" element={<RequireAuth redirectTo="/signin" outlet />}>
          {contactsRoutes}
        </Route>
        <Route
          path="/oauth/redirect/:slug/callback"
          element={
            <RequireAuth redirectTo="/signin">
              <ConnectProviderCallbackContainer />
            </RequireAuth>
          }
        />
        <Route path="/subscriptions" element={<RequireAuth redirectTo="/signin" outlet />}>
          {subscriptionsRoutes}
        </Route>
        <Route path="/attributes" element={<RequireAuth redirectTo="/signin" outlet />}>
          {attributesRoutes}
        </Route>
        <Route path="/lists" element={<RequireAuth redirectTo="/signin" outlet />}>
          {listsRoutes}
        </Route>
        <Route path="/forms" element={<RequireAuth redirectTo="/signin" outlet />}>
          {formsRoutes}
          <Route exact path="new" element={<NewForm />} />
          <Route exact path="popup/template" element={<PopupTemplate />} />
          <Route exact path="popup/:hash/settings" element={<PopupSettings />} />
          <Route exact path="popup/:hash" element={<AddPopupForm />} />
          <Route exact path="popup/:hash/snippet" element={<PopupSnippet />} />
          <Route exact path="regular/add" element={<AddRegularForm key="add" />} />
          <Route exact path="regular/edit/:id" element={<AddRegularForm key="edit" />} />
        </Route>
        <Route exact path="/pages" element={<RequireAuth redirectTo="/signin" outlet />}>
          {pagesRoutes}
        </Route>
        <Route
          path="/pages"
          element={
            <RequireAuth
              redirectTo="/signin"
              aclNeedRedirect={!hasLandingPagesEditAccess}
              aclRedirectTo="/pages"
              outlet
            />
          }
        >
          <Route exact path="template" element={<PageTemplate />} />
          <Route exact path="settings/:templateId" element={<PageSettings />} />
          <Route exact path=":hash/publish" element={<PagePublish />} />
          <Route exact path=":hash/publish/success" element={<PagePublishSuccess />} />
          <Route exact path=":hash" element={<AddPage />} />
        </Route>
        <Route exact path="/surveys" element={<RequireAuth redirectTo="/signin" outlet />}>
          {surveysRoutes}
        </Route>
        <Route
          path="/surveys"
          element={
            <RequireAuth
              redirectTo="/signin"
              aclNeedRedirect={!hasSurveysEditAccess}
              aclRedirectTo="/surveys"
              outlet
            />
          }
        >
          <Route exact path="template" element={<SurveyTemplate />} />
          <Route exact path="settings/:templateId" element={<SurveySettings />} />
          <Route exact path=":hash" element={<AddSurvey key="hash" />} />
          <Route exact path=":hash/publish" element={<SurveyPublish />} />
          <Route exact path=":hash/publish/success" element={<SurveyPublishSuccess />} />
          <Route exact path=":hash/responses" element={<AddSurvey key="hash-responses" />} />
          <Route exact path=":hash/responses/:id" element={<AddSurvey key="hash-responses-id" />} />
          <Route exact path=":hash/settings" element={<AddSurvey key="hash-settings" />} />
        </Route>
        <Route path="/mails" element={<RequireAuth redirectTo="/signin" outlet />}>
          {mailsRoutes}
        </Route>
        <Route
          path="/responders/start/:id"
          element={
            <RequireAuth redirectTo="/signin">
              <StartAutoresponder />
            </RequireAuth>
          }
        />
        <Route path="/reports" element={<RequireAuth redirectTo="/signin" outlet />}>
          <Route exact index element={<Reports />} />
          <Route exact path=":id" element={<DetailedReport />} />
        </Route>
        <Route
          path="/account"
          element={
            <RequireAuth redirectTo="/signin">
              <Account />
            </RequireAuth>
          }
        >
          {accountRoutes}
        </Route>

        <Route path="/sms" element={<RequireAuth redirectTo="/signin" outlet />}>
          {smsRoutes}
        </Route>

        <Route path="/upgrade" element={<RequireAuth redirectTo="/signin" outlet />}>
          {upgradeRoutes}
        </Route>
        <Route
          path="/renew-subscription/auth/:id"
          element={
            <RequireAuth needRedirect={token} redirectTo="/signin">
              <StripeAuthSubscription />
            </RequireAuth>
          }
        />
        <Route
          exact
          path="/partner"
          element={<RequireAuth needRedirect={partnerToken} redirectTo="/signin" outlet />}
        >
          {partnerRoutes}
        </Route>
        <Route
          path="/standard-overview"
          element={
            <RequireAuth redirectTo="/signin">
              <StandardOverviewPage />
            </RequireAuth>
          }
        />
        <Route exact path="/legal/privacy" element={<Privacy />} />
        <Route exact path="/legal/terms" element={<Terms />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  )
}

const ComposedContainer = compose(
  connect((state, props) => {
    const params = new URLSearchParams(props.location.search)
    return {
      token: get(state.resource, 'session.data.token') || getCookie('jwt'),
      hasLandingPagesEditAccess:
        typeof get(state.resource, 'profile.data.subscription') === 'undefined' ||
        getPlanLevelWithTrial(get(state.resource, 'profile')),
      hasSurveysEditAccess:
        typeof get(state.resource, 'profile.data.subscription') === 'undefined' ||
        getPlanLevelWithTrial(get(state.resource, 'profile')),
      partnerToken: get(state.resource, 'session.data.partnerToken') || getCookie('partner_jwt'),
      code: params.get('c'),
    }
  })
)(AppRoutes)

function ComposedContainerWrapper(props) {
  const location = useLocation()
  return <ComposedContainer {...props} location={location} />
}

export default ComposedContainerWrapper
