import React from 'react'
import { FormattedMessage } from 'react-intl'
import { FiAlertCircle } from 'react-icons/fi'

import { Modal, ModalHeader, ModalBody } from '~/components/Modal'
import { noop } from '~/lib/utils'

export const OutdatedBrowserModal = (props) => {
  const { onClose = noop, size = 'lg' } = props
  return (
    <Modal onClose={onClose} size={size}>
      <ModalHeader
        name={
          <div className="flex items-center">
            <FiAlertCircle size={20} strokeWidth={2.5} className="mr-1" />
            <FormattedMessage id="outdated.modal.title" />
          </div>
        }
        onClose={onClose}
      />
      <ModalBody>
        <p className="text-center">
          <FormattedMessage
            id="outdated.modal.body.line.1"
            values={{
              kind: (
                <strong>
                  <FormattedMessage id="common.outdated" />
                </strong>
              ),
            }}
          />
        </p>
        <p className="text-center">
          <FormattedMessage
            id="outdated.modal.body.line.2"
            values={{
              link: (
                <a href="http://browsehappy.com/" target="_blank" rel="noopener noreferrer">
                  <FormattedMessage id="outdated.update.link.text" />
                </a>
              ),
            }}
          />
        </p>
      </ModalBody>
    </Modal>
  )
}

export default OutdatedBrowserModal
