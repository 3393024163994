import React from 'react'
import get from 'lodash/get'
import { FormattedMessage } from 'react-intl'
import { FiX } from 'react-icons/fi'

import PaymentModal from '~/modals/components/PaymentModal'
import StripePaymentModal from '~/modals/components/StripePaymentModal'
import { getPaymentUrl, calculatePriceData } from '~/common/utils/helpers'
import { PAYMENT_PROVIDER_STRIPE } from '~/common/utils/constants'
import { Modal, ModalBody } from '~/components/Modal'
import { OrderInformation } from '~/components/OrderInformation'

export default function ReactivateAccount({
  article,
  address,
  billing,
  onSubmit,
  showPaymentModal,
  upgradeAccount,
  onClose,
  onPaymentModalClose,
  profile,
  discount,
  invoice,
  isLoading,
  upgradeConditions,
}) {
  if (!profile.data || !address.data) {
    return null
  }

  const isOnHold = get(profile, 'data.subscription.on_hold_since')
  const isPaused = get(profile, 'data.subscription.is_paused')

  const priceCalculation = calculatePriceData({
    article,
    profile,
    address,
    billing,
    discount,
    upgradeConditions,
  })

  const isStripeProvider = get(profile, 'data.payment_provider') === PAYMENT_PROVIDER_STRIPE

  const hasEpayCard = typeof profile?.data?.active_card === 'string'

  const orderId = get(upgradeAccount, 'data.order_id') || get(invoice, 'order_id')
  return (
    <>
      {showPaymentModal && (isStripeProvider || (!isStripeProvider && !hasEpayCard)) && (
        <StripePaymentModal
          key={1}
          onClose={onPaymentModalClose}
          id={get(upgradeAccount, 'data.invoice_id') || get(invoice, 'id')}
          test={2}
        />
      )}
      {showPaymentModal && !isStripeProvider && hasEpayCard && !!orderId && (
        <PaymentModal
          key={2}
          onClose={onPaymentModalClose}
          filters={{
            order_id: orderId,
            accepturl: getPaymentUrl(`upgrade/pay-by-card/accept/`),
          }}
          id={get(upgradeAccount, 'data.invoice_id') || get(invoice, 'id')}
        />
      )}
      {!showPaymentModal && !isLoading && article && (
        <Modal onClose={onClose} backdrop="static" key={3}>
          <ModalBody className="p-5">
            <button
              type="button"
              onClick={onClose}
              className="btn-none stripe-modal--close-button pointer text-gray-600"
            >
              <FiX strokeWidth={2.5} />
            </button>
            <h2 className="card-title">
              {!isOnHold && !isPaused && <FormattedMessage id="pause.pausePageTitle" />}
              {(isOnHold || isPaused) && <FormattedMessage id="pause.activatePageTitle" />}
            </h2>
            {isOnHold && isPaused && (
              <p className="msg__activate-description">
                <FormattedMessage id="activate.description" />
              </p>
            )}
            <OrderInformation
              onSubmit={onSubmit}
              priceCalculation={priceCalculation}
              article={article}
              upgradeConditions={upgradeConditions}
              profile={profile}
              isLoading={get(upgradeAccount, 'isLoading')}
              withButtons={false}
            />
            <div className="mt-4 text-right">
              <button
                onClick={onClose}
                className="btn btn-link font-weight-semi-bold mr-2"
                disabled={get(upgradeAccount, 'isLoading')}
              >
                <FormattedMessage id="common.back" />
              </button>
              <button
                className="btn btn-success ml-auto"
                onClick={onSubmit}
                disabled={get(upgradeAccount, 'isLoading')}
              >
                <FormattedMessage id="card.payCard" />
              </button>
            </div>
          </ModalBody>
        </Modal>
      )}
    </>
  )
}
