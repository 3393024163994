import React from 'react'
import FormValidationError from '~/components/FormValidationError'
import get from 'lodash/get'

export const BaseFieldLayout = (props) => {
  const {
    icon,
    label,
    prefix,
    required,
    inputComponent: InputComponent,
    appendAddonComponent: AppendAddonComponent,
    appendAddon,
    helpText,
    labelClassName,
    ignoreTouched,
    ...restProps
  } = props

  const Component = (
    <InputComponent {...restProps} {...{ ...props.input, required: false, ignoreTouched }} />
  )

  let AppendAddon
  if (AppendAddonComponent) {
    AppendAddon = <AppendAddonComponent {...props} />
  }
  return (
    <>
      {icon}
      {label && (
        <label htmlFor={props.name} className={labelClassName}>
          {label}
          {required && <span className="control-asterisk">*</span>}
        </label>
      )}
      <div className="control-field">
        <div className="control-element">
          {prefix && <div className="control-prefix">{prefix}</div>}
          {appendAddon && (
            <div className="input-group">
              {Component}
              {appendAddon}
            </div>
          )}
          {AppendAddonComponent && (
            <div className="input-group">
              {Component}
              {AppendAddon}
            </div>
          )}
          {!AppendAddonComponent && !appendAddon && Component}
          {helpText && <small className="form-text text-muted mt-2">{helpText}</small>}

          <FormValidationError
            validationMessages={
              (ignoreTouched && props.meta.error) ||
              (props.meta.touched && props.meta.error) ||
              get(props.customMeta, 'error')
            }
            className="visible"
          />
        </div>
      </div>
    </>
  )
}

export default BaseFieldLayout
