import React, { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { Link } from 'react-router-dom'
import { List } from 'phosphor-react'
import { FormattedMessage } from 'react-intl'
import { classNames } from '~/common/utils/classNames'

function SidebarNavUnAuth() {
  return (
    <Menu as="div" className="relative w-16 text-left">
      <Menu.Button className="rounded-lg border border-transparent p-1.5 text-left text-white hover:bg-white hover:bg-opacity-10 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-offset-2 focus:ring-offset-blue-900">
        <List size={20} className="text-white" />
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-1 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute left-0 mt-2 w-56 origin-top-left divide-y divide-gray-100 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="p-2">
            <Menu.Item>
              {({ active }) => (
                <Link
                  to="/signin"
                  className={`${classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'flex items-center gap-2 rounded-md  py-1.5 px-2 hover:bg-gray-100'
                  )}`}
                >
                  <FormattedMessage id="auth.signin" />
                </Link>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <Link
                  to="/signup"
                  className={`${classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'flex items-center gap-2 rounded-md  py-1.5 px-2 hover:bg-gray-100'
                  )}`}
                >
                  <FormattedMessage id="auth.signup" />
                </Link>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

export default SidebarNavUnAuth
