export const getVariant = (variant = 'secondary') => {
  if (variant === 'secondary') return 'bg-white border-gray-300 shadow-sm hover:bg-gray-50'
  if (variant === 'primary')
    return 'bg-emerald-600 border-emerald-700 text-white shadow hover:bg-emerald-700 hover:border-emerald-800'
  if (variant === 'danger')
    return 'bg-red-500 border-red-600 text-white shadow hover:bg-red-600 hover:border-red-700'
}

export const getSize = (size = 'md') => {
  if (size === 'sm') return 'px-2 py-1 rounded'
  if (size === 'md') return 'px-3 py-1.5 rounded-md'
  if (size === 'lg') return 'px-5 py-2 rounded-lg'
}
