import React from 'react'

export default function RadioInput({
  labelElement,
  name,
  className,
  inputClassName,
  checked,
  required,
  disabled,
  onChange,
  value,
  defaultValue,
}) {
  const inputId = `${name}-${defaultValue}`

  return (
    <label htmlFor={inputId} className={className} data-test="radio-input">
      <input
        id={inputId}
        name={name}
        type="radio"
        value={defaultValue}
        className={inputClassName}
        onChange={(e) => {
          onChange(e.target.value)
        }}
        checked={checked || value === defaultValue}
        required={required}
        disabled={disabled}
      />
      {labelElement}
    </label>
  )
}
