import React from 'react'
import { FormattedMessage } from 'react-intl'

import starweblogga from '~/img/starweblogga.png'
import ehandelLogo from '~/img/ehandel-logo.png'

// https://wikinggruppen.se/innehall/uploads/2015/02/logo-wikinggruppen.png
import logoWikinggruppen from '~/img/logo-wikinggruppen.png'

// https://cdn.getanewsletter.com/partner-logos/payson_logo.png
import paysonLogo from '~/img/payson_logo_1.png'

// https://media.getanewsletter.com/07340123-1f94-48ee-9ecd-4fa9f2633cf5.png
import nyforetagarcentrumLogo from '~/img/07340123-1f94-48ee-9ecd-4fa9f2633cf5.png'

import signupRestaurantsLogo from '~/img/signup-restaurants.png'
import signupEventsLogo from '~/img/signup-events.png'
import signupChurchesLogo from '~/img/signup-churches.png'
import theparkLogo from '~/img/thepark-campaign.png'
import spreadLoveLogo from '~/img/spread-love.svg'

export const signupCodes = [
  {
    code: 'wYjR3kyg', // Special signup code for Starweb
    isCustom: true,
    logo: {
      url: starweblogga,
      title: <FormattedMessage id="auth.logo.title.starweb" />,
      isDescriptionUp: false,
    },
    form: {
      title: <FormattedMessage id="auth.form.title.common" />,
      description: <FormattedMessage id="auth.form.description.starweb" />,
    },
  },
  {
    code: 'kUdW6luc', // Special signup code for Wikinggruppen - 1000 mails
    isCustom: true,
    logo: {
      url: logoWikinggruppen,
      title: <FormattedMessage id="auth.logo.title.wikinggruppen" values={{ mails: 1000 }} />,
      isDescriptionUp: false,
    },
    form: {
      title: <FormattedMessage id="auth.form.title.common" />,
      description: (
        <FormattedMessage id="auth.form.description.wikinggruppen" values={{ mails: 1000 }} />
      ),
    },
  },
  {
    code: 'rOnL9wyt', // Special signup code for Wikinggruppen - 3000 mails
    isCustom: true,
    logo: {
      url: logoWikinggruppen,
      title: <FormattedMessage id="auth.logo.title.wikinggruppen" values={{ mails: 3000 }} />,
      isDescriptionUp: false,
    },
    form: {
      title: <FormattedMessage id="auth.form.title.common" />,
      description: (
        <FormattedMessage id="auth.form.description.wikinggruppen" values={{ mails: 3000 }} />
      ),
    },
  },
  {
    code: 'a23g2zH6', // Special signup code for Ehandel campaign - 2000 contacts, 10 000 mails
    isCustom: true,
    logo: {
      url: ehandelLogo,
      title: <FormattedMessage id="auth.logo.title.ehandel" />,
      isDescriptionUp: false,
    },
    form: {
      title: <FormattedMessage id="auth.form.title.common" />,
      description: <FormattedMessage id="auth.form.description.ehandel" />,
    },
  },
  {
    code: 'u95s2LP6', // Special signup code for Payson: 2000 contacts
    isCustom: true,
    logo: {
      url: paysonLogo,
      title: <FormattedMessage id="auth.logo.title.payson" />,
      isDescriptionUp: false,
    },
    form: {
      title: <FormattedMessage id="auth.form.title.common" />,
      description: <FormattedMessage id="auth.form.description.payson" />,
    },
  },
  {
    code: 'p12f7Pc9', // Special signup code for Nyföretagarcentrum: 500 contacts, 2500 mails
    isCustom: true,
    logo: {
      url: nyforetagarcentrumLogo,
      title: <FormattedMessage id="auth.logo.title.nyforetagar" />,
      isDescriptionUp: true,
    },
    form: {
      title: <FormattedMessage id="auth.form.title.common" />,
      description: <FormattedMessage id="auth.form.description.nyforetagar" />,
    },
  },
  {
    code: 'tPy37ktV', // Special signup code for mexican campaign - normal sign up
    isCustom: false,
    logo: {},
    form: {},
  },
  {
    code: 'hEc11PvX', // Special signup code for Corona – Bars/Restaurants
    isCustom: true,
    logo: {
      url: signupRestaurantsLogo,
      title: <FormattedMessage id="auth.logo.title.restaurants" />,
      subtitle: <FormattedMessage id="auth.logo.subtitle.restaurants" />,
      isDescriptionUp: true,
    },
    form: {
      title: <FormattedMessage id="auth.form.title.coronaRestaurants" />,
      description: <FormattedMessage id="auth.form.description.coronaRestaurants" />,
    },
  },
  {
    code: 'wQm98CcP', // Special signup code for Corona – Event companies
    isCustom: true,
    logo: {
      url: signupEventsLogo,
      title: <FormattedMessage id="auth.logo.title.events" />,
      subtitle: <FormattedMessage id="auth.logo.subtitle.events" />,
      isDescriptionUp: true,
    },
    form: {
      title: <FormattedMessage id="auth.form.title.coronaEvents" />,
      description: <FormattedMessage id="auth.form.description.coronaEvents" />,
    },
  },
  {
    code: 'iUk40BlO', // Special signup code for Corona – Churches
    isCustom: true,
    logo: {
      url: signupChurchesLogo,
      title: <FormattedMessage id="auth.logo.title.churches" />,
      subtitle: <FormattedMessage id="auth.logo.subtitle.churches" />,
      isDescriptionUp: true,
    },
    form: {
      title: <FormattedMessage id="auth.form.title.coronaChurches" />,
      description: <FormattedMessage id="auth.form.description.coronaChurches" />,
    },
  },
  {
    code: 'rRw22CN4', // Special signup code for Corona – Schools
    isCustom: true,
    logo: {
      url: spreadLoveLogo,
    },
    form: {
      title: <FormattedMessage id="auth.form.title.coronaSchools" />,
      description: <FormattedMessage id="auth.form.description.coronaSchools" />,
    },
  },
  {
    code: 'aEp91BN1', // Special signup code for The Park campaign may 2021
    isCustom: true,
    logo: {
      url: theparkLogo,
      title: <FormattedMessage id="auth.logo.title.thepark" />,
      subtitle: <FormattedMessage id="auth.logo.subtitle.thepark" />,
      isDescriptionUp: true,
    },
    form: {
      title: <FormattedMessage id="auth.form.title.thepark" />,
      description: <FormattedMessage id="auth.form.description.thepark" />,
    },
  },
]

export const signupCodesHash = signupCodes.reduce(
  (result, current) => ({ ...result, [current.code]: current }),
  {}
)
