import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { Popover, Transition } from '@headlessui/react'
import get from 'lodash/get'

import { PLANS_LEVELS, TRIAL_PRIOD_STATUS } from '~/common/utils/constants/providers'
import RequireUpgrade from '~/common/widgets/RequireUpgrade'
import ModalStartedTrial from '~/common/widgets/RequireUpgrade/ModalStartedTrial'
import { getPlanLevelWithTrial, getTrialPeriodStatus } from '~/common/utils/helpers'
import { compose } from 'redux'
import { connect } from 'react-redux'

export function SuggestPlanPopover({ className, planLevel, trialStatus, description, children }) {
  const [show, setShow] = React.useState(false)
  const [showModalStartedTrial, setShowModalStartedTrial] = React.useState(false)

  if (planLevel >= PLANS_LEVELS.STANDARD && trialStatus === TRIAL_PRIOD_STATUS.NOT_USED) {
    return children
  }

  return (
    <>
      {planLevel === PLANS_LEVELS.START ? (
        <Popover
          as="div"
          className={`relative ${className}`}
          onMouseOver={() => setShow(true)}
          onMouseLeave={() => setShow(false)}
        >
          <Transition
            show={show}
            as={React.Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-1 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Popover.Panel className="absolute top-0 left-1/2 z-50 origin-top-right -translate-y-full -translate-x-1/2 bg-transparent p-0 pb-3 focus:outline-none">
              <div className="relative rounded-lg bg-white px-5 py-4 shadow-lg ring-1 ring-black ring-opacity-5">
                <div className="w-72">
                  <p className="font-weight-bold mb-2 text-sm">
                    <FormattedMessage id="common.availableOnStandard" />
                  </p>
                  <p className="text-sm text-gray-600">
                    <FormattedMessage
                      id={description || 'common.contactSegmentation.availableOnStandard'}
                    />
                  </p>
                  <RequireUpgrade
                    hideStartTrialModal
                    trialLabel={<FormattedMessage id="common.startFreeTrial" />}
                    onStartedTrialSuccess={() => setShowModalStartedTrial(true)}
                  >
                    {trialStatus !== TRIAL_PRIOD_STATUS.USING ? (
                      <Link
                        to="/upgrade?planLevel=20"
                        className="btn btn-success flex-1 md:flex-initial"
                      >
                        <FormattedMessage id="common.upgradeToStandard" />
                      </Link>
                    ) : null}
                  </RequireUpgrade>
                </div>
                <div className="absolute left-1/2 -bottom-1 h-2.5 w-2.5 -translate-x-1/2 rotate-45 bg-white shadow-xl" />
              </div>
            </Popover.Panel>
          </Transition>
          {children}
        </Popover>
      ) : (
        children
      )}
      <ModalStartedTrial
        isOpen={showModalStartedTrial}
        onClose={() => setShowModalStartedTrial(false)}
      />
    </>
  )
}

export default compose(
  connect(() => (state) => ({
    trialStatus: getTrialPeriodStatus(get(state, 'resource.profile')),
    planLevel: getPlanLevelWithTrial(get(state, 'resource.profile')),
  }))
)(SuggestPlanPopover)
