import React, { Component } from 'react'
import { FiMoreHorizontal } from 'react-icons/fi'
import noop from 'lodash/noop'

export default class Dropdown extends Component {
  state = {
    visible: false,
  }

  static getDerivedStateFromProps(props, state) {
    if (typeof props.handleToggleDropdown !== 'undefined') {
      const newState = {}

      if (state.visible !== props.visible) {
        newState.visible = props.visible
      }
      if (Object.keys(newState).length) {
        return {
          ...state,
          ...newState,
        }
      }
    }
    return null
  }

  toggleDropdown = () => {
    this.setState({ visible: !this.state.visible }, () => {
      if (typeof this.props.handleToggleDropdown !== 'undefined') {
        this.props.handleToggleDropdown(this.state.visible)
      }
    })
  }

  render() {
    const {
      buttonClasses = 'btn badge badge-secondary border-0',
      dropdownMenuClasses = '',
      buttonContent = '',
      disableButtonIcon,
      closeOnClickMenu,
    } = this.props
    return (
      <div className="dropdown">
        {this.state.visible && <div className="dropdown-layout" onClick={this.toggleDropdown} />}
        <button className={buttonClasses} type="button" onClick={this.toggleDropdown}>
          {!disableButtonIcon && <FiMoreHorizontal />}
          {buttonContent}
        </button>
        <div
          className={`dropdown-menu ${this.state.visible && 'show'} ${dropdownMenuClasses}`}
          onClick={closeOnClickMenu ? this.toggleDropdown : noop}
        >
          {this.props.children}
        </div>
      </div>
    )
  }
}

Dropdown.defaultProps = {
  closeOnClickMenu: true,
}
