import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import Cookies from 'js-cookie'
import { Question } from 'phosphor-react'

import { toggleShow, addOnShowHandler, addOnHideHandler } from '~/common/utils/intercom'
import { show, hide } from '~/store/actions/intercom'

export class Feedback extends React.Component {
  toggleModal = () => {
    toggleShow(!this.props.isOpen)
  }

  componentDidMount() {
    addOnHideHandler(this.handleIntercomOnHide)
    addOnShowHandler(this.handleIntercomOnShow)
  }

  handleIntercomOnHide = () => {
    this.props.intercomHide()
  }

  handleIntercomOnShow = () => {
    this.props.intercomShow()
  }

  render() {
    const isLoggedFromAdmin = Cookies.get('gan-logged_in')
    if (isLoggedFromAdmin || !window.INTERCOME_APP_ID || !this.props.token) {
      return null
    }
    return (
      <button
        className="relative hidden rounded-lg border border-transparent p-1.5 hover:bg-white hover:bg-opacity-10 lg:block"
        onClick={this.toggleModal}
        data-test="btn-feedback"
      >
        <Question className="block text-blue-100" weight="duotone" size={20} />
      </button>
    )
  }
}

const mapDispatchToProps = (dispatch, props) => ({
  intercomShow: () => dispatch(show()),
  intercomHide: () => dispatch(hide()),
})

export default compose(
  connect(
    (state) => ({
      token: state?.resource?.session?.data?.token,
      isOpen: state?.intercom?.isOpen,
    }),
    mapDispatchToProps
  )
)(Feedback)
