import React from 'react'
import CustomValidationError from '~/components/CustomValidationError'

const ignoreKeys = ['email_item_price']

export const ErrorsSubmit = ({ errors = {} }) => {
  const filteredErrors = Object.keys(errors)
    .filter((key) => !ignoreKeys.includes(key))
    .filter((key) => !!errors[key])
  if (!filteredErrors.length) {
    return null
  }
  return (
    <div className="errors-submit flex">
      <div
        className="alert alert-danger w-full border-red-400 bg-red-100 pl-3 text-red-600"
        role="alert"
      >
        <ul className="mb-0 p-0 pl-3">
          {filteredErrors.map((key, index, arr) => (
            <li key={index} className={arr.length - 1 === index ? 'mb-0' : 'mb-0'}>
              <CustomValidationError messages={errors[key]} />
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}
