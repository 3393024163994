import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link, useLocation } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import { compose } from 'redux'
import get from 'lodash/get'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { FiAlertTriangle } from 'react-icons/fi'
import moment from 'moment-timezone'

import { connectResource } from '~/common/utils/resource'
import { getPaymentUrl, isRouteMatch } from '~/common/utils/helpers'
import { PAYMENT_PROVIDER_STRIPE } from '~/common/utils/constants'
import { isTabVisible } from '~/common/utils/visibility'
import ReactivateAccountContainer from '~/components/Pages/PauseAccount/ReactivateAccountContainer'
import PaymentModal from '~/modals/components/PaymentModal'
import StripePaymentModal from '~/modals/components/StripePaymentModal'
import { PARTNER_MATCH_URLS } from '~/common/utils/constants'

import AppHeader from '~/components/AppHeader'
import AppSidebar from '~/components/AppSidebar'

export class AppLayout extends Component {
  static propTypes = {
    user: PropTypes.shape({ data: PropTypes.shape({ id: PropTypes.number }) }),
  }

  static defaultProps = {
    user: { data: { id: 0 } },
  }

  interval = null

  _isMounted = false

  state = {
    showReactivateModal: false,
    showPaymentModal: false,
    invoice: null,
    invoiceDueDays: null,
  }

  togglePaymentModal = (e) => {
    if (e) {
      e.preventDefault()
    }
    this.setState((prevState) => ({
      showPaymentModal: !prevState.showPaymentModal,
    }))
  }

  toggleReactivateModal = (e) => {
    if (e) {
      e.preventDefault()
    }
    this.setState((prevState) => ({
      showReactivateModal: !prevState.showReactivateModal,
    }))
  }

  componentDidMount() {
    this._isMounted = true
    this.fetchProfile()
    if (
      (get(this.props, 'profile.data.email_confirmation_needed') ||
        get(this.props, 'profile.data.unpaid_invoice')) &&
      !this.interval
    ) {
      this.interval = setInterval(
        this.fetchProfile,
        import.meta.env.REACT_APP_PROFILE_UPDATE_INTERVAL
      )
    }
  }

  componentDidUpdate(prevProps) {
    if (!get(prevProps, 'profile.data') && !!get(this.props, 'profile.data')) {
      this.fetchProfile()
    }
    if (
      get(this.props, 'profile.data.email_confirmation_needed') === false &&
      get(this.props, 'profile.data.unpaid_invoice') === false &&
      this.interval
    ) {
      if (this.interval) {
        clearInterval(this.interval)
        this.interval = null
      }
    }
    if (
      (get(this.props, 'profile.data.email_confirmation_needed') ||
        get(this.props, 'profile.data.unpaid_invoice')) &&
      !this.interval
    ) {
      this.fetchProfile()
      this.interval = setInterval(
        this.fetchProfile,
        import.meta.env.REACT_APP_PROFILE_UPDATE_INTERVAL
      )
    }
  }

  componentWillUnmount() {
    this._isMounted = false
    if (this.interval) {
      clearInterval(this.interval)
      this.interval = null
    }
  }

  fetchProfile = async () => {
    const isPartner = !!this.props.partnerSession && !this.props.userSession
    if (
      (get(this.props, 'profile.data.email_confirmation_needed') ||
        get(this.props, 'profile.data.unpaid_invoice')) &&
      isTabVisible() &&
      !isPartner
    ) {
      const profile = await this.props.profile.fetch()
      if (get(profile, 'unpaid_invoice')) {
        const invoiceResponse = await this.props.unpaidInvoice.filter()
        if (this._isMounted) {
          const invoice = get(invoiceResponse, 'results[0]')
          this.setState({
            invoice,
            invoiceDueDays: moment(invoice?.due_date).diff(moment(), 'days'),
          })
        }
      } else {
        if (this._isMounted) {
          this.setState({
            invoiceDueDays: null,
          })
        }
      }
    }
  }

  render() {
    const { invoiceDueDays } = this.state
    const profileData = this.props?.profile?.data || {}

    const article = get(profileData, 'subscription.recommended_article')

    const isStripeProvider = get(profileData, 'payment_provider') === PAYMENT_PROVIDER_STRIPE

    const isPartnerSection = isRouteMatch(this.props.location?.pathname, PARTNER_MATCH_URLS)

    const hasNoUserAuth = !this.props.userSession && !this.props.user?.data?.id

    const isLoggedInAsUser =
      !!this.props.partnerSession && !!this.props.userSession && !!this.props.user?.data?.email

    return (
      <>
        <AppHeader />
        <div className="lg:flex">
          {hasNoUserAuth ? null : <AppSidebar isLoggedInAsUser={isLoggedInAsUser} />}
          <div className="lg:flex lg:flex-1 lg:flex-col">
            {get(profileData, 'unpaid_invoice') &&
              !get(profileData, 'subscription.is_paused') &&
              !isPartnerSection &&
              invoiceDueDays > 0 &&
              invoiceDueDays < 7 && (
                <div className="header-info unpaid-invoice-warning header-info--static flex items-center justify-center bg-yellow-100 text-yellow-600">
                  <FiAlertTriangle className="mr-2" size="20" />
                  <FormattedMessage id="invoice.unpaid.warning.header" />{' '}
                  <HashLink
                    className="link-underline ml-1 text-yellow-600 hover:text-yellow-500"
                    to={{
                      pathname: '/account',
                      hash: '#invoices',
                    }}
                  >
                    <FormattedMessage id="invoice.unpaid.warning.link.text" />
                  </HashLink>
                </div>
              )}
            {get(profileData, 'unpaid_invoice') &&
              !get(profileData, 'subscription.is_paused') &&
              !isPartnerSection &&
              invoiceDueDays < 0 && (
                <div className="header-info unpaid-invoice-warning-expired header-info--static flex items-center justify-center bg-red-100 text-red-600">
                  <FiAlertTriangle className="mr-2" size="20" />
                  <FormattedMessage id="invoice.unpaid.warning.header.expired" />{' '}
                  <HashLink
                    className="link-underline ml-1 text-red-600 hover:text-red-500"
                    to={{
                      pathname: '/account',
                      hash: '#invoices',
                    }}
                  >
                    <FormattedMessage id="invoice.unpaid.warning.link.text.expired" />
                  </HashLink>
                </div>
              )}
            {get(profileData, 'unpaid_invoice') &&
              get(profileData, 'subscription.is_paused') &&
              !isPartnerSection &&
              invoiceDueDays > 0 &&
              invoiceDueDays < 7 && (
                <div className="header-info unpaid-paused-invoice-warning header-info--static flex items-center justify-center bg-yellow-100 text-yellow-600">
                  <FiAlertTriangle className="mr-2" size="20" />
                  <FormattedMessage id="invoice.unpaid.paused.warning.header" />{' '}
                  <HashLink
                    className="link-underline ml-1 text-yellow-600 hover:text-yellow-500"
                    to={{
                      pathname: '/account',
                      hash: '#invoices',
                    }}
                  >
                    <FormattedMessage id="invoice.unpaid.paused.warning.link.text" />
                  </HashLink>
                </div>
              )}
            {get(profileData, 'unpaid_invoice') &&
              get(profileData, 'subscription.is_paused') &&
              !isPartnerSection &&
              invoiceDueDays < 0 && (
                <div className="header-info unpaid-paused-invoice-warning-expired header-info--static flex items-center justify-center bg-red-100 text-red-600">
                  <FiAlertTriangle className="mr-2" size="20" />
                  <FormattedMessage id="invoice.unpaid.paused.warning.header.expired" />{' '}
                  <HashLink
                    className="link-underline ml-1 text-red-600 hover:text-red-500"
                    to={{
                      pathname: '/account',
                      hash: '#invoices',
                    }}
                  >
                    <FormattedMessage id="invoice.unpaid.paused.warning.link.text.expired" />
                  </HashLink>
                </div>
              )}
            {get(profileData, 'subscription.is_paused') &&
              !get(profileData, 'unpaid_invoice') &&
              !isPartnerSection && (
                <div className="header-info pause-warning header-info--static flex items-center justify-center bg-yellow-100 text-yellow-600">
                  <FiAlertTriangle className="mr-2" size="20" />
                  <FormattedMessage id="pausedaccount.warning" />{' '}
                  <a
                    className="link-underline ml-1 text-yellow-600 hover:text-yellow-500"
                    href="/account"
                    onClick={this.toggleReactivateModal}
                  >
                    <FormattedMessage id="pausedaccount.activate" />
                  </a>
                </div>
              )}
            {profileData.email_confirmation_needed && !isPartnerSection && (
              <div className="header-info email-confirm-warning header-info--static flex flex-wrap items-center justify-center bg-red-100 text-red-600">
                <FiAlertTriangle className="mr-2" size="20" />
                <FormattedMessage id="emailConfirmation.message" />:{' '}
                <Link
                  className="link-underline ml-1 text-red-600 hover:text-red-500"
                  to="/email-confirmation"
                >
                  <FormattedMessage id="emailConfirmation.title" />
                </Link>
              </div>
            )}
            {this.state.showReactivateModal && !isPartnerSection && (
              <ReactivateAccountContainer article={article} onClose={this.toggleReactivateModal} />
            )}
            {this.state.showPaymentModal &&
              !isStripeProvider &&
              !isPartnerSection &&
              !!get(this.state.invoice, 'order_id') && (
                <PaymentModal
                  onClose={this.togglePaymentModal}
                  filters={{
                    order_id: get(this.state.invoice, 'order_id'),
                    accepturl: getPaymentUrl(`upgrade/pay-by-card/accept/`),
                  }}
                  id={get(this.state.invoice, 'id')}
                />
              )}
            {this.state.showPaymentModal && isStripeProvider && !isPartnerSection && (
              <StripePaymentModal
                onClose={this.togglePaymentModal}
                id={get(this.state.invoice, 'id')}
                test={1}
              />
            )}
            <div className="lg:flex lg:flex-1 lg:flex-col">{this.props.children}</div>
          </div>
        </div>
      </>
    )
  }
}

const ComposedContainer = compose(
  connect((state) => ({
    userSession: state?.resource?.session?.data?.token,
    partnerSession: state?.resource?.session?.data?.partnerToken,
  })),
  connectResource({
    namespace: 'user',
    endpoint: 'user',
    async: true,
    prefetch: false,
  }),
  connectResource({
    namespace: 'profile',
    endpoint: 'profile',
    async: true,
    prefetch: false,
  }),
  connectResource({
    namespace: 'unpaidInvoice',
    endpoint: 'invoices',
    idKey: 'id',
    list: true,
    prefetch: false,
    filters: {
      paid_time__isnull: true,
      paginate_by: 1,
      ordering: '-id',
    },
  })
)(AppLayout)

function ComposedContainerWrapper(props) {
  const location = useLocation()
  return <ComposedContainer {...props} location={location} />
}

export default ComposedContainerWrapper
