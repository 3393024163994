import React from 'react'
import get from 'lodash/get'
import { compose } from 'redux'
import { connect } from 'react-redux'

import OfflineModal from './OfflineModal'
import { getInit } from '~/services/init'
import { tick } from '~/common/utils/helpers'

class OfflineLayout extends React.Component {
  state = {
    isLoading: false,
  }

  checkOnline = async () => {
    if (this.state.isLoading) {
      return
    }
    this.setState({ isLoading: true })
    try {
      await tick(1000)
      const response = await getInit()
      if (response && response.contacts) {
        window.location.reload()
      }
    } finally {
      this.setState({ isLoading: false })
    }
  }

  render() {
    return (
      <>
        {this.props.children}
        {this.props.isOffline && (
          <OfflineModal checkOnline={this.checkOnline} isLoading={this.state.isLoading} />
        )}
      </>
    )
  }
}

export default compose(
  connect((state) => ({
    isOffline: get(state, 'offline.isOffline'),
  }))
)(OfflineLayout)
