import React from 'react'

import Image from '~/components/Image'

export default function EmptyList({
  children,
  placeholderImage,
  placeholderImageAlt,
  placeholderTitle,
  placeholderDescription,
  placeholderNote,
  placeholderActions: PlaceholderActions,
}) {
  return (
    <div className="row mb-3">
      <div className="col">
        <div className="card border-dashed py-5">
          <div className="row py-5">
            <div className="col-12 col-md-8 offset-0 offset-md-2 space-y-4 text-center">
              {placeholderImage && (
                <Image width="130px" alt={placeholderImageAlt} image={placeholderImage} />
                // <img width="130px" alt={placeholderImageAlt} src={placeholderImage} />
              )}
              {placeholderNote && <p>{placeholderNote}</p>}
              <h2>{placeholderTitle}</h2>
              <p className="lead">{placeholderDescription}</p>
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
