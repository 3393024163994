import React from 'react'
import { SubmissionError } from 'redux-form/dist/redux-form'
import keys from 'lodash/keys'
import isEmpty from 'lodash/isEmpty'
import isFunction from 'lodash/isFunction'
import isString from 'lodash/isString'
import isPlainObject from 'lodash/isPlainObject'
import isObject from 'lodash/isObject'
import flatMapDeep from 'lodash/flatMapDeep'
import { toast } from 'react-toastify'
import CustomValidationError from '~/components/CustomValidationError'
// TODO it seems we can move all query logic to API
import { buildQueryParams } from '~/common/utils/queryParams'
import { logout } from '~/common/session'
import Cookies from 'js-cookie'
import { offline, online } from '~/store/actions/offline'
import fetchRetry from '~/common/utils/retryPromise'

export const DEFAULT_API_VERSION = 'v3'
export const API_VERSION_V4 = 'v4'

var store

// FIXME make it as middleware
export function configure(s) {
  store = s
}

export default function api(
  endpoint,
  allowEmptyEndpoint = false,
  disableToast = false,
  apiVersion = DEFAULT_API_VERSION
) {
  return new API(endpoint, allowEmptyEndpoint, disableToast, apiVersion)
}

function deepValues(obj) {
  // creates flat list of all `obj` values (including nested)
  if (isPlainObject(obj) || Array.isArray(obj)) {
    return flatMapDeep(obj, deepValues)
  }
  return obj
}

function hasFile(obj) {
  // check if `obj` has at least one `File` instance
  return deepValues(obj || {}).some((v) => v instanceof File || v instanceof Blob)
}

const NUMBER_OF_TRIES = 3
const TRIES_DELAY = 600

class API {
  constructor(
    endpoint,
    allowEmptyEndpoint = false,
    disableToast = false,
    apiVersion = DEFAULT_API_VERSION
  ) {
    if (!allowEmptyEndpoint && !/^\w[^?]+\w$/.test(endpoint)) {
      console.error(
        "invalid API endpoint: '%s'. API endpoint should not contain trailing slashes and query params",
        endpoint
      )
    }
    this.endpoint = endpoint
    this.allowEmptyEndpoint = allowEmptyEndpoint
    this.disableToast = disableToast
    this.apiVersion = apiVersion
  }

  prepareBody(body, isMultipartFormData) {
    if (isEmpty(body) || (isPlainObject(body) && !Object.keys(body).length)) {
      return undefined
    }

    if (isPlainObject(body)) {
      // FIXME we shouldn't send file object represented by url
      ;['avatar', 'logo'].forEach((field) => isString(body[field]) && delete body[field])
    }

    if (isMultipartFormData) {
      const formData = new FormData()

      for (var name in body) {
        if (isFunction(body[name])) {
          // FIXME there should not be functions
          console.warn('API detects invalid data value (function) in field:', name)
          continue
        } else if (Array.isArray(body[name])) {
          // eslint-disable-next-line no-loop-func
          body[name].forEach((value, i) => {
            if (isObject(value)) {
              keys(value).forEach((key) => {
                formData.append(`${name}[${i}]${key}`, value[key])
              })
            } else {
              formData.append(name, value)
            }
          })
        } else if (isPlainObject(body[name])) {
          // eslint-disable-next-line no-loop-func
          keys(body[name]).forEach((key) => {
            formData.append(`${name}.${key}`, body[name][key])
          })
        } else {
          if (body[name] !== null) {
            // FIXME this shouldn't be here. check form body serialization
            // https://github.com/erikras/redux-form/issues/701
            formData.append(name, body[name])
          }
        }
      }
      return formData
    } else {
      return JSON.stringify(body)
    }
  }

  handleResponseCallback = (response) => {
    if (response.status >= 200 && response.status < 500 && store && store.dispatch) {
      store.dispatch(online())
    }
    if (response.status === 401) {
      // 401 (Unauthorized)
      store.dispatch(logout())
      return
    } else if (response.status === 204) {
      // 204 (No Content)
      return Promise.resolve({})
    }

    /*
     * TODO: improve response body and headers for empty response
     */
    if (
      response.headers.get('Content-Type') !== 'application/json' &&
      `${response.headers.get('Content-Type')}`.startsWith('application/json') === false
    ) {
      if (response.ok) {
        return Promise.resolve({})
      }
      return Promise.reject(response)
    }

    return response.json().then((body) => {
      if (response.ok) {
        return body
      }

      // handle errors
      var errors = {}
      const bodyKeys = keys(body)
      bodyKeys.forEach((key) => {
        let eKey = key
        if (key === 'data') {
          return
        }
        if (bodyKeys.includes('user_message')) {
          if (key !== 'user_message') {
            return
          }
          errors.original = body
          eKey = '_error'
        }
        if (key === 'non_field_errors' || key === 'nonFieldErrors' || key === 'detail') {
          eKey = '_error'
        }
        if (Array.isArray(body[key])) {
          errors[eKey] = body[key][0]
        } else {
          errors[eKey] = body[key]
        }

        let error = errors['_error'] || errors['error']

        if (error && !this.disableToast) {
          toast.error(() => <CustomValidationError messages={error} />)
        }
      })

      throw new SubmissionError(errors)
    })
  }

  getApiUrl = (url) => {
    if (DEFAULT_API_VERSION === this.apiVersion) {
      return url
    }
    return url.replace(DEFAULT_API_VERSION, this.apiVersion)
  }

  request(method, params = {}, body = {}) {
    const queryParams = isEmpty(params) ? '' : '?' + buildQueryParams(params)
    const resource = `${this.getApiUrl(import.meta.env.REACT_APP_API_URL)}${this.endpoint}${
      this.allowEmptyEndpoint ? '' : '/'
    }${queryParams}`
    const customHeaders = Cookies.get('csrftoken')
      ? { 'X-CSRFToken': Cookies.get('csrftoken') }
      : {}
    const headers = {
      ...customHeaders,
    }
    const isMultipartFormData = hasFile(body)
    if (!isMultipartFormData) {
      headers['Content-Type'] = 'application/json'
    }

    body = method === 'GET' ? undefined : this.prepareBody(body, isMultipartFormData)
    const options = {
      method,
      headers,
      body,
      credentials: 'include',
      redirect: 'manual',
    }
    const call =
      method === 'GET'
        ? fetchRetry(() => fetch(resource, options), { tries: NUMBER_OF_TRIES, delay: TRIES_DELAY })
        : fetch(resource, options)
    return call.then(this.handleResponseCallback).catch((e) => {
      if (e.type === 'opaqueredirect') {
        return Promise.resolve({})
      }
      if (e instanceof TypeError || e.status >= 500) {
        if (store && store.dispatch) {
          store.dispatch(offline())
          // Handle api response fail with set app to offline mode
          return
        }
      }
      throw e
    })
  }

  post(body = {}, params = {}) {
    return this.request('POST', params, body)
  }

  get(params) {
    return this.request('GET', params)
  }

  put(body = {}, params = {}) {
    return this.request('PUT', params, body)
  }

  patch(body = {}, params = {}) {
    return this.request('PATCH', params, body)
  }

  options() {
    return this.request('OPTIONS')
  }

  head(params) {
    return this.request('HEAD', params)
  }

  delete(params = {}) {
    return this.request('DELETE', params)
  }
}
