import React from 'react'
import { Route } from 'react-router-dom'

import AsyncComponent from '~/components/AsyncComponent'

const IndexPageComponent = () => import('./IndexPage')
const IndexPage = AsyncComponent({ component: IndexPageComponent })

const NewSMSComponent = () => import('./NewSMS/NewSMSContainer')
const NewSMS = AsyncComponent({ component: NewSMSComponent })

const ReviewComponent = () => import('./Review/ReviewContainer')
const Review = AsyncComponent({ component: ReviewComponent })

const SuccessComponent = () => import('./Success/SuccessContainer')
const Success = AsyncComponent({ component: SuccessComponent })

const SendersPageComponent = () => import('./SendersPartial/SendersPartialContainer')
const SendersPage = AsyncComponent({ component: SendersPageComponent })

const ReportComponent = () => import('./DetailedReport/DetailedReportContainer')
const Report = AsyncComponent({ component: ReportComponent })

const PricingComponent = () => import('./Pricing/PricingContainer')
const Pricing = AsyncComponent({ component: PricingComponent })

export const smsRoutes = (
  <>
    <Route exact index element={<IndexPage key="index" />} />
    <Route exact path="sent" element={<IndexPage key="sent" />} />
    <Route exact path="drafts" element={<IndexPage key="drafts" />} />
    <Route exact path="new" element={<NewSMS key="new" />} />
    <Route exact path="drafts/:id" element={<NewSMS key="new-edit" />} />
    <Route exact path="drafts/:id/review" element={<Review key="review" />} />
    <Route exact path="sent/:id/success" element={<Success key="success" />} />
    <Route exact path="reports/:id" element={<Report key="report" />} />
    <Route exact path="senders" element={<SendersPage key="senders" />} />
    <Route exact path="pricing" element={<Pricing key="pricing" />} />
  </>
)
