import React, { Component, Fragment } from 'react'
import {
  reduxForm,
  getFormValues,
  change,
  reset,
  getFormError,
  Field,
  SubmissionError,
} from 'redux-form/dist/redux-form'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl, useIntl } from 'react-intl'
import { toast } from 'react-toastify'
import { CaretDown, Check, Hash, Info, TextT, X } from 'phosphor-react'
import { Dialog, Listbox, Transition } from '@headlessui/react'
import get from 'lodash/get'
import isPlainObject from 'lodash/isPlainObject'

import { connectFormResource } from '~/common/utils/resource'
import Button from '~/common/components/Button'
import { classNames } from '~/common/utils/classNames'
import { resetFields } from '~/common/utils/helpers'
import { requiredCustom } from '~/validations'
import { captureFilteredExceptionHandler } from '~/common/utils/sentry'
import { defaultPagination } from '~/common/utils/constants'
import { ErrorsSubmit } from '~/components/ErrorsSubmit'

const renderField = (field) => (
  <div className="input-row">
    <input {...field.input} type="hidden" />
    {field.meta.touched && field.meta.error && (
      <span className="error text-red-500">{field.meta.error}</span>
    )}
  </div>
)

const options = [
  {
    intlTitleId: 'attributes.modal.type.option.text.label',
    icon: TextT,
    intlDescriptionId: 'attributes.modal.type.option.text.description',
    current: false,
    type: 'text',
  },
  {
    intlTitleId: 'attributes.modal.type.option.number.label',
    icon: Hash,
    intlDescriptionId: 'attributes.modal.type.option.number.description',
    current: false,
    type: 'number',
  },
]

function Dropdown({ disabled, type, handleTypeChange }) {
  const [selected, setSelected] = React.useState(options[0])

  React.useEffect(() => {
    const option = options.find((item) => item.type === type) || options[0]
    setSelected(option)
  }, [type, setSelected])

  const handleSelected = React.useCallback(
    (option) => {
      setSelected(option)
      handleTypeChange(option.type)
    },
    [handleTypeChange]
  )

  return (
    <Listbox value={selected} onChange={handleSelected}>
      {({ open }) => (
        <>
          <Listbox.Label className="text-gray-900">
            <FormattedMessage id="attributes.modal.type.label" />
          </Listbox.Label>
          <div className="relative">
            <div className="relative z-0 rounded-lg">
              <Listbox.Button
                className={`relative inline-flex w-full items-center rounded-md border border-gray-300 bg-white py-1.5 px-2.5 font-medium transition-colors hover:z-10 hover:border-gray-400 ${
                  disabled ? 'pointer-events-none opacity-60' : ''
                }`}
              >
                <span className="sr-only">
                  <FormattedMessage id="attributes.modal.type.help.text" />
                </span>
                <selected.icon
                  className="text-gray-400"
                  size={16}
                  weight="duotone"
                  aria-hidden="true"
                />
                <p className="mb-0 mr-2.5 ml-1.5 text-left font-medium">
                  <FormattedMessage id={selected.intlTitleId} />
                </p>
                {!disabled ? (
                  <CaretDown weight="bold" size={16} className="ml-auto" aria-hidden="true" />
                ) : null}
              </Listbox.Button>
            </div>

            <div className="mt-1 text-gray-500">
              <FormattedMessage id="attributes.modal.type.help.note" />
            </div>

            <Transition
              show={open}
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-1 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Listbox.Options className="absolute left-0 z-10 mt-2 w-full origin-top-left overflow-hidden rounded-lg bg-white p-1.5 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                {options.map((option) => (
                  <Listbox.Option
                    key={option.type}
                    className={({ active }) =>
                      classNames(
                        active ? 'bg-gray-100' : '',
                        'relative cursor-default select-none rounded-md px-2.5 py-1.5 text-gray-900'
                      )
                    }
                    value={option}
                  >
                    {({ selected }) => (
                      <div className="flex flex-col">
                        <div className="flex items-center justify-between">
                          <p
                            className={`mb-0 ${
                              selected ? 'font-semibold' : 'font-normal'
                            } flex items-center gap-1.5`}
                          >
                            <option.icon
                              className="text-gray-400"
                              size={16}
                              weight="duotone"
                              aria-hidden="true"
                            />
                            <span>
                              <FormattedMessage id={option.intlTitleId} />
                            </span>
                          </p>
                          {selected ? (
                            <Check
                              size={16}
                              className="text-gray-900"
                              weight="bold"
                              aria-hidden="true"
                            />
                          ) : null}
                        </div>
                        <p className="mb-0 mt-0.5 ml-6 text-xs text-gray-500">
                          <FormattedMessage id={option.intlDescriptionId} />
                        </p>
                      </div>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  )
}

export function AttributeForm({
  isModal = true,
  inputRef,
  onClose,
  handleSubmit,
  onSubmit,
  isEdit = false,
  formData,
  defaultValues,
  change,
  submitErrors,
}) {
  const intl = useIntl()

  const [attributeValue, setAttributeValue] = React.useState(formData?.name || '')

  React.useEffect(() => {
    const name = formData?.name || ''
    setAttributeValue(name)
  }, [formData])

  const updateNameValue = React.useCallback(
    (e) => {
      change('name', e.target.value)
      if (!formData?.type) {
        change('type', 'text')
      }
    },
    [change, formData?.type]
  )

  const handleNameChange = React.useCallback(
    (e) => {
      setAttributeValue(e.target.value)
    },
    [setAttributeValue]
  )

  const handleNameBlur = React.useCallback(
    (e) => {
      updateNameValue(e)
    },
    [updateNameValue]
  )

  const handleFormKeyDown = React.useCallback(
    (e) => {
      if (e.key === 'Enter') {
        updateNameValue(e)
      }
    },
    [updateNameValue]
  )

  const handleTypeChange = React.useCallback(
    (value) => {
      change('type', value)
    },
    [change]
  )

  const isSubmitDisabled = !attributeValue && !defaultValues?.name

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault()
        event.stopPropagation()
        handleSubmit(onSubmit)()
      }}
      onKeyDown={handleFormKeyDown}
    >
      {isModal && (
        <>
          <div className="flex items-center justify-between gap-4">
            <h3 className="mb-0">
              {isEdit ? (
                <FormattedMessage id="actions.attribute.edit" />
              ) : (
                <FormattedMessage id="attributes.addAttributeBtn" />
              )}
            </h3>
            <button
              type="button"
              className="p-1 text-gray-500 hover:text-gray-900"
              onClick={onClose}
            >
              <X weight="bold" size={16} />
            </button>
          </div>
          <p className="my-4">
            <FormattedMessage id="attributes.modal.description" />
          </p>
        </>
      )}
      <div>
        <Dropdown disabled={isEdit} type={formData?.type} handleTypeChange={handleTypeChange} />
        <Field name="type" component={renderField} />
      </div>
      <div className="mt-4">
        <label className="text-gray-900" htmlFor="name">
          <FormattedMessage id="attributes.modal.name.label" />
        </label>
        <div className="flex flex-1">
          <input
            className="relative block w-full rounded-md border-gray-300 px-2.5 py-1.5 text-sm transition placeholder:text-gray-400 hover:border-gray-400 focus:border-blue-500 focus:ring-0"
            type="text"
            ref={inputRef}
            defaultValue={defaultValues?.name ?? attributeValue}
            onChange={handleNameChange}
            onBlur={handleNameBlur}
            placeholder={intl.formatMessage({
              id: 'attributes.modal.name.placeholder',
            })}
            name="name"
            id="name"
          />
        </div>
        <Field name="name" component={renderField} />
      </div>

      <div className="mt-4">
        <ErrorsSubmit errors={submitErrors} />
      </div>

      <div className="mt-7 flex items-center justify-between gap-4">
        <div>
          <a
            className="gan-text-link flex items-center gap-1"
            href={intl.formatMessage({ id: 'attributes.modal.help.link' })}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Info weight="duotone" className="" size={16} />
            <span>
              <FormattedMessage id="attributes.modal.learnMore" />
            </span>
          </a>
        </div>
        <div className="flex items-center gap-4">
          <Button type="button" onClick={onClose}>
            <FormattedMessage id="common.cancel" />
          </Button>
          <Button type="submit" disabled={isSubmitDisabled} variant="primary">
            <FormattedMessage id="common.save" />
          </Button>
        </div>
      </div>
    </form>
  )
}

export function AttributeModal({
  isOpen,
  onClose,
  handleSubmit,
  onSubmit,
  isEdit = false,
  formData,
  change,
  submitErrors,
  defaultValues,
}) {
  const inputRef = React.useRef(null)

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog initialFocus={inputRef} as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-30" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-200"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-100"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-lg transform rounded-lg border border-white bg-white bg-opacity-90 p-6 pb-8 pt-7 text-left align-middle shadow-2xl backdrop-blur-sm transition-all md:rounded-xl md:px-8">
                <AttributeForm
                  inputRef={inputRef}
                  handleSubmit={handleSubmit}
                  onSubmit={onSubmit}
                  isEdit={isEdit}
                  formData={formData}
                  defaultValues={defaultValues}
                  change={change}
                  submitErrors={submitErrors}
                  onClose={onClose}
                />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

export class FormAddAttributeContainer extends Component {
  onSubmit = async (data) => {
    const { onClose, onSubmit, code, intl, resetFields } = this.props
    try {
      await onSubmit(data)
      toast.success(
        <FormattedMessage id={code ? 'toasters.attribute.updated' : 'toasters.attribute.created'} />
      )
      onClose(true)
      resetFields({
        name: '',
        type: 'text',
      })
    } catch (e) {
      captureFilteredExceptionHandler(e)
      if (isPlainObject(e)) {
        throw new SubmissionError(e)
      }
      if (e instanceof SubmissionError) {
        throw e
      }
      throw new SubmissionError({
        _error: intl.formatMessage({ id: 'common.submit.error' }),
      })
    }
  }

  componentWillUnmount() {
    this.props.attribute.setData(null)
  }

  render() {
    const { onClose, handleSubmit, code } = this.props
    return (
      <AttributeForm
        {...this.props}
        code={code}
        isEdit={!!code}
        onClose={onClose}
        onSubmit={this.onSubmit}
        handleSubmit={handleSubmit}
        isModal={false}
      />
    )
  }
}

export class ModalAddAttribute extends Component {
  onSubmit = async (data) => {
    const { onClose, onSubmit, onSuccess, code, intl } = this.props
    try {
      await onSubmit(data)
      await onSuccess?.()
      toast.success(
        <FormattedMessage id={code ? 'toasters.attribute.updated' : 'toasters.attribute.created'} />
      )
      onClose(true)
    } catch (e) {
      captureFilteredExceptionHandler(e)
      if (isPlainObject(e)) {
        throw new SubmissionError(e)
      }
      if (e instanceof SubmissionError) {
        throw e
      }
      throw new SubmissionError({
        _error: intl.formatMessage({ id: 'common.submit.error' }),
      })
    }
  }

  componentWillUnmount() {
    this.props.attribute.setData(null)
  }

  render() {
    const { isOpen, onClose, handleSubmit, code } = this.props
    return (
      <AttributeModal
        {...this.props}
        code={code}
        isOpen={isOpen}
        isEdit={!!code}
        onClose={onClose}
        onSubmit={this.onSubmit}
        handleSubmit={handleSubmit}
      />
    )
  }
}

const validate = (values) => {
  const errors = {
    type: requiredCustom(
      get(values, 'type'),
      <FormattedMessage id="validations.required.label.type" />
    ),
    name: requiredCustom(
      get(values, 'name'),
      <FormattedMessage id="validations.required.label.name" />
    ),
  }
  return Object.keys(errors)
    .filter((key) => Boolean(errors[key]))
    .map((key) => ({ [key]: errors[key] }))
    .reduce((result, current) => ({ ...result, ...current }), {})
}

const getInitialValues = (formData, init) => {
  const type = get(formData, 'type')
  const name = get(formData, 'name')

  const initialValues = {
    ...init,
  }
  if (type) {
    initialValues.type = type
  }
  if (name) {
    initialValues.name = name
  }
  return initialValues
}

const mapDispatchToProps = (dispatch) => ({
  resetFields: (fieldsObj) => resetFields(dispatch, 'addAttribute', fieldsObj),
  reset: (name) => dispatch(reset(name)),
  change: (field, value) => dispatch(change('addAttribute', field, value)),
})

const defaultCompose = compose(
  connectFormResource({
    namespace: 'attribute',
    endpoint: 'attributes',
    list: true,
    idKey: 'code',
    async: true,
    filters: { paginate_by: defaultPagination },
  }),
  connect((state, props) => {
    const formData = getFormValues('addAttribute')(state)

    const submitErrors = {}
    submitErrors['error'] = getFormError('addAttribute')(state)
    const initialValues = getInitialValues(formData, get(state.resource, 'attribute.data'))
    return {
      initialValues,
      formData,
      submitErrors,
      validationErrors: validate(formData),
    }
  }, mapDispatchToProps),
  reduxForm({
    form: 'addAttribute',
    enableReinitialize: true,
    validate,
  }),
  injectIntl
)

export const FormAddAttribute = defaultCompose(FormAddAttributeContainer)

export default defaultCompose(ModalAddAttribute)
