import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import { Modal, ModalBody, ModalFooter } from '~/components/Modal'
import ButtonSpinner from '~/components/ButtonSpinner'
import serviceStatus from '~/services/status'
import { Checkbox } from '~/components/Checkbox'

const propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
}

export default class ModalConfirmationWrapper extends Component {
  state = {
    validated: false,
    status: serviceStatus.OK,
    additional: false,
  }

  onConfirm = (event) => {
    if (event) {
      event.preventDefault()
    }
    this.props.onConfirm(event)
    if (this.state.additional) {
      this.props.checkboxAction()
    }
    this.props.onClose()
  }

  onAdditionalConfirm = (event) => {
    this.setState({ additional: !this.state.additional })
  }

  render() {
    const {
      open,
      onClose,
      message,
      checkboxAction,
      checkboxActionText,
      confirmBtn,
      isPositive,
      size = 'sm',
      title,
    } = this.props
    const { status, additional } = this.state
    if (!open) return null
    return (
      <Modal onClose={onClose} className="confirmation-modal" size={size}>
        <ModalBody>
          {title && <h3>{title}</h3>}
          <p className="lead">{message}</p>
          {checkboxAction && (
            <div className="custom-control custom-checkbox mt-3">
              <Checkbox
                className="custom-control-input"
                id="additional"
                onChange={this.onAdditionalConfirm}
                value={additional}
                checked={additional}
              />
              <label className="custom-control-label" htmlFor="additional">
                {checkboxActionText}
              </label>
            </div>
          )}
        </ModalBody>
        <ModalFooter className="flex gap-4">
          <button className="btn btn-light" onClick={this.props.onClose}>
            <FormattedMessage id="common.cancel" />
          </button>
          <ButtonSpinner
            className={`btn btn-${isPositive ? 'success' : 'danger'}`}
            spin={status === serviceStatus.LOADING}
            disabled={status === serviceStatus.LOADING}
            onClick={this.onConfirm}
          >
            {confirmBtn || <FormattedMessage id="common.delete" />}
          </ButtonSpinner>
        </ModalFooter>
      </Modal>
    )
  }
}

ModalConfirmationWrapper.propTypes = propTypes
