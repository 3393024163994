export const SHOW = '@ds-intercom/show'
export const HIDE = '@ds-intercom/hide'

export function show() {
  return {
    type: SHOW,
  }
}

export function hide() {
  return {
    type: HIDE,
  }
}
