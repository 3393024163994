import { Component } from 'react'
import { useLocation } from 'react-router-dom'

class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0)
    }
  }

  render() {
    return this.props.children
  }
}

function ScrollToTopWrapper(props) {
  const location = useLocation()
  return <ScrollToTop {...props} location={location} />
}

export default ScrollToTopWrapper
