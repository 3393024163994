import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import isNil from 'lodash/isNil'

import {
  checkPathHasTrailingSlash,
  removeTrailingSlashFromPath,
} from '~/common/utils/exactPathname'

/**
 * NavLink component does not support for trailing slash urls
 * Support trailing slash urls
 */
export default function NavLinkExact({ exact, to, ...props }) {
  const { pathname } = useLocation()

  if (isNil(exact) || to === '/') {
    return <NavLink {...props} end={exact} to={to} />
  }

  let exactTo = removeTrailingSlashFromPath(to)

  if (!checkPathHasTrailingSlash(pathname)) {
    return <NavLink {...props} end={exact} to={exactTo} />
  }

  return <NavLink {...props} end={exact} to={`${exactTo}/`} />
}
