import React, { Component } from 'react'

const styles = {
  overflow: 'hidden',
  overflowX: 'hidden',
  overflowY: 'hidden',
  width: '100%',
  height: '100%',
  position: 'fixed',
  top: '0',
  left: '0',
  zIndex: 70000,
}

class FileManager extends Component {
  render() {
    return (
      <div>
        <iframe
          title="filemanager"
          frameBorder="0"
          width="0"
          height="0"
          src={import.meta.env.REACT_APP_FILE_MANAGER_PATH}
          style={styles}
        />
      </div>
    )
  }
}

export default FileManager
