import isNil from 'lodash/isNil'

export const checkPathHasTrailingSlash = (path) => {
  if (isNil(path)) {
    return false
  }

  return path !== '/' && path.endsWith('/')
}

export const removeTrailingSlashFromPath = (path) => {
  if (isNil(path) || !checkPathHasTrailingSlash(path)) {
    return path
  }

  return path.slice(0, -1)
}
