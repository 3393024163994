import CheckAccess from './CheckAccess'

const logout = function (skipPartner = false) {
  const location = window.location.hostname

  document.cookie = `jwt=; domain=localhost; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`
  document.cookie = `jwt=; domain=${location
    .replace(/^app/, '')
    .replace(/^beta.app/, '')
    .replace(/^old.app/, '')}; expires=Thu, 01 Jan 1970 00:00:00 UTC; secure=true; path=/`

  if (!skipPartner) {
    document.cookie = `partner_jwt=; domain=localhost; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`
    document.cookie = `partner_jwt=; domain=${location
      .replace(/^app/, '')
      .replace(/^beta.app/, '')
      .replace(/^old.app/, '')}; expires=Thu, 01 Jan 1970 00:00:00 UTC; secure=true; path=/`
  }

  document.cookie = `apisessionid=; domain=localhost; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`
  document.cookie = `apisessionid=; domain=${location
    .replace(/^app/, '')
    .replace(/^beta.app/, '')
    .replace(/^old.app/, '')}; expires=Thu, 01 Jan 1970 00:00:00 UTC; secure=true; path=/`
  return {
    type: '@ds-resource/set-data',
    meta: {
      resource: { namespace: 'session' },
    },
    payload: { logout: true, skipPartner },
  }
}

export { CheckAccess, logout }
