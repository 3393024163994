import React from 'react'
import { compose } from 'redux'
import get from 'lodash/get'
import { CaretDown, CaretUp } from 'phosphor-react'
import { FormattedMessage } from 'react-intl'

import { connectResource } from '~/common/utils/resource'
import Spinner from '~/components/Spinner'
import { TextField } from '~/common/forms/fields'
import { validateNumber } from '~/validations'
import AddAttributeForm from './AddAttributeForm'
import { defaultPagination } from '~/common/utils/constants'

export function AttributesForm(props) {
  const [showItems, setShowItems] = React.useState(false)

  let Component = null
  if (showItems === true) {
    Component = (
      <>
        <div className="row">
          <div className="col">
            <div className="mb-2">
              <button
                type="button"
                data-id="btn-link"
                className="flex items-center space-x-1 leading-none text-blue-600"
                onClick={() => setShowItems((prevState) => !prevState)}
              >
                <FormattedMessage id="contacts.hideAttributes" />{' '}
                <CaretUp size={14} strokeWidth={2.5} />
              </button>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            {!!get(props, 'attributes.data.length') &&
              get(props, 'attributes.data', []).map((item, id) => (
                <div className="form-group" key={item.name + id}>
                  <TextField
                    type={item.type || 'text'}
                    name={`attributes.${item.code}`}
                    label={item.name}
                    validate={item.type === 'number' ? [validateNumber] : undefined}
                  />
                </div>
              ))}
          </div>
        </div>
        <AddAttributeForm attributes={props.attributes} />
      </>
    )
  } else {
    Component = (
      <div className="row">
        <div className="col">
          <div className="mb-4">
            <button
              type="button"
              data-id="btn-link"
              className="flex items-center space-x-1 leading-none text-blue-600"
              onClick={() => setShowItems((prevState) => !prevState)}
            >
              <FormattedMessage id="contacts.showAllAttributes" />{' '}
              <CaretDown size={14} strokeWidth={2.5} />
            </button>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <Spinner show={props.attributes && props.attributes.isLoading} />
      {Component}
    </>
  )
}

export default compose(
  connectResource({
    namespace: 'attributes',
    endpoint: 'attributes',
    list: true,
    filters: { paginate_by: defaultPagination },
    async: true,
  })
)(AttributesForm)
