import React, { Suspense } from 'react'

import Spinner from '~/components/Spinner'
import importHandleError from '~/common/utils/importHandleError'

const LoadingComponent = () => (
  <div className="gan-container relative h-4/6 min-h-[calc(100vh-200px)]">
    <Spinner show={true} />
  </div>
)

const AsyncComponent = (opts) => (props) => {
  const [Component, setComponent] = React.useState(null)

  React.useEffect(() => {
    setComponent(importHandleError(opts?.component))
  }, [opts?.component])

  if (!opts?.component || !Component) {
    return null
  }

  return (
    <Suspense fallback={opts?.disableLoader ? <div>Loading</div> : <LoadingComponent />}>
      <Component {...props} />
    </Suspense>
  )
}

export default AsyncComponent
