import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { noop } from '~/lib/utils'
import get from 'lodash/get'
import { FiLoader, FiAlertCircle, FiCheckCircle } from 'react-icons/fi'

const propTypes = {
  inputClassName: PropTypes.string,
  placeholder: PropTypes.string,
  pattern: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  name: PropTypes.string,
  meta: PropTypes.object,
  customMeta: PropTypes.object,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

const defaultProps = {
  inputClassName: 'input-custom',
  readOnly: false,
  meta: {},
  onChange: noop,
}

export default class TextInput extends PureComponent {
  handleChange = (e) => {
    this.props.onChange(e.target.value)
  }

  render() {
    const {
      inputClassName,
      inputComponent,
      appendAddonComponent,
      input,
      meta,
      label,
      appendAddon,
      customMeta,
      helpText,
      ignoreTouched,
      ...restProps
    } = this.props

    const isShowError =
      (this.props.meta.touched && this.props.meta.error) || get(customMeta, 'error')
    const errorClass = isShowError && `error ${get(customMeta, 'check') && 'is-invalid'}`
    const className = `form-control placeholder-light ${errorClass} ${this.props.inputClassName}`
    const style = {
      backgroundImage: get(customMeta, 'check') ? 'none' : undefined,
    }

    if (!get(customMeta, 'check')) {
      return (
        <input {...restProps} className={className} style={style} onChange={this.handleChange} />
      )
    }

    const imageStyles = {
      stroke:
        this.props.meta.asyncValidating || get(customMeta, 'isLoading')
          ? '#000000'
          : get(customMeta, 'error')
          ? '#FC8181'
          : '#38a169',
      animation:
        this.props.meta.asyncValidating || get(customMeta, 'isLoading')
          ? 'spinner-rotate 1s linear infinite'
          : undefined,
    }

    const Image = get(customMeta, 'check')
      ? this.props.meta.asyncValidating || get(customMeta, 'isLoading')
        ? FiLoader
        : get(customMeta, 'error')
        ? FiAlertCircle
        : restProps.value &&
          this.props.meta.valid &&
          !this.props.meta.active &&
          typeof get(customMeta, 'error') !== 'undefined'
        ? FiCheckCircle
        : undefined
      : undefined

    style.border =
      (isShowError && get(customMeta, 'isWarning')) || this.props.meta.valid
        ? '1px solid #d1dce6'
        : undefined

    return (
      <span style={{ position: 'relative', width: '100%', display: 'block' }}>
        <input {...restProps} className={className} style={style} onChange={this.handleChange} />
        {Image && (
          <span
            style={{
              position: 'absolute',
              top: '8px',
              right: '10px',
            }}
          >
            <Image style={imageStyles} size="16" strokeWidth={2.5} />
          </span>
        )}
      </span>
    )
  }
}

TextInput.propTypes = propTypes
TextInput.defaultProps = defaultProps
