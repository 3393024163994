import React, { Component } from 'react'
import { Route, Outlet } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

import NavLinkExact from '~/components/NavLinkExact'
import AsyncComponent from '~/components/AsyncComponent'

const ApiTokensContainerComponent = () => import('./ApiTokensContainer')
const ApiTokensContainer = AsyncComponent({ component: ApiTokensContainerComponent })

const ApiPluginsComponent = () => import('./ApiPlugins')
const ApiPlugins = AsyncComponent({ component: ApiPluginsComponent })

export default class ApiPartial extends Component {
  render() {
    return (
      <div className="row">
        <div className="col-12 col-md-3 mb-4">
          <div className="list-group">
            <NavLinkExact
              to="/account/api"
              exact
              className="list-group-item list-group-item-action flex items-center justify-between"
            >
              <FormattedMessage id="apiTokens.title" />
            </NavLinkExact>
            <NavLinkExact
              to="/account/api/plugins"
              exact
              className="list-group-item list-group-item-action flex items-center justify-between"
            >
              <FormattedMessage id="apiTokens.plugins" />
            </NavLinkExact>
          </div>
        </div>
        <Outlet />
      </div>
    )
  }
}

export const apiPartialRoutes = (
  <>
    <Route exact index element={<ApiTokensContainer />} />
    <Route exact path="plugins" element={<ApiPlugins />} />
  </>
)
