import React from 'react'
import get from 'lodash/get'

import { isIE } from '~/common/utils/helpers'

const Image = ({ image, alt, className, width, height }) => {
  const src = get(image, 'img.src')

  if (typeof image === 'string') {
    return <img src={image} alt={alt} width={width} height={height} className={className} />
  }

  if (!src) {
    return null
  }

  if (isIE()) {
    return <img src={image.img.src} alt={alt} width={width} height={height} className={className} />
  }

  return (
    <picture>
      {Object.entries(image.sources).map(([format, images], index) => {
        return (
          <source
            key={index}
            srcSet={images.map((i) => `${i.src} ${i.w}w`).join(', ')}
            type={'image/' + format}
          />
        )
      })}
      <img src={image.img.src} alt={alt} width={width} height={height} className={className} />
    </picture>
  )
}

export default Image
