import React, { PureComponent } from 'react'
import { Route, Outlet } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

import AsyncComponent from '~/components/AsyncComponent'
import Api, { apiPartialRoutes } from './partials/ApiPartial/ApiPartial'
import Affiliate, { affiliatePartialRoutes } from './partials/AffiliatePartial'
import PageSubNav from '~/components/PageSubNav'
import { PageTitle } from '~/common/widgets'
import DeleteAccountContainer from '~/components/Pages/DeleteAccount/DeleteAccountContainer'

import './styles.scss'

const links = [
  {
    name: <FormattedMessage id="appNavBar.profileDropDown.profile" />,
    link: '/account/profile',
  },
  {
    name: <FormattedMessage id="appNavBar.profileDropDown.account" />,
    link: '/account',
    end: true,
  },
  {
    name: <FormattedMessage id="appNavBar.profileDropDown.notifications" />,
    link: '/account/messages',
  },
  {
    name: <FormattedMessage id="appNavBar.profileDropDown.settings" />,
    link: '/account/settings',
  },
  {
    name: <FormattedMessage id="appNavBar.profileDropDown.senders" />,
    link: '/account/senders',
  },
  {
    name: <FormattedMessage id="appNavBar.profileDropDown.api" />,
    link: '/account/api',
  },
  {
    name: <FormattedMessage id="appNavBar.profileDropDown.affiliate" />,
    link: '/account/affiliate',
  },
]

const Account = AsyncComponent({
  component: () => import('./partials/AccountPartial'),
})

const Profile = AsyncComponent({
  component: () => import('./partials/ProfilePartial'),
})

const Notifications = AsyncComponent({
  component: () => import('./partials/NotificationsPartial'),
})

const Settings = AsyncComponent({
  component: () => import('./partials/SettingsPartial'),
})

const Senders = AsyncComponent({
  component: () => import('./partials/SendersPartial/SendersPartialContainer'),
})

export default class AccountPage extends PureComponent {
  render() {
    return (
      <div className="gan-container my-8">
        <PageTitle titles={['title.myAccount']} />
        <div className="mb-6 flex items-center justify-between">
          <div>
            <h1 className="mb-0 capitalize">
              <FormattedMessage id="title.myAccount" />
            </h1>
            <p className="mt-1 mb-0 font-medium text-gray-600">
              <FormattedMessage id="subtitle.myAccount" />
            </p>
          </div>
        </div>
        <PageTitle titles={['title.myAccount']} />
        <PageSubNav links={links} />

        <Outlet />
      </div>
    )
  }
}

export const accountRoutes = (
  <>
    <Route exact index element={<Account key="index" />} />
    <Route exact path="invoices" element={<Account key="invoices" />} />
    <Route exact path="delete" element={<DeleteAccountContainer />} />
    <Route exact path="pause" element={<Account key="pause" />} />
    <Route exact path="details/login" element={<Profile key="details-login" />} />

    <Route path="profile" element={<Outlet />}>
      <Route exact index element={<Profile key="profile" />} />
      <Route exact path="address" element={<Profile key="profile-address" />} />
    </Route>

    <Route path="messages" element={<Outlet />}>
      <Route exact index element={<Notifications key="messages" />} />
      <Route exact path="all" element={<Notifications key="messages-all" />} />
    </Route>

    <Route path="settings" element={<Outlet />}>
      <Route exact index element={<Settings key="settings" />} />
      <Route exact path="partners" element={<Settings key="settings-partners" />} />
    </Route>

    <Route path="senders" element={<Outlet />}>
      <Route exact index element={<Senders key="senders" />} />
      <Route exact path="edit/:id" element={<Senders key="senders-edit-id" />} />
    </Route>

    <Route path="api" element={<Api />}>
      {apiPartialRoutes}
    </Route>

    <Route path="affiliate" element={<Affiliate />}>
      {affiliatePartialRoutes}
    </Route>
  </>
)
