import React from 'react'
import { Route, Outlet } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

import NavLinkExact from '~/components/NavLinkExact'
import AsyncComponent from '~/components/AsyncComponent'

const AffiliateOverviewContainerComponent = () => import('./AffiliateOverviewContainer')
const AffiliateOverviewContainer = AsyncComponent({
  component: AffiliateOverviewContainerComponent,
})

const AffiliateGetStartedComponent = () => import('./AffiliateGetStarted')
const AffiliateGetStarted = AsyncComponent({ component: AffiliateGetStartedComponent })

const AffiliateBannerLinksContainerComponent = () => import('./AffiliateBannerLinksContainer')
const AffiliateBannerLinksContainer = AsyncComponent({
  component: AffiliateBannerLinksContainerComponent,
})

const AffiliateTransactionsContainerComponent = () => import('./AffiliateTransactionsContainer')
const AffiliateTransactionsContainer = AsyncComponent({
  component: AffiliateTransactionsContainerComponent,
})

const AffiliatePayoutsContainerComponent = () => import('./AffiliatePayoutsContainer')
const AffiliatePayoutsContainer = AsyncComponent({ component: AffiliatePayoutsContainerComponent })

const AffiliateRequestPaymentContainerComponent = () => import('./AffiliateRequestPaymentContainer')
const AffiliateRequestPaymentContainer = AsyncComponent({
  component: AffiliateRequestPaymentContainerComponent,
})

export default function AffiliatePartial(props) {
  return (
    <div className="row mb-3">
      <div className="col-12 col-md-3 mb-4">
        <ul className="list-group">
          <NavLinkExact
            exact
            to="/account/affiliate"
            className="list-group-item list-group-item-action"
          >
            <FormattedMessage id="affiliate.links.overview" />
          </NavLinkExact>
          <NavLinkExact
            exact
            to="/account/affiliate/get-started"
            className="list-group-item list-group-item-action"
          >
            <FormattedMessage id="affiliate.links.getStarted" />
          </NavLinkExact>
          <NavLinkExact
            exact
            to="/account/affiliate/banner-links"
            className="list-group-item list-group-item-action"
          >
            <FormattedMessage id="affiliate.links.bannersLinks" />
          </NavLinkExact>
          <NavLinkExact
            exact
            to="/account/affiliate/transactions"
            className="list-group-item list-group-item-action"
          >
            <FormattedMessage id="affiliate.links.transactions" />
          </NavLinkExact>
          <NavLinkExact
            to="/account/affiliate/request-payment"
            className="list-group-item list-group-item-action"
          >
            <FormattedMessage id="affiliate.links.requestPayment" />
          </NavLinkExact>
          <NavLinkExact
            to="/account/affiliate/payouts"
            className="list-group-item list-group-item-action"
          >
            <FormattedMessage id="affiliate.links.payouts" />
          </NavLinkExact>
        </ul>
      </div>
      <div className="col-12 col-md-9">
        <Outlet />
      </div>
    </div>
  )
}

export const affiliatePartialRoutes = (
  <>
    <Route exact index element={<AffiliateOverviewContainer />} />
    <Route exact path="get-started" element={<AffiliateGetStarted />} />
    <Route exact path="banner-links" element={<AffiliateBannerLinksContainer />} />
    <Route exact path="transactions" element={<AffiliateTransactionsContainer />} />
    <Route exact path="payouts" element={<AffiliatePayoutsContainer />} />
    <Route exact path="request-payment" element={<AffiliateRequestPaymentContainer />} />
  </>
)
