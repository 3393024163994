import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'react-router-redux'
import { createStore, applyMiddleware, combineReducers, compose as reduxCompose } from 'redux'
import { reducer as form } from 'redux-form/dist/redux-form'
import { createEpicMiddleware } from 'redux-observable'
import { middleware as cacheMiddleware, state as initialState } from './cache'
import { reducers } from '~/store'
import { reducer as resource, getEpic as getResourceEpic } from '~/common/utils/resource'
import API, { configure as configureAPI } from '~/api'

const epicMiddleware = createEpicMiddleware({ dependencies: { API } })

const history = createBrowserHistory()

// support for redux dev tools
const compose = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || reduxCompose

const initStore = (initialState) => {
  const store = createStore(
    combineReducers({
      form,
      resource,
      ...reducers,
    }),
    initialState,
    compose(applyMiddleware(epicMiddleware, cacheMiddleware, routerMiddleware(history)))
  )

  epicMiddleware.run(getResourceEpic(history))

  return store
}

const store = initStore(initialState)

configureAPI(store)

export { store, history, initStore }
