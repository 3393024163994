import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import get from 'lodash/get'
import { injectIntl } from 'react-intl'

import ReactivateAccount from './ReactivateAccount'
import { connectResource } from '~/common/utils/resource'
import {
  addEvent,
  prepareTransactionEvent,
  storePurchaseEvents,
} from '~/common/utils/googleTagManager'
import { captureException } from '~/common/utils/sentry'

export class ReactivateAccountContainer extends Component {
  _isMounted = false

  state = {
    showPaymentModal: false,
    isCard: true,
    invoice: null,
    isLoading: true,
  }

  onSubmit = async () => {
    const request = {
      article: this.props.article.id,
      pay_by_card: 1,
      start: 0,
    }

    if (get(this.props.upgradeAccount, 'data.order_id')) {
      return this.showPaymentModalTrigger(true)
    }
    try {
      await this.upgradeAccount(request)
      this.showPaymentModalTrigger(true)
    } catch (e) {
      if (get(e, '_error') === 'You have unpaid invoice.') {
        try {
          if (this._isMounted) this.setState({ isLoading: true })
          const invoice = await this.fetchUnpaidInvoice()
          if (this._isMounted) this.setState({ invoice, showPaymentModal: true, isLoading: false })
        } catch (e) {
          if (this._isMounted) this.setState({ isLoading: false })
          captureException(e)
        }
      }
    }
  }

  upgradeAccount = async (request) => {
    const { article, upgradeAccount } = this.props

    const response = await upgradeAccount.create(request)

    if (!article || !response || (response && !response.order_id)) {
      return response
    }

    const event = prepareTransactionEvent(response, this.props)
    addEvent(event)
    storePurchaseEvents(response, this.props)

    return response
  }

  fetchUnpaidInvoice = async () => {
    const {
      unpaidInvoice,
      intl: { formatMessage },
    } = this.props
    let response
    try {
      response = await unpaidInvoice.filter({
        paid_time__isnull: true,
      })
    } catch (e) {
      if (this._isMounted) {
        this.setState({
          error: get(e, 'error.message', formatMessage({ id: 'invoice.unpaid.fetch.error' })),
        })
      }
      captureException(e)
    }
    return get(response, 'results[0]')
  }

  showPaymentModalTrigger = (show) => {
    if (this._isMounted)
      this.setState(
        (prevState) => {
          const showPaymentModal =
            typeof show === 'undefined' || typeof show !== 'boolean'
              ? !prevState.showPaymentModal
              : show
          return { showPaymentModal }
        },
        () => {
          if (!this.state.showPaymentModal) {
            this.props.onClose()
          }
        }
      )
  }

  async componentDidMount() {
    this._isMounted = true
    if (this._isMounted) this.setState({ isLoading: true })
    if (get(this.props, 'profile.data.unpaid_invoice')) {
      try {
        const invoice = await this.fetchUnpaidInvoice()
        if (this._isMounted) this.setState({ invoice, showPaymentModal: true, isLoading: false })
      } catch (e) {
        if (this._isMounted) this.setState({ isLoading: false })
        captureException(e)
      }
    } else {
      if (this._isMounted) this.setState({ isLoading: false })
    }
    if (this.props.id) {
      await this.props.upgradeConditions.filter()
    }
  }

  componentWillUnmount() {
    this._isMounted = false
    this.props.upgradeAccount.setData(null)
  }

  render() {
    return (
      <ReactivateAccount
        {...this.props}
        {...this.state}
        onSubmit={this.onSubmit}
        onPaymentModalClose={this.showPaymentModalTrigger}
      />
    )
  }
}

export default compose(
  connectResource({
    namespace: 'address',
    endpoint: 'profile/address',
    async: true,
  }),
  connectResource({
    namespace: 'billing',
    endpoint: 'profile/billing_address',
    async: true,
  }),
  connectResource({
    namespace: 'upgradeAccount',
    endpoint: 'upgrade_account',
    prefetch: false,
  }),
  connectResource({
    namespace: 'profile',
    endpoint: 'profile',
    async: true,
    prefetch: false,
  }),
  connectResource({
    namespace: 'discount',
    endpoint: 'upgrade_discount',
    async: true,
    prefetch: true,
    refresh: true,
  }),
  connect((state, props) => ({
    id: get(props, 'article.id') || get(state, 'resource.articleData.data.id'),
  })),
  connectResource({
    namespace: 'upgradeConditions',
    endpoint: 'upgrade_conditions/:id',
    async: true,
    prefetch: false,
  }),
  connectResource({
    namespace: 'unpaidInvoice',
    endpoint: 'invoices',
    idKey: 'id',
    list: true,
    prefetch: false,
    filters: {
      paid_time__isnull: true,
    },
  }),
  injectIntl
)(ReactivateAccountContainer)
