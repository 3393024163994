export const CLEAR = '@ds-vat-id/clear'
export const SET_ERROR = '@ds-vat-id/set-error'
export const SET_VALID = '@ds-vat-id/set-valid'

export const clear = (meta) => (payload) => {
  return {
    type: CLEAR,
    payload,
    meta,
  }
}

export const setError = (meta) => (payload) => {
  return {
    type: SET_ERROR,
    payload,
    meta,
  }
}

export const setValid = (meta) => (payload) => {
  return {
    type: SET_VALID,
    payload,
    meta,
  }
}
