import React from 'react'
import { FormattedMessage } from 'react-intl'
import { compose } from 'redux'
import { connect } from 'react-redux'
import get from 'lodash/get'
import { Link } from 'react-router-dom'

import { CaretDown } from 'phosphor-react'
import { Checkbox } from '~/components/Checkbox'
import Dropdown from '~/common/widgets/Dropdown'
import { TableContext } from '~/common/widgets/Table'
import { PLANS_LEVELS, TRIAL_PRIOD_STATUS } from '~/common/utils/constants/providers'
import { getPlanLevelWithTrial, getTrialPeriodStatus } from '~/common/utils/helpers'
import RequireUpgrade from '~/common/widgets/RequireUpgrade'

export function ToggleColumnTable({ planLevel, trialStatus, localStorageName }) {
  const [isDropdownOpen, setDropdownOpen] = React.useState(false)
  const {
    toggleColumnList,
    shownColumnIdList,
    handleToggleColumn,
    setStorageName,
  } = React.useContext(TableContext)

  React.useEffect(() => {
    if (localStorageName) {
      setStorageName(localStorageName)
    }
  }, [localStorageName, setStorageName])

  return (
    <Dropdown
      buttonClasses="btn btn-light"
      dropdownMenuClasses="p-0"
      disableButtonIcon
      closeOnClickMenu={false}
      buttonContent={
        <span className="flex items-center gap-2">
          <FormattedMessage id="common.titles.columns" />
          <CaretDown />
        </span>
      }
      visible={isDropdownOpen}
      handleToggleDropdown={() => setDropdownOpen((prevState) => !prevState)}
    >
      <div className="w-64 border-b px-3.5 pt-3">
        {planLevel < PLANS_LEVELS.STANDARD && (
          <p className="font-weight-bold mb-2 text-sm">
            <FormattedMessage id="common.availableOnStandard" />
          </p>
        )}
        <p className="m-0 text-xs text-gray-600">
          <FormattedMessage id="tables.chooseColumnToInclude" />
        </p>
        <div className="m-0 mt-2 mb-3">
          <RequireUpgrade onStartedTrialSuccess={() => setDropdownOpen(false)}>
            {planLevel < PLANS_LEVELS.STANDARD && trialStatus !== TRIAL_PRIOD_STATUS.USING ? (
              <Link to="/upgrade?planLevel=20" className="btn btn-success flex-1 md:flex-initial">
                <FormattedMessage id="common.upgradeToStandard" />
              </Link>
            ) : null}
          </RequireUpgrade>
        </div>
      </div>

      <div className="p-1.5">
        {toggleColumnList.map((column) => {
          return (
            <div
              key={column.toggleId}
              className="dropdown-item custom-control custom-checkbox cursor-pointer rounded-md p-0 pl-8 hover:bg-gray-100"
            >
              <Checkbox
                className="custom-control-input cursor-pointer"
                id={column.toggleId}
                name={column.toggleId}
                onChange={() => handleToggleColumn(column.toggleId)}
                checked={shownColumnIdList.includes(column.toggleId)}
                disabled={planLevel === PLANS_LEVELS.START}
              />
              <label
                className="custom-control-label user-select-none w-full cursor-pointer py-1.5 font-normal text-gray-600"
                htmlFor={column.toggleId}
              >
                {column.title}
              </label>
            </div>
          )
        })}
      </div>
    </Dropdown>
  )
}

export default compose(
  connect(() => (state) => ({
    trialStatus: getTrialPeriodStatus(get(state, 'resource.profile')),
    planLevel: getPlanLevelWithTrial(get(state, 'resource.profile')),
  }))
)(ToggleColumnTable)
