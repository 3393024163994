import React from 'react'
import { Outlet, Route } from 'react-router-dom'

import AsyncComponent from '~/components/AsyncComponent'

const IndexPageComponent = () => import('./IndexPage')
const IndexPage = AsyncComponent({ component: IndexPageComponent })

const CreateTemplatesComponent = () => import('./CreateTemplates')
const CreateTemplates = AsyncComponent({ component: CreateTemplatesComponent })

const AdvancedTemplateContainerComponent = () =>
  import('./AdvancedEditorPages/AdvancedTemplateContainer')
const AdvancedTemplateContainer = AsyncComponent({ component: AdvancedTemplateContainerComponent })

const AdvancedMailContainerComponent = () => import('./AdvancedEditorPages/AdvancedMailContainer')
const AdvancedMailContainer = AsyncComponent({ component: AdvancedMailContainerComponent })

const AdvancedAutoresponerContainerComponent = () =>
  import('./AdvancedEditorPages/AdvancedAutoresponerContainer')
const AdvancedAutoresponerContainer = AsyncComponent({
  component: AdvancedAutoresponerContainerComponent,
})

const PendingPreviewContainerComponent = () => import('./PendingPreview/PendingPreviewContainer')
const PendingPreviewContainer = AsyncComponent({ component: PendingPreviewContainerComponent })

import { draftsRoutes } from './DraftsPage'

export const mailsRoutes = (
  <>
    <Route exact index element={<IndexPage key="index" />} />
    <Route exact path="drafts" element={<IndexPage key="drafts" />} />
    <Route exact path="autoresponders" element={<IndexPage key="autoresponders" />} />
    <Route exact path="scheduled" element={<IndexPage key="scheduled" />} />
    <Route exact path="sent" element={<IndexPage key="sent" />} />
    <Route exact path="stopped" element={<IndexPage key="stopped" />} />
    <Route exact path="pending" element={<IndexPage key="pending" />} />
    <Route exact path="templates" element={<IndexPage key="templates" />} />

    <Route path="templates/advanced/detail" element={<Outlet />}>
      <Route exact index element={<AdvancedTemplateContainer key="index" />} />
      <Route exact path=":id" element={<AdvancedTemplateContainer key="id" />} />
    </Route>

    <Route path="advanced/detail" element={<Outlet />}>
      <Route exact index element={<AdvancedMailContainer key="index" />} />
      <Route exact path=":id" element={<AdvancedMailContainer key="id" />} />
    </Route>

    <Route path="autoresponders/advanced/detail" element={<Outlet />}>
      <Route exact index element={<AdvancedAutoresponerContainer key="index" />} />
      <Route exact path=":id" element={<AdvancedAutoresponerContainer key="id" />} />
    </Route>

    <Route path="templates/create" element={<Outlet />}>
      <Route exact index element={<CreateTemplates key="index" />} />
      <Route exact path="own" element={<CreateTemplates key="own" />} />
      <Route exact path="advanced" element={<CreateTemplates key="advanced" />} />
    </Route>

    <Route exact path="pending_review" element={<PendingPreviewContainer />} />

    <Route path="drafts" element={<Outlet />}>
      {draftsRoutes}
    </Route>
  </>
)
