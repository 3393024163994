import React from 'react'
import { noop } from '~/lib/utils'

export const Checkbox = ({
  id,
  className = '',
  addedClassName = '',
  checked,
  value,
  onChange = noop,
  disabled,
  required,
}) => {
  const defaultClassName = `mt-1 h-3.5 w-3.5 cursor-pointer rounded border border-gray-500 bg-white bg-contain bg-center bg-no-repeat align-top transition duration-200 checked:border-blue-600 checked:bg-blue-600 focus:outline-none focus:ring-0 ${addedClassName}`
  const preparedClassName = className ? className : defaultClassName
  return (
    <input
      type="checkbox"
      checked={checked}
      className={preparedClassName}
      value={value}
      onChange={onChange}
      required={required}
      disabled={disabled}
      id={id}
    />
  )
}
