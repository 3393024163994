import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'

export default class PageSubNav extends Component {
  static propTypes = {
    links: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        link: PropTypes.string,
        badge: PropTypes.element,
      })
    ),
  }

  static defaultProps = {
    links: [],
  }

  render() {
    return (
      <div className="row">
        <div className="col">
          <nav className="gan-nav-tabs mb-8 flex">
            {!this.props.children &&
              this.props.links.map((item) => (
                <NavLink
                  end={item.end}
                  to={item.link}
                  className="nav-link flex items-baseline"
                  key={item.name + item.link}
                >
                  {item.name}
                  {!!item.badge && item.badge}
                </NavLink>
              ))}
            {this.props.children}
          </nav>
        </div>
      </div>
    )
  }
}
