import React, { Component } from 'react'
import { bindActionCreators, compose, Dispatch } from 'redux'
import { connect } from 'react-redux'
import { combineEpics, ofType } from 'redux-observable'
import { concat, from, of } from 'rxjs'

import { catchError, filter as filterOp, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators'

import { compile } from 'path-to-regexp'

import merge from 'lodash/merge'
import values from 'lodash/values'
import uniqWith from 'lodash/uniqWith'
import orderBy from 'lodash/orderBy'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

import { push, replace } from 'react-router-redux'

import { parseQueryParams } from '~/common/utils/queryParams'
import { captureException } from './sentry'
import { createdSorter, isEqualByIdKey } from './helpers'
import { prepareFilters } from '~/common/utils/helpers'
import { DEFAULT_API_VERSION } from '~/api'
import {
  Action,
  FilterAction,
  RequestAction,
  RequestErrorAction,
  RequestSuccessAction,
  ResetFiltersAction,
  SetDataAction,
  SetErrorsAction,
  SetFiltersAction,
  SetLoadingAction,
} from '@/types/actionTypes'
import { ResourceMeta, ResourceOptions, ResourceState } from '@/types/resourceTypes'
import { Epic, EpicDependencies } from '@/types/epicTypes'
import { BrowserHistory } from 'history'
import { MakeOptional } from '@/types/utilityTypes'

export const REQUEST = '@ds-resource/request'
export const REQUEST_SUCCESS = '@ds-resource/request-success'
export const REQUEST_ERROR = '@ds-resource/request-error'
export const FILTER = '@ds-resource/filter'
export const SET_DATA = '@ds-resource/set-data'
export const SET_ERRORS = '@ds-resource/set-errors'
export const SET_LOADING = '@ds-resource/set-loading'
export const SET_FILTERS = '@ds-resource/set-filters'
export const RESET_FILTERS = '@ds-resource/reset-filters'

export function request(payload: any, meta: ResourceMeta): RequestAction {
  return {
    type: REQUEST,
    meta,
    payload,
  }
}

export function requestSuccess(payload: any, meta: ResourceMeta): RequestSuccessAction {
  return {
    type: REQUEST_SUCCESS,
    meta,
    payload,
  }
}

export function requestError(payload: any, meta: ResourceMeta): RequestErrorAction {
  return {
    type: REQUEST_ERROR,
    meta,
    payload,
  }
}

export function setData(payload: any, meta: ResourceMeta): SetDataAction {
  return {
    type: SET_DATA,
    meta,
    payload,
  }
}

export function setErrors(payload: any, meta: ResourceMeta): SetErrorsAction {
  return {
    type: SET_ERRORS,
    meta,
    payload,
  }
}

export function setLoading(payload: number, meta: ResourceMeta): SetLoadingAction {
  return {
    type: SET_LOADING,
    meta,
    payload,
  }
}

export function setFilters(payload: any, meta: ResourceMeta): SetFiltersAction {
  return {
    type: SET_FILTERS,
    meta,
    payload,
  }
}

export function resetFilters(payload: any, meta: ResourceMeta): ResetFiltersAction {
  return {
    type: RESET_FILTERS,
    meta,
    payload,
  }
}

export function filter(payload: any, meta: ResourceMeta): FilterAction {
  return {
    type: FILTER,
    meta,
    payload,
  }
}

export function selectResource(resource: ResourceOptions) {
  return function (state: any): ResourceState<any> {
    return {
      data: null,
      options: null,
      isLoading: false,
      errors: null,
      loading: 0,
      filters: { ...resource.filters },
      ...state.resource[resource.namespace],
    }
  }
}

export function connectResource(
  resourceData: MakeOptional<ResourceOptions, 'idKey'>,
  options = {}
) {
  const resource = {
    prefetch: true,
    refresh: false,
    form: false,
    list: false,
    options: false,
    async: false,
    item: Boolean((options as ResourceOptions).form),
    disableToast: false,
    apiVersion: DEFAULT_API_VERSION,
    ...resourceData,
    ...options,
    idKey: resourceData.idKey || 'uuid',
  }

  resource.namespace = getNamespace(resource)

  const connectHOC = connect(
    selectResource(resource),
    (dispatch: Dispatch<Action>, props: any) => {
      const meta: ResourceMeta = { resource, props }

      const promiseableActions = {
        create: makePromisableRequestAction('POST', meta, dispatch),
        fetch: makePromisableRequestAction('GET', meta, dispatch),
        update: makePromisableRequestAction('PATCH', meta, dispatch),
        remove: makePromisableRequestAction('DELETE', meta, dispatch),
        replace: makePromisableRequestAction('PUT', meta, dispatch),
        fetchOptions: makePromisableRequestAction('OPTIONS', meta, dispatch),
        filter: makePromisableAction(
          (payload: any) => filter(payload, { ...meta, reset: false }),
          dispatch
        ),
      }

      const restActions = {
        setData: (payload: any) => setData(payload, meta),
        setErrors: (payload: any) => setErrors(payload, meta),
        setFilters: (payload: any) => setFilters(payload, meta),
        resetFilters: (payload: any) => resetFilters(payload, meta),
      }

      return {
        ...promiseableActions,
        ...bindActionCreators(restActions, dispatch),
        save: promiseableActions.update,
      }
    },
    (stateProps: any, dispatchProps: any, ownProps: any) => {
      const props = {
        ...ownProps,
        [resource.namespace]: {
          ...stateProps,
          ...dispatchProps,
        },
      }

      if (resource.form) {
        const isNew = !(
          (resource.list && ownProps[resource.idKey]) ||
          (!resource.list && resource.prefetch)
        )
        return {
          ...props,
          initialValues: stateProps.data,
          onSubmit: isNew ? dispatchProps.create : dispatchProps.update,
        }
      }

      return props
    }
  )

  if (!resource.prefetch) {
    return connectHOC
  }

  return compose(connectHOC, makePrefetchHOC(resource))
}

export function connectFormResource(resource: ResourceOptions, options: Record<string, any>) {
  return connectResource(resource, { ...options, form: true })
}

export function connectListResource(resource: ResourceOptions, options: Record<string, any>) {
  return connectResource(resource, { ...options, list: true })
}

export function connectSingleResource(resource: ResourceOptions, options: Record<string, any>) {
  return connectResource(resource, options)
}

export function makePrefetchHOC(resource: ResourceOptions) {
  return function (ComposedComponent: React.ComponentType<any>) {
    return class PrefetchResourceContainer extends Component<any, any> {
      async componentDidMount() {
        const hasData = this.props[resource.namespace].data !== null
        const hasOptions = this.props[resource.namespace].options !== null
        const hasId = Boolean(this.props[resource.idKey])
        if ((!hasData || resource.refresh) && (!resource.list || !resource.item || hasId)) {
          if (resource.useRouter) {
            this.props[resource.namespace].setFilters({
              ...resource.filters, // default filters
              ...parseQueryParams(window.location.search),
            })
          }

          try {
            await this.props[resource.namespace].fetch()
          } catch (e) {
            console.error(e)
            captureException(e)
          }
        } else if (!hasData) {
          this.props[resource.namespace].setData({})
        }

        if (resource.options && !hasOptions) {
          try {
            await this.props[resource.namespace].fetchOptions()
          } catch (e) {
            console.error(e)
            captureException(e)
          }
        }
      }

      render() {
        const hasData = this.props[resource.namespace].data !== null
        const hasOptions = this.props[resource.namespace].options !== null

        if (!resource.async && (!hasData || (resource.options && !hasOptions))) {
          return null // TODO loading
        }

        return <ComposedComponent {...this.props} />
      }
    }
  }
}

const defaultState = {
  isFetched: false,
  partnerProfile: {},
}

export function reducer(state = defaultState, { type, payload, meta }: Action) {
  switch (type) {
    case SET_ERRORS:
    case SET_DATA: {
      // @ts-ignore
      const currentData = state[meta.resource.namespace]
      const dataKey = {
        [SET_DATA]: meta.type === 'OPTIONS' ? 'options' : 'data',
        [SET_ERRORS]: 'errors',
      }[type]

      if (dataKey === 'options') {
        payload = parseOptions(payload)
      }

      let count
      let payloadAll = []
      const dataAllKey = `${dataKey}All`
      if (dataKey === 'data' && meta.resource.list && !meta.resource.item && payload) {
        count = payload.results ? payload.count : payload.length
        payload = payload.results || payload
        payloadAll = orderBy(
          uniqWith(
            [
              ...(Array.isArray(payload) ? payload : []),
              ...(currentData && Array.isArray(currentData[dataAllKey])
                ? currentData[dataAllKey]
                : []),
            ],
            isEqualByIdKey(meta.resource.namespace)
          ),
          [createdSorter],
          ['desc']
        )
      }

      if (meta.resource.namespace === 'session') {
        if (payload && payload.logout && !payload.skipPartner) {
          return {}
        }
        if (payload && payload.logout && payload.skipPartner) {
          return {
            [meta.resource.namespace]: {
              ...currentData,
              [dataKey]: {
                ...get(currentData, `${dataKey}`),
                token: undefined,
              },
            },
            partnerProfile: {
              ...state.partnerProfile,
            },
          }
        }
      }

      return {
        ...state,
        [meta.resource.namespace]: {
          ...currentData,
          count,
          [dataKey]: payload,
          [dataAllKey]: payloadAll,
        },
      }
    }

    case SET_LOADING: {
      // @ts-ignore
      const currentData = state[meta.resource.namespace] || { loading: 0 }
      const loading = (currentData.loading === undefined ? 0 : currentData.loading) + payload

      if (loading < 0) {
        console.warn('loading counter actions are inconsistent')
      }

      return {
        ...state,
        [meta.resource.namespace]: {
          ...currentData,
          isLoading: loading > 0,
          loading,
        },
      }
    }

    case SET_FILTERS: {
      // @ts-ignore
      const currentData = state[meta.resource.namespace] || {}
      const filters = meta.reset
        ? meta.resource.filters
        : selectResource(meta.resource)({ resource: state }).filters

      return {
        ...state,
        [meta.resource.namespace]: {
          ...currentData,
          filters: { ...filters, ...payload },
        },
      }
    }

    case REQUEST_SUCCESS: {
      if (meta.type === 'DELETE') {
        // @ts-ignore
        const currentData = state[meta.resource.namespace] || []
        const dataAllKey = 'dataAll'
        const dataAll =
          currentData && Array.isArray(currentData[dataAllKey]) ? currentData[dataAllKey] : []
        const idKey = get(meta, 'resource.idKey')
        return {
          ...state,
          [meta.resource.namespace]: {
            ...currentData,
            [dataAllKey]: dataAll.filter(
              (item: any) => item[idKey] !== meta.payload[meta.resource.idKey]
            ),
          },
        }
      }
      return state
    }

    case RESET_FILTERS: {
      // @ts-ignore
      const currentData = state[meta.resource.namespace] || {}
      return {
        ...state,
        [meta.resource.namespace]: {
          ...currentData,
          filters: { ...payload },
        },
      }
    }

    default: {
      return state
    }
  }
}

function requestEpic(action$: any, state$: any, { API }: EpicDependencies): Epic {
  return action$.pipe(
    ofType(REQUEST),
    withLatestFrom(state$),
    mergeMap(([{ meta, payload }, state]: [RequestAction, any]) => {
      const { type, props, resource } = meta

      const isListItem =
        !resource.item && resource.list && ['PATCH', 'PUT', 'DELETE'].includes(type)

      let itemId = (isListItem ? payload : props)[resource.idKey]
      if (resource.idFromResource) {
        itemId = resource[resource.idKey]
      }

      let endpoint = resource.endpoint
      if (!new RegExp(`(:${resource.idKey})\\W`, 'g').test(endpoint)) {
        endpoint += `/:${resource.idKey}?`
      }
      const toPath = compile(endpoint)
      endpoint = toPath({ ...props, [resource.idKey]: itemId })

      const submitting = resource.form && ['POST', 'PATCH', 'PUT', 'DELETE'].includes(type)

      const hasId = Boolean(props[resource.idKey])
      const query =
        resource.list && !hasId && !isListItem ? selectResource(resource)(state).filters : undefined

      return concat(
        of(setLoading(+1, meta)),
        from(
          API(
            endpoint,
            resource.allowEmptyEndpoint,
            resource.disableToast,
            resource.apiVersion
          ).request(type, query, payload)
        ).pipe(
          switchMap((response) =>
            of(
              isListItem ? request(undefined, { ...meta, type: 'GET' }) : setData(response, meta),
              setLoading(-1, meta),
              submitting &&
                meta.resource.navigateAfterSubmit &&
                push(meta.resource.navigateAfterSubmit),
              requestSuccess(response, meta)
            )
          ),
          catchError((err) =>
            of(
              setErrors(err.errors || err, meta),
              setLoading(-1, meta),
              submitting ? requestError(err, meta) : requestError(err.errors || err, meta)
            )
          )
        )
      ).pipe(filterOp(Boolean))
    })
  )
}

function filterEpic(action$: any): Epic {
  return action$.pipe(
    ofType(FILTER),
    mergeMap(({ meta, payload }: SetFiltersAction) => {
      return of(setFilters(payload, meta), request(undefined, { ...meta, type: 'GET' }))
    })
  )
}

function getNavigateEpic(history: BrowserHistory) {
  const buildSearch = (meta: ResourceMeta, state: any) => {
    const filters = selectResource(meta.resource)(state).filters
    const preparedFilters = prepareFilters(filters, meta.resource.namespace)
    return buildQueryParams(preparedFilters)
  }

  return function (action$: any, state$: any): Epic {
    return action$.pipe(
      ofType(SET_FILTERS),
      withLatestFrom(state$),
      filterOp(([{ meta }, state]: [SetFiltersAction, any]) => meta.resource.useRouter),
      filterOp(([{ meta }, state]: [SetFiltersAction, any]) => {
        const search = history.location.search || ''
        const newSearch = buildSearch(meta, state)
        return ![newSearch, `?${newSearch}`].includes(search)
      }),
      mergeMap(([{ meta }, state]: [SetFiltersAction, any]) => {
        return of(
          replace(
            {
              ...history.location,
              search: buildSearch(meta, state),
            },
            history.location.state as any
          )
        )
      })
    )
  }
}

function promiseResolveEpic(action$: any): Epic {
  return action$.pipe(
    ofType(REQUEST_ERROR, REQUEST_SUCCESS),
    mergeMap(function ({ meta, payload, type }: RequestErrorAction | RequestSuccessAction) {
      if (meta.requestPromise) {
        const callback = type === REQUEST_SUCCESS ? 'resolve' : 'reject'
        meta.requestPromise[callback](payload)
      }
      return of({ type: '@@NONE' })
    })
  )
}

function getNamespace({
  list,
  item,
  namespace,
}: Omit<ResourceOptions, 'namespace'> & {
  namespace: string | string[]
}) {
  if (!Array.isArray(namespace)) {
    namespace = [namespace, namespace]
  }

  return namespace[list && !item ? 0 : 1]
}

function makeRequestAction(type: string, meta: ResourceMeta) {
  return function (payload: any, options?: ResourceOptions) {
    if (type === 'GET' && payload !== undefined) {
      console.warn('GET action should not contain request body')
    }
    const passMeta =
      options === undefined ? meta : { ...meta, resource: { ...meta.resource, ...options } }
    return request(payload, { ...passMeta, type })
  }
}

function makePromisableRequestAction(type: string, meta: ResourceMeta, dispatch: any) {
  const actionCreator = makeRequestAction(type, meta)
  return makePromisableAction(actionCreator, dispatch)
}

function makePromisableAction(
  actionCreator: (payload: any, options?: ResourceOptions) => Action,
  dispatch: any
) {
  return function () {
    // @ts-ignore
    const { type, meta, payload } = actionCreator.apply(this, arguments)
    return new Promise((resolve, reject) => {
      const action = {
        type,
        payload,
        meta: {
          ...meta,
          payload,
          requestPromise: { resolve, reject },
        },
      }

      dispatch(action)
    })
  }
}

function parseOptions(options: any) {
  // @ts-ignore
  return merge.apply(null, values(options.actions))
}

export const getEpic = (history: BrowserHistory) =>
  combineEpics(requestEpic, filterEpic, getNavigateEpic(history), promiseResolveEpic)

type Params = {
  [key: string]: string | number | (string | number)[] | null | undefined
}

export function buildQueryParams(params: any) {
  if (isEmpty(params)) {
    return ''
  }

  return Object.keys(params)
    .reduce<string[]>((ret, key) => {
      let value = params[key]

      if (value === null || value === undefined) {
        return ret
      }

      if (!Array.isArray(value)) {
        value = [value]
      }

      value.forEach((val: Params) => {
        if (String(val).length > 0) {
          ret.push(encodeURIComponent(key) + '=' + encodeURIComponent(String(val)))
        }
      })

      return ret
    }, [])
    .join('&')
}
