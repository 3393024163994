import React from 'react'

import { injectIntl } from 'react-intl'
import { compose } from 'redux'

export const ERROR_TYPE_UNKNOWN = 0
export const ERROR_TYPE_EMAIL_ALREADY_CONFIRMED = 1
export const ERROR_TYPE_INVALID_CONFIRMATION_CODE = 2
export const ERROR_TYPE_CANNOT_DELETE_PENDING_MAIL = 3
export const ERROR_TYPE_UNCONFIRMED_EMAIL = 4
export const ERROR_TYPE_FILE_NO_EMAIL_COLUMN = 5
export const ERROR_TYPE_FILE_PASSWORD_PROTECTED = 6
export const ERROR_TYPE_PROMO_DISCOUNT_NOT_FOUND = 7
export const ERROR_TYPE_PROMO_DISCOUNT_NOT_ACTIVE = 8
export const ERROR_TYPE_PROMO_DISCOUNT_NOT_VALID = 9
export const ERROR_TYPE_PROMO_DISCOUNT_ALREADY_USED = 10
export const ERROR_TYPE_INVALID_LISTS = 11
export const ERROR_TYPE_CANNOT_DELETE_ATTRIBUTE_IN_USE = 12
export const ERROR_NO_EMAIL_IN_MAPPING = 13

export const prepareMessageTranslate = (formatMessage) => (id) => {
  switch (true) {
    case id === 'Sender already confirmed': {
      return {
        type: ERROR_TYPE_EMAIL_ALREADY_CONFIRMED,
        message: formatMessage({
          id: 'sender.emailConfirmation.alreadyConfirmed.title',
        }),
      }
    }
    case id === 'Already confirmed':
    case id === 'Email already confirmed': {
      return {
        type: ERROR_TYPE_EMAIL_ALREADY_CONFIRMED,
        message: formatMessage({
          id: 'emailConfirmation.alreadyConfirmed.title',
        }),
      }
    }
    case id === 'Invalid confirmation code': {
      return {
        type: ERROR_TYPE_INVALID_CONFIRMATION_CODE,
        message: formatMessage({ id: 'emailConfirmation.invalidCode.title' }),
      }
    }
    case id === 'Cannot delete pending mail': {
      return {
        type: ERROR_TYPE_CANNOT_DELETE_PENDING_MAIL,
        message: formatMessage({ id: 'mails.pending.delete.error' }),
      }
    }
    case id === 'User email address is not confirmed': {
      return {
        type: ERROR_TYPE_UNCONFIRMED_EMAIL,
        message: formatMessage({ id: 'emailConfirmation.notconfirmed' }),
      }
    }
    case id === 'Unable to find email column.': {
      return {
        type: ERROR_TYPE_FILE_NO_EMAIL_COLUMN,
        message: formatMessage({ id: 'validations.file.email.nocolumn' }),
      }
    }
    case id === 'File is password-protected': {
      return {
        type: ERROR_TYPE_FILE_PASSWORD_PROTECTED,
        message: formatMessage({ id: 'validations.file.password.protected' }),
      }
    }
    case `${id}`.startsWith('wrong format for EU country'): {
      return {
        type: ERROR_TYPE_FILE_PASSWORD_PROTECTED,
        message: formatMessage(
          {
            id: 'validations.vatid.wrong.format',
          },
          {
            countryCode: `${id}`.split(' ')[5],
          }
        ),
      }
    }
    case id === 'first digit can not be equal to 4': {
      return {
        type: ERROR_TYPE_FILE_PASSWORD_PROTECTED,
        message: formatMessage({ id: 'validations.orgnumber.first.digit' }),
      }
    }
    case id === 'must contain 10 digits': {
      return {
        type: ERROR_TYPE_FILE_PASSWORD_PROTECTED,
        message: formatMessage({ id: 'validations.orgnumber.length' }),
      }
    }
    case id === 'signature wrong or outdated': {
      return {
        type: ERROR_TYPE_INVALID_CONFIRMATION_CODE,
        message: formatMessage({
          id: 'sender.emailConfirmation.invalidCode.title',
        }),
      }
    }
    case id === 'Not found': {
      return {
        type: ERROR_TYPE_PROMO_DISCOUNT_NOT_FOUND,
        message: formatMessage({
          id: 'upgrade.promo.error.not-found',
        }),
      }
    }
    case id === 'Not active': {
      return {
        type: ERROR_TYPE_PROMO_DISCOUNT_NOT_ACTIVE,
        message: formatMessage({
          id: 'upgrade.promo.error.not-active',
        }),
      }
    }
    case id === 'Valid only for yearly subscriptions': {
      return {
        type: ERROR_TYPE_PROMO_DISCOUNT_NOT_VALID,
        message: formatMessage({
          id: 'upgrade.promo.error.not-valid',
        }),
      }
    }
    case id === 'Already used': {
      return {
        type: ERROR_TYPE_PROMO_DISCOUNT_ALREADY_USED,
        message: formatMessage({
          id: 'upgrade.promo.error.already-used',
        }),
      }
    }
    case `${id}`.startsWith('Invalid list hashes:'): {
      return {
        type: ERROR_TYPE_INVALID_LISTS,
        message: formatMessage({
          id: 'validations.lists.invalid',
        }),
      }
    }
    case id === 'Can not delete attribute that in use': {
      return {
        type: ERROR_TYPE_CANNOT_DELETE_ATTRIBUTE_IN_USE,
        message: formatMessage({
          id: 'toasters.attribute.usedByAutoresponder',
        }),
      }
    }
    case id === 'Email column not provided as part of the contact mapping': {
      return {
        type: ERROR_NO_EMAIL_IN_MAPPING,
        message: formatMessage({
          id: 'toasters.import.mapping.no-email',
        }),
      }
    }
    default: {
      return {
        type: ERROR_TYPE_UNKNOWN,
        message: id,
      }
    }
  }
}

export function CustomValidationError({ intl: { formatMessage }, messages = null }) {
  const prepareMessage = prepareMessageTranslate(formatMessage)
  if (typeof messages === 'string') {
    return <div>{prepareMessage(messages).message}</div>
  }

  if (Array.isArray(messages)) {
    return (
      <div>
        {messages.map((msg, index) => (
          <span key={index}>{prepareMessage(msg).message}</span>
        ))}
      </div>
    )
  }

  return null
}

export default compose(injectIntl)(CustomValidationError)
