import 'core-js'
import 'react-app-polyfill/stable'
import '~/polyfills'

import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import get from 'lodash/get'
import { PostHogProvider } from 'posthog-js/react'

import App from '~/components/App'
import { history, store } from '~/init'
import { initPageviewTrack } from '~/common/utils/googleTagManager'

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

import 'react-toastify/dist/ReactToastify.css'
import './index.css'
import './index.scss'

if (import.meta.env.REACT_APP_APM_SERVICE_URL) {
  import('@elastic/apm-rum').then(({ init: initApm }) => {
    initApm({
      serviceName: import.meta.env.REACT_APP_APM_SERVICE_NAME,
      serverUrl: import.meta.env.REACT_APP_APM_SERVICE_URL,
      serviceVersion: import.meta.env.REACT_APP_GIT_COMMIT,
      environment: import.meta.env.REACT_APP_ENV,
      centralConfig: import.meta.env.REACT_APP_APM_CENTRAL_CONFIG,
    })
  })
}

Sentry.init({
  dsn: import.meta.env.REACT_APP_SENTRY_DSN,
  environment: import.meta.env.REACT_APP_ENV,
  release: import.meta.env.REACT_APP_GIT_COMMIT,
  autoSessionTracking: false,
  whitelistUrls: [
    new RegExp(`${import.meta.env.REACT_APP_SENTRY_WHITELIST_URL}`.replace('.', '\\.')),
  ],
  ignoreErrors: [
    'Network request failed',
    'Failed to fetch',
    'Nätverksanslutningen förlorades.',
    'NetworkError when attempting to fetch resource.',
    'AbortError: The operation was aborted.',
  ],
  beforeSend: (event, hint) => {
    // ignore error if handled and validation error
    if (
      get(event, 'exception.values[0].mechanism.handled') &&
      (get(hint, 'originalException._error') ||
        get(event, 'exception.values[0].type') === 'SubmissionError')
    ) {
      return null
    }

    // filter out UnhandledRejection errors that have no information
    if (
      event !== undefined &&
      event.exception !== undefined &&
      event.exception.values !== undefined &&
      event.exception.values.length === 1
    ) {
      const e = event.exception.values[0]
      if (
        e.type === 'UnhandledRejection' &&
        (e.value === 'Non-Error promise rejection captured with value: ' ||
          e.value === 'Non-Error promise rejection captured with value: Timeout' ||
          `${e.value}`.startsWith(
            'Non-Error promise rejection captured with value: Object Not Found Matching Id:'
          ))
      ) {
        return null
      }
    }

    // Filter out NS_ERROR_FAILURE
    if (
      event !== undefined &&
      event.exception !== undefined &&
      event.exception.values !== undefined &&
      event.exception.values.length === 1
    ) {
      const e = event.exception.values[0]
      if (e.value === 'NS_ERROR_FAILURE') {
        return null
      }
    }

    return event
  },
})

window.onload = initPageviewTrack(history, store)

const root = createRoot(document.getElementById('root'))

if (import.meta.env.REACT_APP_PUBLIC_POSTHOG_KEY) {
  const options = {
    api_host: import.meta.env.REACT_APP_PUBLIC_POSTHOG_HOST,
  }
  root.render(
    <PostHogProvider apiKey={import.meta.env.REACT_APP_PUBLIC_POSTHOG_KEY} options={options}>
      <GoogleReCaptchaProvider reCaptchaKey={import.meta.env.REACT_APP_RECAPTCHA_SITE_KEY}>
        <Provider store={store}>
          <HistoryRouter history={history}>
            <App />
          </HistoryRouter>
        </Provider>
      </GoogleReCaptchaProvider>
    </PostHogProvider>
  )
} else {
  root.render(
    <GoogleReCaptchaProvider reCaptchaKey={import.meta.env.REACT_APP_RECAPTCHA_SITE_KEY}>
      <Provider store={store}>
        <HistoryRouter history={history}>
          <App />
        </HistoryRouter>
      </Provider>
    </GoogleReCaptchaProvider>
  )
}
