import React from 'react'
import './styles.scss'

export default function AnimatedCheckmark({ size = 'md' }) {
  const sizes = {
    sm: 'animated-checkmark animated-checkmark--small',
    md: 'animated-checkmark',
  }
  return (
    <svg className={sizes[size]} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
      <circle className="animated-checkmark__circle" cx="26" cy="26" r="25" fill="none" />
      <path className="animated-checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
    </svg>
  )
}
