import React from 'react'
import get from 'lodash/get'
import { connect } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import FileManager from './'
import { compose } from 'redux'

export class FileManagerLayout extends React.Component {
  state = {
    opened: null,
  }

  toggleModal = () => {
    this.setState({ opened: !this.state.opened })
  }

  componentDidUpdate() {
    const { pathname, search } = this.props.location
    if (get(this.props, 'location.state.openFileManager') === true) {
      this.props.navigate({ pathname, search }, { state: { openFileManager: null } })
      this.setState({
        opened: true,
      })
    } else if (get(this.props, 'location.state.openFileManager') === false) {
      this.props.navigate({ pathname, search }, { state: { openFileManager: null } })
      this.setState({
        opened: false,
      })
    }
  }

  render() {
    return (
      <>
        {this.props.children}
        {this.props.token && this.state.opened && <FileManager onClose={this.toggleModal} />}
      </>
    )
  }
}

const ComposedContainer = compose(
  connect((state) => ({
    token: get(state, 'resource.session.data.token'),
  }))
)(FileManagerLayout)

function ComposedContainerWrapper(props) {
  const location = useLocation()
  const navigate = useNavigate()
  return <ComposedContainer {...props} location={location} navigate={navigate} />
}

export default ComposedContainerWrapper
