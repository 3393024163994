import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { noop } from '~/lib/utils'

const propTypes = {
  inputClassName: PropTypes.string,
  placeholder: PropTypes.string,
  pattern: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  name: PropTypes.string,
  value: PropTypes.string,
  meta: PropTypes.object,
  onChange: PropTypes.func,
}

const defaultProps = {
  inputClassName: 'input-custom',
  readOnly: false,
  meta: {},
  onChange: noop,
}

export default class TextareaInput extends PureComponent {
  handleChange = (e) => {
    this.props.onChange(e.target.value)
  }

  render() {
    const {
      inputClassName,
      inputComponent,
      appendAddonComponent,
      input,
      meta,
      label,
      appendAddon,
      helpText,
      ignoreTouched,
      ...restProps
    } = this.props
    return (
      <textarea
        {...restProps}
        className={`form-control placeholder-light ${
          (this.props.meta.touched || this.props.ignoreTouched) && this.props.meta.error && 'error'
        }`}
        onChange={this.handleChange}
      />
    )
  }
}

TextareaInput.propTypes = propTypes
TextareaInput.defaultProps = defaultProps
