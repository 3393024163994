import React from 'react'
import { compose } from 'redux'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import get from 'lodash/get'
import noop from 'lodash/noop'

import { connectResource } from '~/common/utils/resource'
import { getPlanLevelWithTrial } from '~/common/utils/helpers'
import Button from '~/common/components/Button'
import { PLANS_LEVELS } from '~/common/utils/constants/providers'
import ModalStartedTrial from './ModalStartedTrial'
import { addEvent, prepareTrialRegistrationEvent } from '~/common/utils/googleTagManager'
import { trialStart } from '~/common/utils/posthog'

export const RequireUpgradeBase = ({
  hideStartTrialModal,
  profile,
  startTrial,
  actionLink,
  actionLabel,
  trialLabel,
  onStartedTrialSuccess,
  buttonClassName,
  children,
}) => {
  const isUsedTrial = !!get(profile, 'data.trial')

  const [showModalStartTrial, setShowModalStartTrial] = React.useState(false)

  const planLevel = React.useMemo(() => getPlanLevelWithTrial(profile), [profile])

  const handleStartTrial = () => {
    startTrial
      .create({ plan_level: PLANS_LEVELS.STANDARD })
      .then(() => {
        const event = prepareTrialRegistrationEvent()
        addEvent(event)
        trialStart()
      })
      .then(() => profile.fetch())
      .then(() => !hideStartTrialModal && setShowModalStartTrial(true))
      .then(() => onStartedTrialSuccess?.())
      .catch(noop)
  }

  const renderContent = () => {
    if (planLevel >= PLANS_LEVELS.STANDARD) return children

    if (!isUsedTrial) {
      return (
        <Button
          className={`leading-5 ${buttonClassName}`}
          isLoading={startTrial?.isLoading || profile.isLoading}
          type="button"
          variant="primary"
          size="lg"
          onClick={handleStartTrial}
        >
          {trialLabel || <FormattedMessage id="common.startFreeTrial" />}
        </Button>
      )
    }

    return (
      <Link to="/upgrade?planLevel=20" className={`btn btn-success ${buttonClassName}`}>
        <FormattedMessage id="common.upgradeToStandard" />
      </Link>
    )
  }

  return (
    <>
      {renderContent()}

      <ModalStartedTrial
        isOpen={showModalStartTrial}
        actionLink={actionLink}
        actionLabel={actionLabel}
        onClose={() => setShowModalStartTrial(false)}
      />
    </>
  )
}

export default compose(
  connectResource({
    namespace: 'startTrial',
    endpoint: 'start_trial',
    prefetch: false,
    async: true,
  }),
  connectResource({
    namespace: 'profile',
    endpoint: 'profile',
    prefetch: false,
    async: true,
  })
)(RequireUpgradeBase)
